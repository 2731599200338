import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  InputAdornment,
  Typography,
  Button,
  FormControl,
  TextField,
  Link
} from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import { SideImage, Logo } from "./assets";
import PhoneVerificationLoginFlowController, { Props } from "./PhoneVerificationLoginFlowController.web";
import { configJSON } from "./EmailAccountLoginController";
import Loader from "../../../components/src/Loader.web";

// Customizable Area Start
const PhoneVerificationLoginFlowStyles = (theme: Theme) =>
  createStyles({
    logoCard: {
      width: "200px",
      height: "200px",
      boxShadow: "-1px 3px 13px -1px rgba(0,0,0,0.75)",
      borderRadius: "50%",
      marginTop: "38px",
    },
    Phone: {
      color: "#01548A",
      fontSize: "25px",
      fontWeight: "bold",
    },
    PhoneDesc: {
      color: "#989898",
      marginTop: "30px",
    },
    InputField: {
      marginTop: "10px",
    },

    LoginBtn: {
      width: "100%",
      textTransform: "none",
      backgroundColor: "#01548A",
      color: "#fff",
      fontWeight: "bold",
      borderRadius: "6px",
      marginTop: "5%",
      marginBottom: "5%",
    },
    otppin: {
      color: "grey",
      fontSize: 14,
      width: "35px",
      height: "35px",
      margin: "5px 5px",
      borderRadius: "5px",
      border: "1px solid #b3b3b3",
      textAlign: "center",
      padding: "5px 0px 5px 0px",
      justifyContent: "center",

      backgroundColor: "#fafafa",
    },
    fieldError: {
      fontSize: "16px",
      textAlign: "start",

      color: "red",
    },
    mainGridContainer: {
      height: "inherit",
    },
  });
// Customizable Area End

// Customizable Area Start
export class PhoneVerificationLoginFlow extends PhoneVerificationLoginFlowController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return  (
      <Box height={"inherit"} id="Main" className="PhoneVerifyMainBox">
        <Loader loading={this.state.isLoadingShow} />
        <Grid className={classes.mainGridContainer} container>
          <Grid item xs={12} sm={5}>
            <img
              src={SideImage}
              alt="side-img-signUp"
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>

          <Grid item xs={12} sm={7} style={{ backgroundColor: "#fff" }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Box className={classes.logoCard}>
                  <img
                    src={Logo}
                    alt="logo-img"
                    style={{ padding: 0, width: "200px", height: "200px" }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Grid item style={{ marginTop: "20px" }}>
                  {!this.state.isOtpSent && (
                    <Box>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="h4"
                          style={{
                            color: "#01548A",
                            fontWeight: "bold",
                            fontSize: "25px",
                            marginTop: "20px",
                          }}
                        >
                          Phone Verification
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.PhoneDesc}
                        >
                          Enter Your Phone Number to receive a <br /> One Time
                          Password
                        </Typography>
                      </Box>
                      <Box style={{ marginTop: "10px" }}>
                        <form
                          onSubmit={this.handlePhoneNumberSubmit}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <FormControl className={classes.InputField}>
                            <TextField
                              id="PhoneNumber"
                              data-test-id="txtname"
                              variant="outlined"
                              size="small"
                              placeholder="Phone Number"
                              type="number"
                              onChange={this.handlePhoneNumberChange}
                              value={this.state.fullPhoneNumber}
                              name="full_phone_number"
                              error={this.state.errorMessage !== ""}
                              helperText={this.state.errorMessage}
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ marginLeft: "-78px" }}
                                  >
                                    <PhoneIcon
                                      style={{ width: 20, height: 20 }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>

                          <Typography
                            style={{
                              textAlign: "start",
                              color: "red",
                              fontSize: "16px",
                            }}
                          >
                            {this.state.fieldError}
                          </Typography>

                          <Box
                            style={{ marginTop: "40px", textAlign: "center" }}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              style={{
                                width: "100%",
                                textTransform: "none",
                                backgroundColor: "#01548A",
                                color: "#fff",
                                fontWeight: "bold",
                                borderRadius: "6px",
                                marginTop: "5%",
                                marginBottom: "5%",
                              }}
                              data-test-id="btnSignUp"
                            >
                              Send OTP
                            </Button>
                            <Typography
                              style={{ cursor: "pointer" }}
                              variant="body2"
                              onClick={this.handleSkipVerificationClick}
                            >
                              <Link>Skip</Link>&nbsp; Verification for 30 days
                            </Typography>
                          </Box>
                        </form>
                      </Box>
                    </Box>
                  )}
                  {this.state.isOtpSent && (
                    <Box>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="h4"
                          style={{
                            color: "#01548A",
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        >
                          Verification Code
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.PhoneDesc}
                        >
                          OTP has been send to your Phone Number,
                          <br />
                          Please Verify.
                        </Typography>
                      </Box>
                      <Box style={{ marginTop: "10px" }}>
                        <form
                          onSubmit={this.handleOtpSubmit}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "20px",
                              marginTop: "20px",
                            }}
                          >
                            {this.state.otpFormFields.map(
                              (value: any, index: any) => (
                                <input
                                  id="myInput"
                                  data-test-id={`txtOTPpin-${index}`}
                                  name={
                                    index == 0 ? "otp_code" : `otp${index + 1}`
                                  }
                                  key={index}
                                  type="number"
                                  onKeyDown={(e) =>
                                    this.handleOtpKeydown(e, index)
                                  }
                                  autoFocus={index === 0}
                                  onInput={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 1);
                                  }}
                                  value={value}
                                  onChange={(e) =>
                                    this.handleOTPInputChange(e, index)
                                  }
                                  maxLength={1}
                                  pattern="\d*"
                                  inputMode="numeric"
                                  style={{
                                    color: "grey",
                                    fontSize: 14,
                                    width: "35px",
                                    height: "35px",
                                    margin: "5px 5px",
                                    borderRadius: "5px",
                                    border: "1px solid #b3b3b3",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#fafafa",
                                    padding: "5px 0px 5px 0px",
                                  }}
                                />
                              )
                            )}
                          </Box>

                          {this.state.otpRelatedError !== "" && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                            >
                              {this.state.otpRelatedError}
                            </p>
                          )}
                          <Typography
                            style={{
                              textAlign: "center",
                              color: "red",
                              fontSize: "16px",
                            }}
                          >
                            {this.state.fieldError}
                          </Typography>
                          <Box
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {this.state.isDisabled ? (
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                }}
                                variant="body2"
                              >
                                00:{this.state.otpTiming}
                              </Typography>
                            ) : (
                              <Typography
                                style={{
                                  fontSize: "12px",
                                }}
                                variant="body2"
                              >
                                {configJSON.resendCodeLabel} &nbsp;
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: "30px",
                                    color: "#01548a",
                                    cursor: "pointer",
                                  }}
                                  onClick={this.handlePhoneNumberSubmit}
                                >
                                  {configJSON.resendTextLabel}
                                </span>
                              </Typography>
                            )}
                          </Box>

                          <Box
                            style={{ textAlign: "center", marginTop: "40px" }}
                          >
                            <Button
                              variant="contained"
                              data-test-id="btnVerifyOTP"
                              style={{
                                width: "100%",
                                marginBottom: "5%",
                                textTransform: "none",
                                color: "#fff",
                                backgroundColor: "#01548A",
                                borderRadius: "6px",
                                fontWeight: "bold",
                                marginTop: "5%",
                              }}
                              type="submit"
                            >
                              Verify
                            </Button>
                          </Box>
                        </form>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(PhoneVerificationLoginFlowStyles, {
  withTheme: true,
})(PhoneVerificationLoginFlow);
// Customizable Area End