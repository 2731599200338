// Customizable Area Start
import React from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  FormHelperText,
  FormControl,
  CardMedia,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputAdornment,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CreateEventStepOneModalController, {
  Props,
} from "./CreateEventStepOneModalController.web";
import Fade from "@material-ui/core/Fade";
import Card from "@material-ui/core/Card";
import "./CreatePostNew.web.css";
import CloseIcon from "./SVGIcons/CloseIcon.web";
import CreateEventIcon from "./SVGIcons/CreateEventIcon.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "./SVGIcons/DropdownIcon.web";
import TimeIcon from "./SVGIcons/TimeIcon.web";
import CameraIcon from "./SVGIcons/CameraIcon.web";
import CustomDatepicker from "../../../components/src/CustomDatepicker.web";
import CalendarIcon from "./SVGIcons/CalendarIcon.web";
import EditIcon from "./SVGIcons/EditIcon.web";
import LocationAddIcon from "./SVGIcons/LocationAddIcon.web";
import LinkIcon from "./SVGIcons/LinkIcon.web";
// Customizable Area End

// Customizable Area Start

/*istanbul ignore next */
const TimezoneEnd = (props: any) => {
  const { timezoneEndError } = props;

  return (
    <>
      {timezoneEndError && (
        <FormHelperText>Please Select End TIme</FormHelperText>
      )}
    </>
  );
};

/*istanbul ignore next */
const FormHelperTextComponent = (props: any) => {
  const { error, errorMessage } = props;
  return (
    <>
      {{ error } && (
        <FormHelperText style={{ marginTop: "5px" }}>
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
};

/*istanbul ignore next */
const EventDesc = (props: any) => {
  const { eventDescError, eventErrorDescMessage } = props;
  return (
    <>
      {eventDescError && (
        <FormHelperText style={{ marginLeft: "25px" }}>
          {eventErrorDescMessage}
        </FormHelperText>
      )}
    </>
  );
};
/*istanbul ignore next */
const TimezoneStart = (props: any) => {
  const { timezoneStartTimeError } = props;
  return (
    <>
      {timezoneStartTimeError && (
        <FormHelperText>Please Select Start TIme</FormHelperText>
      )}
    </>
  );
};
/*istanbul ignore next */

const ImagesError = (props: any) => {
  const { imageWidth, imageErrorMessage, imageError, imageErrorMsg } = props;
  return (
    <>
      {imageWidth < 480 && (
        <FormHelperText style={{ marginLeft: "1.6rem", marginTop: "-15px" }}>
          {imageErrorMessage}
        </FormHelperText>
      )}
      {imageError && (
        <FormHelperText style={{ marginLeft: "1.6rem", marginTop: "-15px" }}>
          {imageErrorMsg}
        </FormHelperText>
      )}
    </>
  );
};

const Poststyles = (theme: Theme) =>
  createStyles({
    textField: {
      borderRadius: "12px",
      padding: "18px 0px",
      border: "solid 2px #f4f4f4",
      position: "relative",
    },
    errorBorder: {
      border: "1px solid red",
    },
    textfieldInput: {
      fontWeight: "normal",
      fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
      paddingLeft: "5px",
    },

    textFieldFont: {
      fontSize: "16px",
    },

    line: {
      fontSize: "40px",
      color: "lightgrey",
    },
    inputAdornment: {
      paddingLeft: "15px",
    },
    title: {
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      display: "none",
    },
    imageUploadsTabs: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid grey",
      paddingTop: "12px",
      width: "100%",
    },
    imageUploadsTab: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    dropzone: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      justifyContent: "center",
    },
    iconBtn: {
      "&:hover": {
        borderRadius: "0px",
      },
    },
    Icons: {
      display: "flex",
      justifyContent: "space-around",
    },

    modal: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    paper: {
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
    },
    postModal: {
      boxShadow: "none",
      width: "100%",
      borderRadius: "12px",
    },
    description: {
      outline: "none",
      backgroundColor: "#e6e3e3",
      borderRadius: "6px",
    },
    postUserDescription: {
      borderTop: "2px solid lightgrey",
      padding: "10px 0px",
      borderBottom: "2px solid lightgrey",
    },
    cursor: {
      cursor: "pointer",
    },
    PostIcons: {
      justifyContent: "space-between",
      display: "flex",
    },

    dropText: {
      paddingTop: "5px",
    },

    topDiv: {
      padding: "18px 22px 8px 22px",
    },

    doneBtnGrid: {
      padding: "8px 16px 10px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },

    avatar: {
      width: "50px",
      objectFit: "contain",
      height: "50px",
    },
  });
// Customizable Area End
/*istanbul ignore next */
export class CreateEventStepOneModal extends CreateEventStepOneModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { classes, openModal } = this.props;

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`CreatePostModals ${classes.modal}`}
        open={openModal}
        onClose={this.props.handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 200,
        }}
        BackdropComponent={Backdrop}
      >
        <Fade in={openModal}>
          <Box
            className={`${classes.paper} CreatePostModalMainBox`}
            style={{ height: "36rem" }}
          >
            <Card className={classes.postModal}>
              <Grid container direction="column">
                <Grid className={classes.topDiv} item xs={12}>
                  {!this.props.eventDescModal && (
                    <Box display="flex" mb={2} justifyContent="space-between">
                      <Typography
                        variant="body2"
                        className={`Heading4 BoldFont FontColor5`}
                      >
                        Create An Event
                      </Typography>
                      <CloseIcon
                        className={`CursorPointer`}
                        onClick={this.props.handleClose}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke={"#808084"}
                        strokeWidth="1.5"
                        fill={"none"}
                      />
                    </Box>
                  )}
                </Grid>
                {!this.props.eventDescModal && (
                  <hr
                    style={{
                      background: "rgb(244, 244, 244)",
                      width: "100%",
                      borderColor: "rgb(244, 244, 244)",
                      color: "rgb(244, 244, 244)",
                    }}
                  />
                )}
                {!this.props.eventDescModal ? (
                  <Box
                    style={{
                      maxHeight: "25rem",
                      overflowX: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <input
                          accept="image/*"
                          id="cover-image-upload"
                          type="file"
                          style={{ display: "none" }}
                          onChange={this.props.handleImageChange}
                        />
                        <label htmlFor="cover-image-upload">
                          {this.props.image === "" && (
                            <Box
                              justifyContent="center"
                              mb={2}
                              style={webPage.cardText}
                            >
                              <Box style={{ marginLeft: "5.4rem" }}>
                                <CameraIcon
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke="#808080"
                                  strokeWidth="1.5"
                                  fill="none"
                                />
                              </Box>

                              <Typography
                                style={{
                                  marginTop: "14px",
                                  marginBottom: "14px",
                                }}
                                variant="body2"
                                className={`Heading4 BoldFont FontColor5`}
                              >
                                Upload Cover Image
                              </Typography>
                              <Typography
                                variant="body2"
                                className={`FontColor5`}
                                style={{ marginLeft: "-28px" }}
                              >
                                Upload cover image minimum width
                              </Typography>
                              <Typography
                                variant="body2"
                                className={`FontColor5`}
                                style={{ marginLeft: "41px" }}
                              >
                                480 pixel (16:9).
                              </Typography>
                            </Box>
                          )}
                        </label>

                        <label htmlFor="cover-image-upload">
                          <CardMedia
                            image={
                              this.props.image || "/default-cover-image.jpg"
                            }
                            style={webPage.media}
                          />
                        </label>
                        <ImagesError
                          imageWidth={this.props.imageWidth}
                          imageErrorMessage={this.props.imageErrorMessage}
                          imageError={this.props.imageError}
                          imageErrorMsg={this.props.imageErrorMsg}
                        />
                      </Grid>
                    </Grid>
                    <Grid className={classes.urlPostGrid} item xs={12}>
                      <FormControl
                        className={`CustomTextFieldFormControl`}
                        style={webPage.eventStyle}
                      >
                        <Typography
                          component={"span"}
                          className={`Heading6 BoldFont PrimaryColor2`}
                          style={webPage.eventName}
                        >
                          Event Type*
                        </Typography>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            checked={
                              this.props.radioSelect === "virtual"
                                ? true
                                : false
                            }
                            value="virtual"
                            onChange={this.props.handleRadioSelect}
                            control={<Radio />}
                            label="Virtual"
                          />
                          <FormControlLabel
                            value="in-person"
                            onChange={this.props.handleRadioSelect}
                            checked={
                              this.props.radioSelect === "in-person"
                                ? true
                                : false
                            }
                            control={<Radio />}
                            label="In-person"
                          />
                        </RadioGroup>
                      </FormControl>
                      {this.props.radioSelectError && (
                        <FormHelperText style={{ marginLeft: "25px" }}>
                          {this.props.radioSelectErrorMsg}
                        </FormHelperText>
                      )}
                    </Grid>

                    {this.props.radioSelect === "virtual" && (
                      <Grid className={classes.urlPostGrid} item xs={12}>
                        <FormControl
                          className={`CustomTextFieldFormControl`}
                          style={webPage.eventStyle}
                        >
                          <Typography
                            component={"span"}
                            className={`Heading6 BoldFont PrimaryColor2`}
                            style={webPage.eventName}
                          >
                            Event URL*
                          </Typography>
                          <CustomTextField
                            placeHolderText="Add Event URL"
                            fieldName="eventUrl"
                            fieldId="event-id"
                            fieldValue={this.props.eventUrl}
                            fieldType="text"
                            handleChange={this.props.handleChange}
                            fieldError={this.props.eventUrlError}
                            helperText={this.props.eventUrlErrorMessage}
                            startAdornmentIcon={
                              <LinkIcon
                                className={`CursorPointer`}
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke="#005487"
                                strokeWidth="1.5"
                                fill="none"
                              />
                            }
                          />
                        </FormControl>
                        {this.props.eventUrlError && (
                          <FormHelperText style={{ marginLeft: "25px" }}>
                            {this.props.eventUrlErrorMessage}
                          </FormHelperText>
                        )}
                      </Grid>
                    )}

                    {this.props.radioSelect === "in-person" && (
                      <Grid className={classes.urlPostGrid} item xs={12}>
                        <FormControl
                          style={webPage.eventStyleStreet}
                          className={`CustomTextFieldFormControl ${classes.urlPostGridFormControl}`}
                        >
                          <Typography
                            className={`Heading6 BoldFont PrimaryColor2 ${classes.label}`}
                            component={"span"}
                          >
                            Address*
                          </Typography>
                          <CustomTextField
                            placeHolderText="Enter your Street"
                            fieldName="street"
                            fieldId="user-profile-street"
                            fieldValue={this.props.street}
                            fieldType="text"
                            handleChange={this.props.handleChange}
                            fieldError={this.props.streetError}
                            helperText={this.props.streetErrorMsg}
                            startAdornmentIcon={
                              <LocationAddIcon
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke={"#005487"}
                                strokeWidth="1.0"
                                fill={"none"}
                              />
                            }
                          />
                          <FormHelperTextComponent
                            errorMessage={this.props.streetErrorMsg}
                            error={this.props.streetError}
                          />
                          <br />
                          <CustomTextField
                            placeHolderText="Enter your City*"
                            fieldName="city"
                            fieldId="user-profile-city"
                            fieldValue={this.props.city}
                            fieldType="text"
                            handleChange={this.props.handleChange}
                            fieldError={this.props.cityError}
                            helperText={this.props.cityErrorMsg}
                            startAdornmentIcon={
                              <LocationAddIcon
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke={"#005487"}
                                strokeWidth="1.0"
                                fill={"none"}
                              />
                            }
                          />
                          <FormHelperTextComponent
                            error={this.props.cityError}
                            style={{ marginLeft: "8px" }}
                            errorMessage={this.props.cityErrorMsg}
                          />
                          <br />
                          <CustomTextField
                            placeHolderText="Enter your State*"
                            fieldName="state"
                            fieldId="user-profile-state"
                            fieldValue={this.props.state}
                            fieldType="text"
                            handleChange={this.props.handleChange}
                            fieldError={this.props.stateError}
                            helperText={this.props.stateErrorMsg}
                            startAdornmentIcon={
                              <LocationAddIcon
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke={"#005487"}
                                strokeWidth="1.0"
                                fill={"none"}
                              />
                            }
                          />
                          <FormHelperTextComponent
                            errorMessage={this.props.stateErrorMsg}
                            error={this.props.stateError}
                          />
                          <br />
                          <CustomTextField
                            placeHolderText="Enter your Zipcode*"
                            fieldName="zipcode"
                            fieldId="user-profile-zipcode"
                            fieldValue={this.props.zipcode}
                            fieldType="number"
                            handleChange={this.props.handleChange}
                            fieldError={this.props.zipcodeError}
                            helperText={this.props.zipcodeErrorMsg}
                            startAdornmentIcon={
                              <LocationAddIcon
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke={"#005487"}
                                strokeWidth="1.0"
                                fill={"none"}
                              />
                            }
                          />
                          <FormHelperTextComponent
                            errorMessage={this.props.zipcodeErrorMsg}
                            error={this.props.zipcodeError}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid className={classes.urlPostGrid} item xs={12}>
                      <FormControl
                        className={`CustomTextFieldFormControl`}
                        style={webPage.eventStyle}
                      >
                        <Typography
                          component={"span"}
                          className={`Heading6 BoldFont PrimaryColor2`}
                          style={webPage.eventName}
                        >
                          Event Name*
                        </Typography>
                        <CustomTextField
                          placeHolderText="Enter your Event Name"
                          fieldName="event"
                          fieldId="event-id"
                          fieldValue={this.props.event}
                          fieldType="text"
                          handleChange={this.props.handleChange}
                          fieldError={this.props.eventError}
                          helperText={this.props.eventErrorMessage}
                          startAdornmentIcon={
                            <CreateEventIcon
                              className={`CursorPointer`}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke="#005487"
                              strokeWidth="1.5"
                              fill="none"
                            />
                          }
                        />
                      </FormControl>
                      {this.props.eventError && (
                        <FormHelperText style={{ marginLeft: "25px" }}>
                          {this.props.eventErrorMessage}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid className={classes.urlPostGrid} item xs={12}>
                      <FormControl
                        className={classes.urlPostGridFormControl}
                        style={webPage.eventStyle}
                      >
                        <Typography
                          className={`Heading6 BoldFont PrimaryColor2 ${classes.label}`}
                          component={"span"}
                          style={webPage.eventName}
                        >
                          Timezone of Event*
                        </Typography>
                        <CustomSelectDropdown
                          placeHolderText="Select Timezone of Event"
                          fieldId="event-timezone"
                          fieldValue={this.props.timezone}
                          fieldName="timezone"
                          handleChange={this.props.handleChangeDropdownValue}
                          MenuProps={MenuProps}
                          renderValue={this.props.renderValueTimezone}
                          fieldError={this.props.timezoneError}
                          fieldMenuItems={this.props.timezoneList}
                          iconComponent={
                            <DropdownIcon
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke={"#005487"}
                              strokeWidth="1.5"
                              fill={"none"}
                            />
                          }
                          startAdornmentIcon={
                            <TimeIcon
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke={"#005487"}
                              strokeWidth="1.0"
                              fill={"none"}
                            />
                          }
                        />
                        <FormHelperText>
                          {this.props.timezoneErrorMsg}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid
                      className={classes.urlPostGrid}
                      item
                      xs={12}
                      style={webPage.eventStyle}
                    >
                      <FormControl
                        style={{ width: "34.5rem" }}
                        className={classes.urlPostGridFormControl}
                      >
                        <Typography
                          component={"span"}
                          className={`Heading6 BoldFont PrimaryColor2 ${classes.label}`}
                        >
                          Date*
                        </Typography>
                        <CustomDatepicker
                          disableFutureDate={false}
                          disablePast
                          placeHolderText={"Start Date"}
                          format="MM-DD-YYYY"
                          selectedDate={this.props.dateStart}
                          handleChangeDate={this.props.handleDateStartChange}
                          calendarIcon={
                            <CalendarIcon
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke={"#005487"}
                              strokeWidth="1.0"
                              fill={"none"}
                            />
                          }
                          openCalendarIcon={
                            <div style={{ margin: "10px" }}>
                              <DropdownIcon
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke={"#005487"}
                                strokeWidth="1.5"
                                fill={"none"}
                              />
                            </div>
                          }
                        />
                        <FormHelperTextComponent
                          errorMessage={this.props.dateStartErrorMsg}
                          error={this.props.dateStartError}
                        />
                        <CustomDatepicker
                          disableFutureDate={false}
                          placeHolderText={"End Date"}
                          format="MM-DD-YYYY"
                          handleChangeDate={this.props.handleDateEndChange}
                          minDate={this.props.dateStart}
                          selectedDate={this.props.dateEnd}
                          disablePast
                          calendarIcon={
                            <CalendarIcon
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke={"#005487"}
                              strokeWidth="1.0"
                              fill={"none"}
                            />
                          }
                          openCalendarIcon={
                            <div style={{ margin: "10px" }}>
                              <DropdownIcon
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke={"#005487"}
                                strokeWidth="1.5"
                                fill={"none"}
                              />
                            </div>
                          }
                        />
                        <FormHelperTextComponent
                          errorMessage={this.props.dateEndErrorMsg}
                          error={this.props.dateEndError}
                        />
                      </FormControl>
                    </Grid>
                    <Grid className={classes.urlPostGrid} item xs={12}>
                      <FormControl
                        className={classes.urlPostGridFormControl}
                        style={webPage.eventStyle}
                      >
                        <Typography
                          component={"span"}
                          style={webPage.eventName}
                          className={`Heading6 BoldFont PrimaryColor2 ${classes.label}`}
                        >
                          Time*
                        </Typography>
                        <CustomSelectDropdown
                          placeHolderText="Start Time"
                          fieldId="event-timezone"
                          fieldValue={this.props.startTime}
                          fieldName="timezone"
                          handleChange={
                            this.props.handleChangeDropdownStartTImeValue
                          }
                          MenuProps={MenuProps}
                          renderValue={this.props.renderValueStartTimezone}
                          fieldError={this.props.timezoneStartTimeError}
                          fieldMenuItems={this.props.timezoneStartTimeList}
                          iconComponent={
                            <DropdownIcon
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke={"#005487"}
                              strokeWidth="1.5"
                              fill={"none"}
                            />
                          }
                          startAdornmentIcon={
                            <TimeIcon
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke={"#005487"}
                              strokeWidth="1.0"
                              fill={"none"}
                            />
                          }
                        />
                        <TimezoneStart
                          timezoneStartTimeError={
                            this.props.timezoneStartTimeError
                          }
                        />
                        <br />
                        <CustomSelectDropdown
                          placeHolderText="End Time"
                          fieldId="event-timezone"
                          fieldValue={this.props.endTime}
                          fieldName="timezone"
                          handleChange={
                            this.props.handleChangeDropdownEndTimeValue
                          }
                          MenuProps={MenuProps}
                          renderValue={this.props.renderValueEndTimezone}
                          fieldError={this.props.timezoneEndError}
                          fieldMenuItems={this.props.timezoneEndTimeList}
                          iconComponent={
                            <DropdownIcon
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke={"#005487"}
                              strokeWidth="1.5"
                              fill={"none"}
                            />
                          }
                          startAdornmentIcon={
                            <TimeIcon
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke={"#005487"}
                              strokeWidth="1.0"
                              fill={"none"}
                            />
                          }
                        />
                        <TimezoneEnd
                          timezoneEndError={this.props.timezoneEndError}
                        />
                      </FormControl>
                    </Grid>
                    <Grid className={classes.urlPostGrid} item xs={12}>
                      <FormControl
                        className={`CustomTextFieldFormControl`}
                        style={webPage.eventStyle}
                      >
                        <Typography
                          component={"span"}
                          className={`Heading6 BoldFont PrimaryColor2`}
                          style={webPage.eventName}
                        >
                          Event Description*
                        </Typography>
                        <FormControl onClick={this.props.handleDescModal}>
                          <TextField
                            multiline
                            placeholder="Add an Event Description here"
                            id="event-id"
                            type="text"
                            name="eventDesc"
                            value={this.props.eventDesc}
                            onKeyPress={this.props.handleKeyPress}
                            onChange={this.props.handleChange}
                            error={this.props.eventDescError}
                            helperText={this.props.eventErrorDescMessage}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  className={classes.inputAdornment}
                                  position="start"
                                >
                                  <EditIcon
                                    className={`CursorPointer`}
                                    width="24"
                                    viewBox="0 0 24 24"
                                    height="24"
                                    strokeWidth="1.5"
                                    stroke="#005487"
                                    fill="none"
                                  />
                                </InputAdornment>
                              ),
                              disableUnderline: true,
                            }}
                            inputProps={{
                              className: `${classes.textFieldInput} ${classes.textFieldFont}`,
                            }}
                          />
                        </FormControl>
                      </FormControl>
                      <EventDesc
                        eventErrorDescMessage={this.props.eventErrorDescMessage}
                        eventDescError={this.props.eventDescError}
                      />
                    </Grid>
                  </Box>
                ) : (
                  <Box
                    style={{
                      overflowY: "auto",
                      maxHeight: "25rem",
                      overflowX: "hidden",
                    }}
                  >
                    <Grid className={classes.urlPostGrid} item xs={12}>
                      <FormControl
                        style={webPage.eventStyleModal}
                        className={`CustomTextFieldFormControl`}
                      >
                        <Typography
                          component={"span"}
                          style={webPage.eventName}
                          className={`Heading6 BoldFont PrimaryColor2`}
                        >
                          Event Description*
                        </Typography>
                        <TextField
                          multiline
                          id="event-id"
                          placeholder="Add an Event Description here"
                          name="eventDesc"
                          value={this.props.eventDesc}
                          type="text"
                          onChange={this.props.handleChange}
                          onKeyPress={this.props.handleKeyPress}
                          error={this.props.eventDescError}
                          helperText={this.props.eventErrorDescMessage}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                className={classes.inputAdornment}
                                position="start"
                              >
                                <EditIcon
                                  className={`CursorPointer`}
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke="#005487"
                                  strokeWidth="1.5"
                                  fill="none"
                                />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                          inputProps={{
                            className: `${classes.textFieldInput} ${classes.textFieldFont}`,
                          }}
                        />
                      </FormControl>
                      <EventDesc
                        eventErrorDescMessage={this.props.eventErrorDescMessage}
                        eventDescError={this.props.eventDescError}
                      />
                    </Grid>
                  </Box>
                )}

                {!this.props.eventDescModal ? (
                  <Grid item xs={12} className={classes.doneBtnGrid}>
                    <Button
                      disabled={false}
                      variant="contained"
                      onClick={this.props.handleSubmit}
                      style={webPage.Postbtn}
                    >
                      Next
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12} className={classes.doneBtnGrid}>
                    <Button
                      variant="contained"
                      disabled={false}
                      style={webPage.PostbtnModal}
                      onClick={this.props.handleDescClose}
                    >
                      Done
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Card>
          </Box>
        </Fade>
      </Modal>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webPage = {
  eventStyle: {
    marginLeft: "22px",
    width: "556px",
  },
  eventStyleModal: {
    width: "556px",
    height: "25rem",
    marginLeft: "22px",
  },
  eventName: {
    marginBottom: "12px",
  },
  eventStyleStreet: {
    width: "556px",
    marginBottom: "9px",
    marginLeft: "22px",
  },

  Postbtn: {
    background: "#01548a",
    color: "#fff",
    borderRadius: "0px",
    width: "165px",
    marginTop: "17px",
    padding: "8px 0px",
  },
  PostbtnModal: {
    color: "#fff",
    background: "#01548a",
    width: "36rem",
    borderRadius: "0px",

    padding: "8px 0px",
    marginTop: "17px",
  },
  PostbtnOpacity: {
    background: "#01548a",
    color: "#fff",
    borderRadius: "0px",
    width: "165px",
    opacity: "0.3",
    padding: "8px 0px",
  },
  Publishbtn: {
    background: "#01548a",
    width: "100%",
    color: "#fff",
  },
  media: {
    width: "556px",
    height: "200px",
    minWidth: "480",
    margin: "19px 7px 20px 24px",
    backgroundColor: "#F4F4F4",
  },
  cardText: {
    paddingTop: "78px",
    marginLeft: "13rem",
    marginBottom: "-180px",
  },
  label: {
    color: "#01548a",
    fontSize: "14px",
    marginBottom: "10px",
    fontWeight: "bold",
  } as const,
};

const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: "12px",
      maxHeight: "300px",
    },
  },
};

export default withStyles(Poststyles, { withTheme: true })(
  CreateEventStepOneModal
);
// Customizable Area End
