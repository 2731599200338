// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const LocationAddIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M9.25 11H14.75" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" />
            <path d="M12 13.75V8.25" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" />
            <path d="M3.62 8.49C5.59 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.39 20.54C5.63 17.88 2.47 13.57 3.62 8.49Z" fill="none" strokeWidth={props.strokeWidth} />
        </SvgIcon>
    );
}

export default LocationAddIcon;
// Customizable Area End