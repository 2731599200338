// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { IBlock } from "../../../framework/src/IBlock";

import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify"
import { PostUser } from "./assets";
import ApiCall from "../../../components/src/ApiCall.web";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export interface IEvent {
  id: string;
  type: string;
  attributes: {
    id: number;
    event_type: string;
    event_name: string;
    event_url: string;
    timezone: string;
    description: string;
    start_date: string;
    end_date: string;
    street: string;
    city: string;
    state: string;
    zipcode: string;
    creating_time: string;
    start_time: string;
    end_time: string;
    cover_image: string;
    is_interested: boolean;
    interested_count: number;
    is_login_user_event: boolean;
    created_at: string;
    full_name: string | null;
    community_name: string;
    profile_picture: string | null;
    community_profile: string;
    community_account_id: number;
    interest_event_count: {
      account_images: {photo:string|null}[];
      count: number | null;
    }[];
  }
}

interface IPost {
  id: string;
    type: string;
    attributes: {
        id: number;
        name: string | null;
        description: string;
        url: string | null;
        account_id: number | null;
        community_id: number | null;
        post_type: string;
        created_at: string;
        updated_at: string;
        creating_time: string;
        thumbnail: string | null;
        images_and_videos: string[]; // This should be defined as needed
        post_like_count: number | null;
        full_name: string | null;
        community_name: string | null;
        profile_picture: string | null;
        community_profile: string | null;
        community_account_id: number | null;
        is_liked: boolean;
        comments_count: number;
        is_followed: boolean;
        post_save_by_current_user: boolean;
        is_login_user_post: boolean;
        is_followed_community: boolean;
        is_followed_by_login_user: boolean;
        video_title: string | null;
        post_shared_by: string | null;
        shared_account_profile: string | null;
        community_shared_by: string | null;
        shared_community_profile: string | null;
        like_by: string | null;
        shared_at: string;
        sharing_time: string;
        comment_by: string | null;
        shared_item_id: string | null;
        notification_turn_on: boolean;
        video_thumbnail: string | null;
    };
}
// Customizable Area End

export interface Props {
   // Customizable Area Start
  navigation: any;
  id: string;
  filter?: boolean;
  resetData: ()=>void;
  classes: any
  // Customizable Area End
}

interface S {
    // Customizable Area Start
  searchValue: any
  postList: (IPost | IEvent)[];
  items: string[];
  loading: boolean
  commentOpen:boolean
  loggedInUserData:any;
  selectedPostId:number
  fullName:string
  email:string
  city:string
  state:string
  editData:any
  postCommunityCount:number;
  userPronoun:string
  profileImageSrc:string
  followerCount:number
  is_Interested:boolean;
  ID:any;
  postCount:number,
  newPostListCount:number,
  communityFlag:any
  communityData:any,
  tabbed:string,
  postLoad:boolean,
  pathRoute:boolean,
  tabValue: number,
  filter: string,
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
 // Customizable Area Start

  // Customizable Area End
  
export default class LandingPageNewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  listPostId: string = '';
  apiCreatePostLikeId:string ="";
  apiGetUserData:string = "";
  interestedSingleApiCallId:any;
  // Customizable Area End

    constructor(props: Props) {
      super(props);
            // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),

    ]
      // Customizable Area End

    this.state = {
       // Customizable Area Start
      searchValue: '',
      postCommunityCount:0,
      postList: [],
      loading: false,
      postLoad:false,
      loggedInUserData:{},
      editData:{},
      commentOpen:false,
      selectedPostId:0,
      fullName:"",
      pathRoute:false,
      email:"",
      city:"",
      state:"",
      userPronoun:"",
      tabbed:"Home",
      items:[   'Home','Photo',
      'Video',
      'Event',
      'URL',
     
    "Post"],
      profileImageSrc:"",
      followerCount:0,
      newPostListCount:0,
      postCount:0,
      communityFlag:"false",
      communityData:{},
      is_Interested:false,
      ID:"",
      tabValue: 0,
      filter: '',
        // Customizable Area End

    };
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
     // Customizable Area End
  }

  async receive(from: string, message: Message) {
        // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

        switch(apiRequestCallId){
          case this.listPostId:
            {
             this.handleGetPostList(responseJson)
            }
          break;
        case this.interestedSingleApiCallId:
            {
             this.interestedEventSucessCallBack(responseJson)
            }
          break;
         
          default:return;
        }
       
    }
    
    // Customizable Area End
  }
  // Customizable Area Start
  editedPost=(id:string)=>{
    this.setState({editData:this.state.postList.find((item: { id: string; }) =>item.id===id)})
  }
  async componentDidMount() {
    super.componentDidMount();
    this.setState({loading:true})
    await this.getPostList();
  }

  // for my community tab post list
  handleMycommunityTabApi = async (filter: string) => {
    let signupToken = await getStorageData('SignupSuccessToken')
    let loginToken = await getStorageData('LoginSuccessToken')
    let Page_size= Math.ceil(this.state.postList.length/10) + 1;
    const header = {
      token: signupToken || loginToken,
      "Content-Type": "application/JSON",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.listPostId = requestMessage.messageId;
      const param = filter ? `?filter_type=${filter}&page=${Page_size}&per_page=10` : `?page=${Page_size}&per_page=10`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.community_data + param
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethode
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

 clickTab=(items:string)=>{
  this.setState({tabbed:items})
 }

  handleInterestedEvent =(item : any)=>{ 
    this.setState({ is_Interested: !this.state.is_Interested ? true : false }, () => {
      this.interestedsingleEvent(item)
   }) 
  }
  eventDetailNavigation = (item:any)=>{
  localStorage.setItem('EventId', item.attributes.id)
  this.props.navigation.navigate('CalenderEventDetails',{id: item.attributes.id})
  }

  responseSucessCell = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.interestedSingleApiCallId) {
      this.interestedEventSucessCallBack(responseJson);
    }
  }

  interestedsingleEvent = async (item:any) => {
    let formdata = new FormData();
    formdata.append("event_id",item);
    formdata.append("is_interested", JSON.stringify(this.state.is_Interested));

    this.interestedSingleApiCallId = await ApiCall({
      method: "POST",
      endPoint: `/bx_block_events/events/event_make_interested`,
      body: formdata,
      type: 'formData'
    });
  };

  interestedEventSucessCallBack = (res: any) => {
    if(res?.data){
      // this.getApiRequest()
      toast.success(res.data[0].message)
    }
  };
  
  handleGetPostItemSrc = (item: any) => {
    let src: any = null;
    if (item && item.attributes && item.attributes.images_and_videos) {
      src = item.attributes.images_and_videos;
    }
    if (item && item.attributes && item.attributes.thumbnail) {
      src = item.attributes.thumbnail;
    }
    if (item && item.attributes && item.attributes.cover_image) {
      src = item.attributes.cover_image;
    }
    return src;
  }  

  handleGetPostItemUserProfilePic = (item : any) =>{
    let profile :  any =  PostUser;

   if (item&& item.attributes&&item.attributes.profile_picture) {
     profile =item.attributes.profile_picture}

 
     if (item&& item.attributes&&item.attributes.community_profile) {
      profile =item.attributes.community_profile
    }

    
 

      
    return profile
  }

  handleErrorToast=(responseJson:any)=>{
    if(responseJson?.errors[0].token!=="Invalid token" || responseJson?.errors[0].token!=="Token has Expired")
    {
      localStorage.clear();
      this.props.navigation.navigate("LogIn")
    }
  }

  handleSetUserDetails = (responseJson: any) => {
    if (!responseJson || !responseJson.errors) {
      if (responseJson && responseJson.data) {
        this.hadleSetUserComplexity(responseJson);
        if (responseJson.data  && responseJson.data.profile_picture) {
          const profileSrc = responseJson.data.profile_picture;
          this.setState({ profileImageSrc: profileSrc });
        }
      }
    } else{

      this.handleErrorToast(responseJson)
    
      if(responseJson?.errors[0].token!=="Invalid token")
      {
       
      for(let key in responseJson.errors[0])
      {
        toast.error(responseJson.errors[0]?.[key])
      }}
    }

  }

  hadleSetUserComplexity=(responseJson:any)=>{

  
    this.setState({
      loggedInUserData: { ...responseJson.data },
      email: responseJson.data && responseJson.data.email,
      fullName: responseJson?.data?.full_name,
      city: responseJson.data && responseJson.data.city,
      state: responseJson.data && responseJson.data.state,
      userPronoun: responseJson.data && responseJson.data.pronouns,
      followerCount: responseJson.data && responseJson.data.follower_count,
      postCount: (responseJson.data && responseJson.data.posts && responseJson.data.posts.data && responseJson.data.posts.data.length) || 0,
    })}
  
  
  

 handleGetUserDetails = () =>{
  let userData = JSON.parse(localStorage.getItem("profileData") as string);
  const userDataFromStorage : any = { 
    data : { ...userData}
  }
  this.setState({ profileImageSrc :userData?.attributes?.profile_picture})

  this.handleSetUserDetails(userDataFromStorage)
 }

 tempPostLike=(responseJson:any)=>{
 const temp= responseJson && responseJson.data && responseJson.data.map((item: any) => {
    item.like = item && item.attributes && item.attributes.is_liked;
    return item;
  });
  return temp;
 }

 handleGetPostList = async (responseJson: any) => {
  this.setState({loading: false})
  if( responseJson  && responseJson.data){
    this.setState({ postList:[...this.state.postList,...responseJson.data],newPostListCount:responseJson.data.length});
  }else{
    this.setState({newPostListCount: 0});
  } 
}
postListFun = () =>{
  const userPostCount = localStorage.getItem("LoggedInUserPostCount")
  const postCounting= Number(userPostCount)

  const userPostCountOne = localStorage.getItem("LoggedInUserPostCountCommunity")
  const postCountingOne= Number(userPostCountOne)

    this.setState({postList:[],postCount:this.state.communityFlag=="false"?postCounting-1:postCountingOne-1});
  
   
 
  localStorage.setItem(this.state.communityFlag=="false"?"LoggedInUserPostCount":"LoggedInUserPostCountCommunity" , JSON.stringify(this.state.postCount))

}

handleGetPostListElse=(responseJson:any)=>{
  if (responseJson && responseJson.errors && responseJson.errors.length) {
    for (let key in responseJson.errors[0]) {
      toast.error(responseJson.errors[0][key]);
    }
  } else if (responseJson && responseJson.error && responseJson.error.length) {
    for (let key in responseJson.error[0]) {
      toast.error(responseJson.error[0][key]);
    }
  }
}




  
  getPostList = async() => {
   if(this.props.filter) return this.handleMycommunityTabApi(this.state.filter);

   let Page_Limit=10;

   let Page_size= Math.ceil(this.state.postList.length/Page_Limit) + 1;

    let signupToken = await getStorageData('SignupSuccessToken')
    let loginToken = await getStorageData('LoginSuccessToken')
 
    const header = {
      token: signupToken || loginToken,
      "Content-Type": "application/JSON",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.listPostId = requestMessage.messageId;

 
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/posts/posts?page=${Page_size}&per_page=${Page_Limit}`
      );
 

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

 
  
deletePost=(id:string)=>{

  const updatedList= [...this.state.postList];
const index=updatedList.findIndex(item=>item.id===id);

  updatedList.splice(index, 1);
  this.setState({ postList: updatedList });
  this.props.resetData();


}
  
followUpdateList = (type: string, id: string | number, action: string) => {
  const updatedList = this.state.postList.map((item): IPost | IEvent => {
      if ("attributes" in item && "account_id" in item.attributes && type === "account" && item.attributes.account_id === id) {
          const updatedAttributes = { ...item.attributes, is_followed_by_login_user: action === "follow" };
          return { ...item, attributes: updatedAttributes } as IPost;
      } else if ("attributes" in item && "community_id" in item.attributes && item.attributes.community_id === id) {
          const updatedAttributes = { ...item.attributes, is_followed_by_login_user: action === "follow" };
          return { ...item, attributes: updatedAttributes } as IPost;
      } else {
          return item;
      }
  });
  this.setState({ postList: updatedList });
};

  updatePostCount = async() => {
   localStorage.setItem(this.state.communityFlag=="false"?"LoggedInUserPostCount":"LoggedInUserPostCountCommunity" , JSON.stringify(this.state.postCount))
   this.props.navigation.navigate("LandingScreen")
  }

  updatePostList = (postItem : any) => {



const postIndex=this.state.postList.findIndex((item: { id: any; })=>item.id===postItem.id)

if(postIndex!== -1)
{

  const updated=[...this.state.postList]

  updated[postIndex]=postItem;
     this.setState({postList:updated});

}
else
{    
  const updatedPostList = [{...postItem} , ...this.state.postList];
   this.setState({postList : updatedPostList})
   this.props.resetData();
  }
     
 }
 a11yProps(index: number) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
  const stateUpdates: {[key: number]: string} = {
    0: '',
    1: 'photos',
    2: 'videos',
    3: 'events',
    4: 'urls',
  };
  
  this.setState({ tabValue: newValue,filter: stateUpdates[newValue],postList:[]});
  this.handleMycommunityTabApi(stateUpdates[newValue])
};

navigateToEvents = (id: string|number) => {
  this.props.navigation.navigate("CalendarEventDetails",{id:id})
}
 // Customizable Area End
}
