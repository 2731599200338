// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import { firebaseAPI, getNotificationPermission, messageListener } from '../../components/src/firebase'
import { connect } from 'react-firebase'
import { toast, ToastContainer } from 'react-toastify';
import { Box } from "@material-ui/core"
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import SwitchAccountWeb from "../../blocks/LinkedAccounts/src/SwitchAccount.web";
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import LinkedAccounts from "../../blocks/LinkedAccounts/src/LinkedAccounts";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import UserGroups from "../../blocks/UserGroups/src/UserGroups";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import CreateCommunity from "../../blocks/postcreation/src/CreateCommunity";
import Customform from "../../blocks/customform/src/Customform";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import GroupChat from "../../blocks/GroupChat/src/GroupChat";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web.tsx";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import Notifications from "../../blocks/notifications/src/Notifications.web";
import Filteritems from "../../blocks/filteritems/src/FilterItemsNew";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import DataStorage from "../../blocks/DataStorage/src/DataStorage";
import CfVideoEmbeddingVimeo6 from "../../blocks/CfVideoEmbeddingVimeo6/src/CfVideoEmbeddingVimeo6";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Scoring from "../../blocks/Scoring/src/Scoring";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import SalesforceIntegration from "../../blocks/SalesforceIntegration/src/SalesforceIntegration";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import Sorting from "../../blocks/sorting/src/Sorting";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Chat9 from "../../blocks/Chat9/src/Chat9";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import VideoEmbeddingYoutube from "../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest";
import AssessmentQuestions from "../../blocks/AssessmentTest/src/AssessmentQuestions/AssessmentQuestions";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import UserGroups2 from "../../blocks/UserGroups2/src/UserGroups2";
import TermsAndConditions3 from "../../blocks/TermsAndConditions3/src/TermsAndConditions3";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import CreateChat from "../../blocks/chat/src/CreateChat.web";
import ReportChat from "../../blocks/chat/src/ReportPage.web";
import Help from "../../blocks/ActivityFeed/src/Help.web";
import SinglePost from "../../blocks/ActivityFeed/src/SinglePost.web";
import CreateTest from "../../blocks/QuestionBank/src/CreateQuestion.web";
import Settings from "../../blocks/Settings/src/Settings";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import SignIn from '../../blocks/email-account-registration/src/SignUpNew.web';
import Welcome from '../../blocks/ActivityFeed/src/WelcomeNew.web';
import LoginNew from '../../blocks/email-account-login/src/LoginNew.web';
import PhoneVerification from '../../blocks/ActivityFeed/src/PhoneVerificationNew.web';
import LandingScreen from '../../blocks/landingpage/src/LandingScreenNew.web';
import CommunityListing from '../../blocks/LinkedAccounts/src/CommunityListing.web'
import UserProfileInformation from '../../blocks/CustomisableUserProfiles/src/UserProfileInformation.web';
import UserOrganizationInformation from "../../blocks/CustomisableUserProfiles/src/UserOrganizationInformation.web";
import UserProfileInformationEdit from "../../blocks/CustomisableUserProfiles/src/UserProfileInformationEdit.web"
import OrganizationProfileEdit from "../../blocks/CustomisableUserProfiles/src/OrganizationProfileEdit.web"
import  ForgotPasswordPhoneVerification from '../../blocks/forgot-password/src/ForgotPasswordPhoneVerification.web';
import 'react-toastify/dist/ReactToastify.css';
import Achievements from '../../blocks/customform/src/Achievements.web';
import CalendarEvents from '../../blocks/scheduling/src/CalenderEvents.web'
import CalendarEventsDetails from '../../blocks/scheduling/src/CalenderEventsDetails.web';
import ViewProfileScreen from "../../blocks/ActivityFeed/src/ViewProfileScreen.web";
import ContactInfo  from "../../blocks/ActivityFeed/src/ContactInfo.web";
import ViewOtherUserProfile from "../../blocks/ActivityFeed/src/ViewOtherUserProfie.web";
import FollowersList from "../../blocks/RequestManagement/src/FollowersList.web";
import MyEvent from "../../blocks/postcreation/src/MyEvent.web"
import EventDetails from "../../blocks/postcreation/src/EventDetails.web"
import Report  from "../../blocks/scheduling/src/Report.web"
import SavePost from "../../blocks/ActivityFeed/src/SavePost.web"
import CreateCollection from "../../blocks/ActivityFeed/src/CreateCollection.web"
import FriendsRequestList from "../../blocks/RequestManagement/src/FriendsRequestList.web";
import FriendsList from "../../blocks/RequestManagement/src/FriendsList.web";
import MutualConnectionList from "../../blocks/RequestManagement/src/MutualConnectionList.web";
import PhoneVerificationLoginFlow from "../../blocks/email-account-login/src/PhoneVerificationLoginFlow.web";
import AchievementListing from "../../blocks/ActivityFeed/src/AchievementListing.web";
import AssessmentTestUserListing from "../../blocks/AssessmentTest/src/AssessmentTestUserListing.web"
import AssessmentTestViewListing from "../../blocks/AssessmentTest/src/AssessmentTestViewListing.web"
import AssessmentTestListing from "../../blocks/AssessmentTest/src/AssessmentTestListing.web"
import AssessmentResult from "../../blocks/AssessmentTest/src/AssessmentResult/AssessmentResult.web"
import CommunityAssessmentDashboard from "../../blocks/AssessmentTest/src/CommunityAssessmentDashboard.web"

const routeMap = {
  SignIn: {
    component: SignIn,
    path: "/SignUp"
  },
  LogIn: {
    component: LoginNew,
    path: "/Login"
  },
  UserProfileInformationEdit: {
    component: UserProfileInformationEdit,
    path: "/User-Profile-Information/:navigationBarTitleText?"
  },
  PhoneVerification: {
    component: PhoneVerification,
    path: "/PhoneVerification"
  },
  Achievements : {
    component:Achievements,
    path:"/Achievements/:navigationBarTitleText?"},
  CalendarEvents: {
    component: CalendarEvents,
    path: "/CalendarEvents", 
  },
  CalendarEventDetails :{
    component:CalendarEventsDetails ,
     path:"/CalendarEventDetails/:id"
  },
  Report :{
    component:Report,
     path:"/Report/:navigationBarTitleText"
  },
  ForgotPasswordPhoneVerification: {
    component: ForgotPasswordPhoneVerification,
    path: "/ForgotPasswordPhoneVerification"
  },
  LandingScreen: {
    component: LandingScreen,
    path: "/LandingScreen"
  },
  UserProfileInformation: {
    component: UserProfileInformation,
    path: '/User-Profile/:navigationBarTitleText?',
    exact: true
  },
  UserOrganizationInformation: {
    component: UserOrganizationInformation,
    path: '/User-Organization/:navigationBarTitleText?',
    exact: true
  },
  CreateCommunity: {
    component: CreateCommunity,
    path: '/Create-Community',
    exact: true
  },
  UserOrganizationInformationEdit: {
    component: OrganizationProfileEdit,
    path: '/User-Organization-Information/:navigationBarTitleText?',
    exact: true
  },
  Welcome: {
    component: Welcome,
    path: '/',
    exact: true
  },
Settings:{
 component:Settings,
path:"/Settings"},
SwitchAccountWeb:{
  component:SwitchAccountWeb,
 path:"/SwitchAccountModal"},
SavePost:{
  component:SavePost,
 path:"/SavePost"},
 CreateCollection:{
  component:CreateCollection,
 path:"/CreateCollection"},
MyEvent:{
  component:MyEvent,
 path:"/MyEvent"},
  EventDetails:{
    component:EventDetails,
    path:'/EventDetails/:id'},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
ResetPassword:{
  component:ResetPassword,
 path:"/ResetPassword"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},

Chat:{
  component:Chat,
 path:"/Chat"},
 ChatView:{
  component:ChatView,
 path:"/ChatView/:navigationBarTitleText"},
 CreateChat:{
   component:CreateChat,
   path:"/CreateChat/:navigationBarTitleText?"
 },
 ReportChat:{
  component:ReportChat,
  path:"/ReportChat/:navigationBarTitleText"
},
Help:{
  component:Help,
  path:"/Help"
},
SinglePost:{
  component:SinglePost,
  path:"/SinglePost/:navigationBarTitleText"
},
TermsAndConditions3:{
 component:TermsAndConditions3,
path:"/TermsAndConditions3"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},

AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
UserGroups2:{
 component:UserGroups2,
path:"/UserGroups2"},

CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
LinkedAccounts:{
 component:LinkedAccounts,
path:"/LinkedAccounts/:navigationBarTitleText?"},
Communities:{
  component:CommunityListing,
 path:"/Communities"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
UserGroups:{
 component:UserGroups,
path:"/UserGroups"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Customform:{
 component:Customform,
path:"/Customform"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
GroupChat:{
 component:GroupChat,
path:"/GroupChat"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Filteritems:{
  component:Filteritems,
 path:"/Filteritems/:id"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
DataStorage:{
 component:DataStorage,
path:"/DataStorage"},
CfVideoEmbeddingVimeo6:{
 component:CfVideoEmbeddingVimeo6,
path:"/CfVideoEmbeddingVimeo6"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},

Scoring:{
 component:Scoring,
path:"/Scoring"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
SalesforceIntegration:{
 component:SalesforceIntegration,
path:"/SalesforceIntegration"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Chat9:{
 component:Chat9,
path:"/Chat9"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
VideoEmbeddingYoutube:{
 component:VideoEmbeddingYoutube,
path:"/VideoEmbeddingYoutube"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest/:navigationBarTitleText"},
AssessmentQuestions:{
  component:AssessmentQuestions,
  path: "/AssessmentQuestions"
},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},

  Home: {
    component: HomeScreen,
    path: '/Home',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  ProfilePage: {
    component: ViewProfileScreen,
    path:"/view-profile",
    exact: true
  },
  ContactInfo: {
    component: ContactInfo,
    path:"/contact-info/:id",
    exact: true
  },
  OtherUserProfile:{
    component: ViewOtherUserProfile,
    path:"/profile/:id?",
    exact: true
  },
  FollowersList: {
    component: FollowersList,
    path: "/followers/:id?",
    exact: true
  },
  FriendRequests: {
    component: FriendsRequestList,
    path: "/friend-requests/:navigationBarTitleText?",
    exact: true
  },
  FriendsList: {
    component: FriendsList,
    path: "/friends/:id",
    exact: true
  },
  MutualConnectionList: {
    component: MutualConnectionList,
    path: "/mutual-connections/:id",
    exact: true
  },
  PhoneVerificationLoginFlow: {
    component: PhoneVerificationLoginFlow,
    path: "/phone-verification-login",
    exact: true
  },
  CreateTest:{
    component: CreateTest,
    path: "/CreateTest/:navigationBarTitleText?"
  },
  AchievementListing: {
    component: AchievementListing,
    path:"/achievement-listing",
    exact: true
  },
  AssessmentDashboard: {
    component: AssessmentResult,
    path:"/assessment-dashboard/:navigationBarTitleText?",
    exact: true
  },
  AssessmentTestViewListing :{
    component: AssessmentTestViewListing,
    path:"/assessment-test-view/:navigationBarTitleText?"
  },
  AssessmentTestUserListing :{
    component: AssessmentTestUserListing,
    path:"/assessment-test-users",
    exact: true
  },
  AssessmentTestListing :{
    component: AssessmentTestListing,
    path:"/assessment-test-list",
    exact: true
  },
  CommunityAssessmentDashboard : {
    component: CommunityAssessmentDashboard,
    path:"/community-dashboard/:navigationBarTitleText?",
    exact: true
  }
};


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount(){
    await getNotificationPermission();
    messageListener();
    firebaseAPI.messaging().onMessage((payload) => {
      const isTabVisible = document.visibilityState === 'visible';

      if (isTabVisible && Notification.permission === "granted") {
        if (!window.location.pathname?.toLowerCase().includes(payload.data.redirect_to.toLowerCase())) {
          const { title, body } = payload.notification;
          const notification = new Notification(title, { body });
          notification.onclick = () => {
            window.open(payload.data.redirect_to,'_self');
          };
        }
      }
    })
  }

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <Box width={"100%"} height={"100%"} display="flex" flexDirection={"column"} >
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        <ToastContainer
          autoClose={2000}
          position="top-right"
          className="toast-container"
          toastClassName="dark-toast"
        />
      </Box>
    );
  }
}

export default App;