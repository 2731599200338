// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
export const VIDEO_TYPES = {
    Youtube:"youtube",
    Vimeo:"vimeo"
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes: any
    open:boolean;
    onClose:any;
    videoEmbedType:string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean
    videoEmbedUrl: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
export default class LearnEmbeddedModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCreatePostCallId: any
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            isLoading: false,
            videoEmbedUrl:"",
        }
        // Customizable Area End
    }
 

    handleClose = () => {
        this.props.onClose()
    };

    handleChange = (event: any) => {
        const { name, value } = event.target;
        this.setState((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        console.log("apiRequestCallId", apiRequestCallId)

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );


        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.apiCreatePostCallId) {
                if (!responseJson.errors) {
                    console.log("myresponse.........", responseJson)

                } else {
                    console.log(responseJson.errors)
                }
            }
        }


        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const otpAuthTkn = message.getData(
                getName(MessageEnum.AuthTokenDataMessage)
            );
            if (otpAuthTkn && otpAuthTkn.length > 0) {
                runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
            }
        }
        // Customizable Area End
    }

}
// Customizable Area End