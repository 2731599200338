import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { toast } from "react-toastify";
const validPasswordRegex = RegExp(
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]|\\:;"'<,>.?/~`])(?!.*\s).{8,}$/
);
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  forgotPasswordToken: string | null;
  FieldError: any;
  password: any;
  showPassTwo:any,
  emailToken:any;
  password_confirmation: any;
  password_error: any;
  confirmPassword_error: any;
  showPassTwoConfirm:any;
  successMessage: string;
  // Customizable Area End
}

interface SS {
    // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  createAccountApiPasswordId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.state = {
    
      FieldError: "",
      emailToken:null,
      password: "",
      forgotPasswordToken: "",
      password_confirmation: "",
      password_error: "",
      confirmPassword_error: "",
      successMessage: "",
      showPassTwo:false,
      showPassTwoConfirm:false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
 async componentDidMount(){

    let params:any = (new URL(document.location.href)).searchParams;
let name:any = params.get("token");
this.setState({emailToken:name});
    this.setState({
      forgotPasswordToken: localStorage.getItem("forgotPasswordToken"),
    });
  };

  handleLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationLogInWebMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  apicalls = (
    apiRequestCallId: any,
    responseJson: any,
    errorReponse: any,
    message: any
  ) => {
    if (apiRequestCallId === this.createAccountApiPasswordId && responseJson) {
      if (!responseJson.errors) {
        if(responseJson[0] && responseJson[0].data && responseJson[0].data.message)
        {
          this.setState({ successMessage: responseJson[0].data.message });
        }

        this.setState({
          password: "",
          confirmPassword_error: "",
          password_error: "",
          password_confirmation: "",
        });
        toast.success("Password Changed Successfully");
        this.handleLogin();
      } else {
         
        toast.error(responseJson.errors[0].otp)

      } 
    }

       };

     async receive(_from: string, message: Message) 
{


runEngine.debugLog("Message Recived", message);
const apiRequestCallId = message.getData(
   getName(MessageEnum.RestAPIResponceDataMessage)
    );



let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
 );
    
 
 let responseJson = message.getData(
         getName(MessageEnum.RestAPIResponceSuccessMessage)  );
this.apicalls(apiRequestCallId, responseJson, errorReponse, message);

  }
  handleChangeConfirmPassword = (e: any) => {
    this.setState({
      password_confirmation: e.target.value,
      confirmPassword_error: "",
      FieldError: "",
    });
  };
  EyeChangeTwo=()=>{
    this.setState({showPassTwo:!this.state.showPassTwo})
  }
  EyeChangeTwoConfirm=()=>{
    this.setState({showPassTwoConfirm:!this.state.showPassTwoConfirm})
  }

  handleChangePassword = (e: any) => {
    this.setState({
      password: e.target.value,
      password_error: "",
      FieldError: "",
    });
  };

  handlePasswordSubmit = async (e: any) => {
    e.preventDefault();

    if (!this.state.password) {
      this.setState({ password_error: configJSON.PasswordForgot});
    }

    if (!this.state.password_confirmation) {
      this.setState({ confirmPassword_error: "Please Enter Confirm Password" });
    }

    if (this.state.password !== this.state.password_confirmation && this.state.password_confirmation!="") {
      this.setState({
        confirmPassword_error: "Password and Confirm password should remain same",
      });
    }

    if (this.state.password === this.state.password_confirmation) {
      const header = {
        "Content-Type": "application/json",
      };
      const data = {
        data: {
          token:this.state.emailToken?this.state.emailToken:this.state.forgotPasswordToken,
          new_password: this.state.password,
          password_confirmation: this.state.password_confirmation,
        },
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createAccountApiPasswordId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_forgot_password/passwords"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  // Customizable Area End
}
