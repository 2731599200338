import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    styled
} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { AvatarGroup } from '@material-ui/lab';
import Avatar from '@material-ui/core/Avatar';
import CalenderEventsController, {Props, EventData, Interasred } from "./CalenderEventsController.web";
import moment from "moment";
import { Calendar} from "react-multi-date-picker";
import EventIcon from "@material-ui/icons/Event";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { blankProfile } from "../../postcreation/src/assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class CalenderEvents extends CalenderEventsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
  
    renderHeader = () => {
        return (
            <Box style={webStyle.imageUploads} >
                <Button data-test-id="btnDaily" fullWidth onClick={() => this.handleheader('daily')} style={this.state.selectAppBar === "daily" ? webStyle.imageBtn : webStyle.bordernone}>Daily</Button>
                <Button data-test-id="btnWeekly" fullWidth onClick={() => this.handleheader('weekly')} style={this.state.selectAppBar === "weekly" ? webStyle.imageBtn : webStyle.bordernone}>Weekly</Button>
                <Button data-test-id="btnMonthly" fullWidth onClick={() => this.handleheader('monthly')} style={this.state.selectAppBar === "monthly" ? webStyle.imageBtn : webStyle.bordernone}>Monthly</Button>
            </Box>
        );
    };

    calenderRender = () => {
        return (
            <CalendarBox>
                <CustomCalender
                    format="DD MMM YYYY"
                    className={this.state.colorFlag?"event-calendar":"react-calendar"}
                    monthYearSeparator=" "
                    data-test-id={"calendar"}
                    onChange={this.handleheaderDaly}
                    multiple={this.state.selectAppBar !== "daily"}
                    onMonthChange={this.ChangeMonth}
                    value={this.state.selectAppBar !== 'daily' ? this.state.arrayHolder?.map((e: EventData) => e.attributes.start_date) : this.state.selectedDate}
                />
            </CalendarBox>
        )
    }
    
    renderEventCard = () => {
        return (<>
            {this.state.newArraydaily === undefined || this.state.newArraydaily.length === 0 ?
                (<Typography variant="h6" align="center" color="textSecondary">No events scheduled for the selected day</Typography>)
                : (this.state.newArraydaily.map((data: EventData, index: number) => {
                    return (<GridCardContainer container item sm={12} data-test-id={`eventData_${index}`} onClick={() => this.NavigateToCalendarDetails(data.id)}>
                        <GridCardImgContainer container item sm={5}>
                            <CardImg src={data.attributes.cover_image}/>
                            <GradientBox></GradientBox>
                        </GridCardImgContainer>
                        <GridContentContainer container item sm={7}>
                            <Grid item style={webStyle.gridEventName}>
                                <Typography variant="h6" style={webStyle.eventName}>{data.attributes.event_name.slice(0,15)}</Typography>
                                <Typography variant="subtitle2" style={webStyle.fullNameEvent}>{data.attributes.full_name} </Typography>
                                <AvatarGroup spacing="small" style={webStyle.avatarGroup}>
                                    {data?.attributes.interest_event_count[0].account_images.map((img: Interasred) => {
                                        return (
                                            <Avatar alt="Remy Sharp" style={webStyle.avtarImg} src={img.photo === null ? blankProfile : img.photo} />
                                        )
                                    })}
                                    {data?.attributes.interest_event_count[0].count !== null ?
                                        <Avatar style={webStyle.avtarImg2}  >+{data?.attributes.interest_event_count[0].count}</Avatar> : ""}

                                </AvatarGroup>
                            </Grid>
                            <GridDateContainer item>
                                <DateTxt align="right">{moment(new Date(data.attributes.start_date)).format("DD")}</DateTxt>
                                <DateTxt style={{ marginBottom: "15px" }} align="right">
                                    {moment(new Date(data.attributes.start_date)).format("MMMM").toUpperCase().slice(0, 3)}
                                </DateTxt>
                                <TimeTxt>{moment(data.attributes.start_time, 'HH:mm A').format("hh:mm a")} - {moment(data.attributes.end_time, 'HH:mm A').format("hh:mm a")}</TimeTxt>
                            </GridDateContainer>
                        </GridContentContainer>
                    </GridCardContainer>)
                }
                ))

            }
        </>)
    };

    render() {
        return (
            <MainContainer>
                {this.state.loading && <Loader loading={this.state.loading} />}
                <TitleTxt>Calendar</TitleTxt>
                <hr />
                {this.renderHeader()}
                {this.calenderRender()}
                {this.renderEventCard()}
                <CreateEventBox onClick={this.handleNavigate}>
                    <EventIcon />
                    <Typography>Create an Event</Typography>
                    <ArrowForwardIcon />
                </CreateEventBox>
            </MainContainer>
        );
    }
    // Customizable Area End
}

// Customizable Area Start

 const CustomCalender = styled(Calendar)({
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0px 0px 23px -7px rgba(0,0,0,0.75)",

    "&.react-calendar .rmdp-calendar": {
        width: "100%",
    },

    "&.react-calendar .rmdp-day-picker div": {
        width: "100%",
        cursor: "none ",
    },

    "&.react-calendar .rmdp-day-picker .rmdp-week .rmdp-day .sd": {
        margin: "0px 40px ",
        fontSize: " 14px ",
        color: "#0075a9",
        cursor:"pointer !important"
    },

    "&.react-calendar .rmdp-selected": {
        color: "#f0f0f0",
    },

    "&.react-calendar   .rmdp-day span": {
        borderRadius: "100%",
        bottom: "40px",
        fontSize: "14px",
        left: "-10px",
        right: "19px",
        top: "3px",
        width:"2rem",
        height:"2rem"
    },
    

    "&.react-calendar .rmdp-selected .sd": {
        color: "#f0f0f0",
    },
    "&.react-calendar .rmdp-header": {
        fontSize: "16px ",
        fontWeight: 600,
    },
    "&.react-calendar .rmdp-day.rmdp-selected span:not(.highlight)":{
        backgroundColor:"lightgrey" 

},

    "&.react-calendar .rmdp-week-day": {
        fontSize: "14px",
        color: "#a9aeb1",
    },

    "&.react-calendar .rmdp-arrow-container .rmdp-arrow": {
        height: "6px ",
        width: " 6px ",
        color: "#0075a9",
    },
    "&.react-calendar .rmdp-day.rmdp-today span":  {
        backgroundColor:"lightgrey"
     },

    "&.react-calendar .rmdp-day-picker .rmdp-week .rmdp-day": {
        cursor: "pointer",
        height:"41px"
    },
    "&.rmdp-header-values":{
        color: "#004587 !importantt",
        margin: "auto"
    },

    "&.event-calendar .rmdp-calendar": {
        width: "100%",
    },

    "&.event-calendar .rmdp-day-picker div": {
        width: "100%",
        cursor: "none ",
    },

    "&.event-calendar .rmdp-day-picker .rmdp-week .rmdp-day .sd": {
        margin: "0px 40px ",
        fontSize: " 14px ",
        color: "#0075a9",
        cursor:"pointer !important"
    },
    "&.event-calendar .rmdp-day.rmdp-today span":  {
       backgroundColor:"lightgrey"
    },
    "&.event-calendar   .rmdp-day span": {
        borderRadius: "100%",
        bottom: "40px",
        fontSize: "14px",
        left: "-10px",
        right: "19px",
        top: "3px",
        width:"2rem",
        height:"2rem"
    },

    "&.event-calendar .rmdp-selected": {
        color: "#f0f0f0",
    },
    "&.event-calendar .rmdp-day.rmdp-selected span:not(.highlight)":{
        backgroundColor:"lightblue" 

},
    "&.event-calendar .rmdp-selected .sd": {
        color: "#f0f0f0",
    },
    "&.event-calendar .rmdp-header": {
        fontSize: "16px ",
        fontWeight: 600,
    },

    "&.event-calendar .rmdp-week-day": {
        fontSize: "14px",
        color: "#a9aeb1",
    },

    "&.event-calendar .rmdp-arrow-container .rmdp-arrow": {
        height: "6px ",
        width: " 6px ",
        color: "#0075a9",
    },

    "&.event-calendar .rmdp-day-picker .rmdp-week .rmdp-day": {
        cursor: "pointer",
        height:"41px"
    }
});

const MainContainer = styled(Box)({
    padding: "10px 35px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    marginTop: "10px",
});

const TitleTxt = styled(Typography)({
    fontSize: "20px",
    fontWeight: "bold",
    margin: "5px 0",
    color: "#005487",
});

const HeadertxtContainer = styled(Typography)({
    width: "100% ",
    display: "flex",
    justifyContent: "space-between",
});

const Headertxt = styled(Typography)({
    width: "32%",
    textAlign: "center",
    color: "#135487",
    borderBottom: "2px solid #135487",
    padding: "15px 0",
    cursor: "pointer",
});

const CalendarBox = styled(Box)({
    margin: "40px 0px",
    width: "100%",
});



const GridCardContainer = styled(Grid)({
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "15px",
    borderRadius: "15px",
    overflow: "hidden",
    boxShadow: "0px 0px 3px 2px rgba(131, 131, 131, 0.23)",
    cursor: "pointer",
    height: "124px",
});

const GridCardImgContainer = styled(Grid)({
    position: "relative",
});

const CardImg = styled("img")({
    height: "100%",
    width: "100%",
    objectFit: "cover",
});

const GradientBox = styled(Box)({
    position: "absolute",
    top: "0px",
    height: "100%",
    width: " 100%",
    background:
        "linear-gradient(-90deg,#fff 0%,rgba(255, 255, 255, 0.337) 50%,rgba(255, 255, 255, 0) 100%)",
});

const GridContentContainer = styled(Grid)({
    backgroundColor: "white",
    justifyContent: "space-between",
    padding: "10px",
});

const GridDateContainer = styled(Grid)({
    backgroundColor: "white",
    width: "40%",
    margin: "10px 0px",
});

const DateTxt = styled(Typography)({
    fontSize: "12px",
    fontFamily: "Verdana",
    color: "#005487",
    fontWeight: "bold",
    lineHeight: "normal",
    textTransform: "capitalize",
});

const TimeTxt = styled(Typography)({
    marginBottom: "2%",
    float: "right",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#ffffff",
    backgroundColor: " #c2bc30",
    padding: "7px 4px 8px",
    borderRadius: "10px",
    textAlign: "center",
    textTransform: "capitalize",
});

const CreateEventBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: "#005487",
    marginTop: "80px",
    cursor: "pointer",
});

export const webStyle: Record<string, React.CSSProperties> = {
    gridEventName: {
        width: "50%"
    },

    eventName: {
        fontWeight: 600,
        fontSize: "24px",
        marginBottom: "-4px",
    },

    fullNameEvent: {
        color: "grey",
        fontSize: "18px"
    },

    avatarGroup: { 
        marginTop: "8px" ,
        marginLeft: "15px"
    },

    avtarImg: { 
        marginLeft: "-15px", 
        border: "1px solid white", 
        width: 30, 
        height: 30 
    },

    avtarImg2: { 
        marginLeft: "-15px", 
        border: "1px solid white", 
        backgroundColor: "#005487" 
    },

    imageUploads: {
        display: "flex",
        justifyContent: "space-between",
        color: "lightgrey"
    } as const,

    imageBtn: {
        borderBottom: "2px solid #01548a",
        borderRadius: "0px",
        cursor: "pointer",
        color: "#01548a",
        fontWeight: 500,

    } as const,

    bordernone: {
        border: 0,
        fontWeight: 500,
        color: "#a9aeb1"
    } as const,

};

// Customizable Area End
