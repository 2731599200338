// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const TimeIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M12 21.6C10.1013 21.6 8.24524 21.037 6.66652 19.9821C5.08781 18.9273 3.85736 17.428 3.13075 15.6738C2.40415 13.9196 2.21404 11.9894 2.58446 10.1272C2.95488 8.26494 3.86919 6.55439 5.21177 5.2118C6.55436 3.86922 8.26491 2.95491 10.1271 2.58449C11.9893 2.21407 13.9196 2.40418 15.6738 3.13079C17.4279 3.85739 18.9272 5.08784 19.9821 6.66655C21.037 8.24527 21.6 10.1013 21.6 12C21.6 14.5461 20.5886 16.9879 18.7882 18.7883C16.9879 20.5886 14.5461 21.6 12 21.6ZM12 4.00003C10.4177 4.00003 8.87103 4.46922 7.55544 5.34827C6.23984 6.22732 5.21446 7.47675 4.60896 8.93856C4.00346 10.4004 3.84503 12.0089 4.15372 13.5608C4.4624 15.1126 5.22432 16.5381 6.34314 17.6569C7.46196 18.7757 8.88743 19.5376 10.4393 19.8463C11.9911 20.155 13.5997 19.9966 15.0615 19.3911C16.5233 18.7856 17.7727 17.7602 18.6518 16.4446C19.5308 15.129 20 13.5823 20 12C20 9.8783 19.1571 7.84347 17.6569 6.34317C16.1566 4.84288 14.1217 4.00003 12 4.00003Z" fill="none" />
            <path d="M16 16.4C15.8017 16.3995 15.6107 16.3254 15.464 16.192L11.464 12.592C11.3811 12.5172 11.3148 12.4259 11.2694 12.3239C11.2239 12.222 11.2003 12.1116 11.2 12V8.00001C11.2 7.78784 11.2843 7.58436 11.4343 7.43433C11.5843 7.2843 11.7878 7.20001 12 7.20001C12.2122 7.20001 12.4157 7.2843 12.5657 7.43433C12.7157 7.58436 12.8 7.78784 12.8 8.00001V11.64L16.536 15C16.6583 15.1079 16.7448 15.2506 16.7837 15.4089C16.8227 15.5673 16.8123 15.7338 16.754 15.8861C16.6957 16.0384 16.5922 16.1692 16.4575 16.2611C16.3227 16.3529 16.1631 16.4014 16 16.4Z" fill="none" />
        </SvgIcon>
    );
}

export default TimeIcon;
// Customizable Area End