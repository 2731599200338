// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Grid,
  FormControl,
  TextField,
  Checkbox,
  FormHelperText,
  Modal,
} from "@material-ui/core";
import SignInNewController, { Props } from "./SignUpNewController.web";
import { SideImage, Logo, Mail, Lock, Eye, Hideeye } from "./assets";
import { styled } from "@material-ui/core/styles";
import TermsAndConditionsNew from "../../TermsAndConditions/src/TermsAndConditionsNew.web";
import "./SignUpNew.web.css";
// Customizable Area End

 export default class SignInNew extends SignInNewController {
      constructor(props: Props) {
         super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
  render() {
    const { errors } = this.state;

    return (
      <Box height="inherit" className="LoginMainBox">
        <Grid style={webStyle.mainContainerGrid} container>
          <LeftImage item xs={12} sm={5} className="SignupLeftSection" />
          <Grid item xs={12} sm={7} className="SignupRightSection">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Box style={webStyle.logoCard}>
                  <img
                    src={Logo}
                    alt="logo-img"
                    style={{ padding: 0, width: "200px", height: "200px" }}
                  />
                </Box>
              </Grid>

              <Grid item style={{ width: "50%", marginTop: "20px" }}>
                <Box>
                  <Typography
                    variant="h4"
                    style={{
                      fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                      marginTop: "30px",
                    }}
                  >
                    Signup
                  </Typography>
                </Box>
                <form
                  onSubmit={this.handleSubmit}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "30px",
                  }}
                >
                  <FormControl style={webStyle.InputField}>
                    <TextField
                      data-test-id="txtInputEmail"
                      variant="outlined"
                      size="small"
                      placeholder="Email ID"
                      name="email"
                      type="email"
                      style={{
                        fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                      }}
                      value={this.state.email}
                      onChange={this.handleChange}
                      error={errors.email !== ""}
                      helperText={errors.email}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ marginLeft: "-78px" }}
                          >
                            <img src={Mail} alt="mail-icon" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <FormControl style={webStyle.InputField}>
                    <TextField
                      data-test-id="txtInputPassword"
                      variant="outlined"
                      size="small"
                      id="passwword"
                      placeholder="Password"
                      name="password"
                      style={{
                        fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                      }}
                      type={this.state.showPassTwo ? "text" : "password"}
                      value={this.state.password}
                      onChange={this.handleChange}
                      error={errors.password !== ""}
                      helperText={errors.password}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ marginLeft: "-78px" }}
                          >
                            <img src={Lock} alt="lock-icon" />
                            {/* <img src={mail} style={{ width: 30, height: 30 }} /> */}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start">
                            <img
                              onClick={this.EyeChangeTwo}
                              style={{
                                cursor: "pointer",
                                position: "relative",
                                left: "65px",
                              }}
                              src={this.state.showPassTwo ? Eye : Hideeye}
                              alt="lock-icon"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>

                  <FormControl style={webStyle.InputField}>
                    <TextField
                      data-test-id="txtInputConfirmPassword"
                      variant="outlined"
                      size="small"
                      id="password_confirmation"
                      placeholder="Confirm Password"
                      type={this.state.showPassTwoConfirm ? "text" : "password"}
                      style={{
                        fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                      }}
                      name="password_confirmation"
                      value={this.state.password_confirmation}
                      onChange={this.handleChange}
                      error={errors.password_confirmation !== ""}
                      helperText={errors.password_confirmation}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ marginLeft: "-78px" }}
                          >
                            <img src={Lock} alt="lock-icon" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              onClick={this.EyeChangeTwoConfirm}
                              style={{
                                cursor: "pointer",
                                position: "relative",
                                left: "63px",
                              }}
                              src={
                                this.state.showPassTwoConfirm ? Eye : Hideeye
                              }
                              alt="lock-icon"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>

                  <FormControl style={webStyle.checkboxForm}>
                    <Checkbox
                      color="primary"
                      checked={this.state.agree}
                      onChange={this.setAgree}
                      name="agree"
                    />
                    <Typography variant="body2" style={{ display: "flex" }}>
                      {" "}
                      I accept the &nbsp;
                      <div
                        style={{
                          color: "#005487",
                          fontWeight: "bold",
                          marginLeft: "3px",
                          cursor: "pointer",
                          textDecoration: "none",
                          borderBottom: "2px solid",
                        }}
                        onClick={this.handleOpen}
                      >
                        Terms and Conditions.
                      </div>
                    </Typography>
                  </FormControl>
                  <div>
                    {this.state.agreeError && (
                      <FormHelperText
                        className="MuiFormHelperText-root"
                        style={{ marginTop: "-15px", marginLeft: "15px" }}
                      >
                        {this.state.agreeErrorMsg}
                      </FormHelperText>
                    )}
                  </div>
                  <Typography className="SignupInputError">
                    {this.state.FieldError}
                  </Typography>
                  <Box style={{ marginTop: "10px" }}>
                    <Button
                      data-test-id="btnSignUp"
                      variant="contained"
                      type="submit"
                      disabled={
                        this.state.errors.email.length > 0 ||
                        this.state.errors.password.length > 0 ||
                        this.state.errors.password_confirmation.length > 0
                      }
                      // fullWidth
                      // onClick={this.handleSubmit}
                      className="loginbtn"
                      style={{
                        paddingLeft: "42%",
                        paddingRight: "42%",
                        textTransform: "none",
                        backgroundColor: "#01548A",
                        color: "#fff",
                        fontWeight: "bold",
                        borderRadius: "6px",
                        marginTop: "5%",
                        marginBottom: "5%",
                        fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                        // backgroundImage:
                        //     "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
                      }}
                    >
                      Register
                    </Button>
                  </Box>
                </form>

                <Box
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    marginBottom: "50px",
                  }}
                >
                  <Typography variant="body2">
                    Already have an account? &nbsp;{" "}
                    <a
                      onClick={this.handleLogin}
                      style={{
                        fontWeight: "bold",
                        color: "#005487",
                        textDecoration: "none",
                        cursor: "pointer",
                        borderBottom: "2px solid",
                      }}
                    >
                      LogIn.
                    </a>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Modal
            open={this.state.modalVisible}
            onClose={this.handleClose}
            style={{ backgroundColor: "aliceblue" }}
          >
            <TermsAndConditionsNew
              navigation=""
              id=""
              handleAccept={this.handleAccept}
              setAgree={this.setAgree}
              agree={this.state.agree}
            />
          </Modal>
        </Grid>
      </Box>
    );
  }
    // Customizable Area End
}
// Customizable Area Start
const webStyle = {
  InputField: {
    marginTop: "10px",
    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
  },
  logoCard: {
    width: "200px",
    height: "200px",
    boxShadow: "-1px 3px 13px -1px rgba(0,0,0,0.75)",
    borderRadius: "50%",
    marginTop: "38px",
  },
  mainContainerGrid: {
    height: "inherit",
  },
  checkboxForm: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  } as const,
};

const LeftImage = styled(Grid)({
  backgroundImage: `url(${SideImage})`,
  backgroundSize: "100% 100%", // Set the background size
});
// Customizable Area End