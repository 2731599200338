// Customizable Area Start
import React from "react";
import { Select , MenuItem , Checkbox , ListItemText , Input , InputAdornment , Box } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomSelectDropdownController, { Props } from "./CustomSelectDropdownController.web";
import CheckboxUnCheckIcon from "./SVGIcons/CheckboxUnCheckIcon.web";
import CheckboxCheckIcon from "./SVGIcons/CheckboxCheckIcon.web";
import CheckboxUnCheckCircleIcon from "./SVGIcons/CheckboxUnCheckCircleIcon.web";
import CheckboxCheckCircleIcon from "./SVGIcons/CheckboxCheckCircleIcon.web";
import CustomButton from "./CustomButton.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
    createStyles({
        textField:{
            borderRadius: "12px",
            border: "solid 2px #f4f4f4",
            padding: "18px 0px",
        },
        textfieldInput:{
          fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
          fontWeight: "normal",
          paddingLeft: "5px",
        },
        errorBorder:{
          border: "1px solid red !important",
        },
        inputAdornment:{
          paddingLeft:"15px"
        },
        textFieldFont:{
            fontSize: "16px" 
        },
        userProfileSelectBootstrapInput: {
            borderRadius: "12px",
            border: "solid 2px #f4f4f4",
            backgroundColor: "#fff",
            padding: "12px 15px",
            fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
            fontSize: "16px",
            cursor:"none !important"
          },
          notSelectedMenuitem:{
            color:"#005487"
          },
          selectedMenuitem:{
            color:"#fff",
            backgroundColor:"#005487 !important"
          },
          declineOptionMenuItem:{
            color:"#005487",
            backgroundColor:"#fff !important",
            border:"1px solid #005487",
            borderRadius:"12px",
          },
          cursorPointer:{
            cursor:"pointer"
          }
    })

// Customizable Area End
  /*istanbul ignore next */
 export class CustomSelectDropdown extends CustomSelectDropdownController {
    // Customizable Area Start
     constructor(props: Props) {
         super(props);
     }
    // Customizable Area End

     render() {
        // Customizable Area Start
         const { classes , fieldName, fieldError , fieldValue , fieldId , fieldMenuItems, startAdornmentIcon , MenuProps , iconComponent } = this.props
    
      
         const applyClass :any = (item : any) => {
           if(item.value === fieldValue && fieldValue?.toLowerCase().includes("decline to answer")) return `${classes.declineOptionMenuItem}`;
           else if(item.value === fieldValue) return `${classes.selectedMenuitem}`;
           else return `${classes.notSelectedMenuitem}`
         
        }
        return (

            <Select
            id={fieldId}
            style={{width:"100%", color:fieldValue!=""?'black':'darkgrey'}}
            value={fieldValue}
            open={this.state.openMenu}
            name={fieldName}
            className={fieldError ?`${classes.userProfileSelectBootstrapInput} ${classes.errorBorder}` : `${classes.userProfileSelectBootstrapInput}`}
            onChange={this.props.handleChange}
            MenuProps={MenuProps}
            error={fieldError}
            renderValue={()=>fieldValue?fieldValue:this.props.placeHolderText}
            disableUnderline
            displayEmpty
            input={<Input id={`${fieldName}-my-select`} style={{color:"#808080"}} startAdornment={
              <InputAdornment position="start" style={{color:"#808080"}}>
                {startAdornmentIcon}
              </InputAdornment>
            } />}
            native={false}
            IconComponent={() => (
              <InputAdornment className={classes.cursorPointer} onClick={this.handleToggleMenu} position="start">
               {iconComponent}
              </InputAdornment>
            )}
          >
            {fieldMenuItems.map((item:any) => (
              <MenuItem className={applyClass(item)} key={`${item.value}`} value={item.value} onClick={this.handleToggleMenu}>
                <ListItemText primary={item.label}  />
                <Checkbox icon={item.value?.toLowerCase()?.includes("decline to answer") ? <CheckboxUnCheckCircleIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#005487"} strokeWidth="1.5" fill={"none"} /> :<CheckboxUnCheckIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#005487"} strokeWidth="1.5" fill={"none"} />}
                checkedIcon={item.value?.toLowerCase()?.includes("decline to answer")  ?<CheckboxCheckCircleIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#005487"} strokeWidth="1.5" fill={"none"} /> :<CheckboxCheckIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#fff"} strokeWidth="1.5" fill={"#005487"} />} checked={item.value === fieldValue} />
              </MenuItem>
            ))}
            
          </Select>
        );
        // Customizable Area End
    }

}


// Customizable Area Start
const webStyle = {
  DoneBtn: {
    background: "#01548a",
    color: "#fff",
    width: "140px",
    borderRadius:"0px",
    padding:"18px"
 },
}
export default withStyles(styles, { withTheme: true })(CustomSelectDropdown);
// Customizable Area End