export const edit = require("../assets/edit.png");
export const PostUser = require('../assets/user.png')
export const Camera = require('../assets/camera.png')
export const Chat = require('../assets/chat.png')
export const Like = require('../assets/like.png')
export const LikeFill = require('../assets/like_fill.png')
export const Link = require('../assets/link.png')
export const LinkFill = require('../assets/link_fill.png')
export const More = require('../assets/more.svg')
export const profilePick=require("../assets/profilePick.svg")
export const Notification = require('../assets/notification.png')
export const Send = require('../assets/send.png')
export const SendFill = require('../assets/send_fill.png')
export const User = require('../assets/user.png')
export const Home = require('../assets/home.png')
export const CommentFill = require('../assets/comment_fill.png')
export const eagel = require('../assets/eagel.png')
export const youtubeIcon = require('../assets/Youtube.png')
export const vimeoIcon = require('../assets/Vimeo.png')
export const blueSend = require("../assets/send.svg")
export const blueLink = require("../assets/Link.svg")
export const eventvainer = require('../assets/event_vainer.jpg')
export const iconscalender = require('../assets/icons_calender.png')
export const iconsrightarrow = require('../assets/icons-right-arrow.png')
export const iconsleftarrow = require('../assets/icons-left-arrow.png')
export const iconsstar = require('../assets/icons-star.png')
export const user1 = require('../assets/user1.jpg')
export const user2 = require('../assets/user2.jpg')
export const colorstar = require('../assets/icons_colorstar.png')
export const Vectorsvg = require('../assets/Vector.svg')
export const calendar = require('../assets/calendar.svg')
export const editcopy = require('../assets/edit copy.svg')
export const editicon = require('../assets/edit.svg')
export const editimg = require('../assets/editimg.svg')
export const editwhite = require('../assets/edit_white.svg')
export const Linkicon = require('../assets/LinkIcon.svg')
export const Time = require('../assets/Time.svg')
export const star = require('../assets/star.svg')
export const star2 = require('../assets/star2.svg')
export const send = require('../assets/send-2.svg')
export const send2 = require('../assets/send-2 copy.svg')
export const more = require('../assets/more.svg')
export const link = require('../assets/Link.svg')
export const blankProfile =require('../assets/blankProfile.svg')
export const LocationSVG = require('../assets/LocationSVG.svg')
export const fillLike = require("../assets/fillLike.svg")
export const AddIcon = require("../assets/add.svg");
export const blueLink1 = require("../assets/blueLink.svg");
export const whiteLink = require("../assets/whiteLink.svg");
export const SmallCamera = require("../assets/smallCamera.svg");
export const DarkSend = require("../assets/Darksend.svg");
export const blueLike = require("../assets/blueLike.svg");
export const simpleLike = require("../assets/like.svg");
export const smallFillLike = require("../assets/smallFillLike.svg");
export const chat1 =require("../assets/chat.svg");
export const DisLike= require("../assets/dislike.svg");
export const Save= require("../assets/save-blue.svg");
export const Report=require("../assets/close-square-blue.svg");
export const NoProfileIcon= require("../assets/NoProfileIcon.svg")
export const DescriptionIcon = require("../assets/Description.svg")
export const Email=require("../assets/Email.svg")
export const PhoneIcon = require ("../assets/Phone.svg")
export const WebsiteIcon = require("../assets/Website.svg")
export const UserName =require("../assets/UserName.svg")
export const CloudIcon =require("../assets/folder-cloud.svg");
export const CloseIcon =require("../assets/close-square.svg");