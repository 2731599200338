// Customizable Area Start
import React from "react";
import { Grid , Avatar , Typography , Box, FormControl, InputAdornment, TextField } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import VideoTypePostStepTwoController, { Props , VIDEO_TYPES } from "./VideoTypePostStepTwoController.web";
import {youtubeIcon, vimeoIcon} from "./assets"
import "./VideoTypePost.web.css";
import EmbedIcon from "./SVGIcons/EmbedIcon.web";
import LinkOpenIcon from "./SVGIcons/LinkOpenIcon.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
// Customizable Area End

// Customizable Area Start
const Poststyles = (theme: Theme) =>
    createStyles({
        title: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            display: 'none',
        },
        menuIcon:{
            width: "24px",
            height: "24px",
            objectFit: "contain",
        },
        inputAdornment:{
            padding:"0px 8px"
        }
    })
// Customizable Area End


export class VideoTypePostStepTwo extends VideoTypePostStepTwoController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { classes , open , videoEmbedType} = this.props
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={"VideoPostModal VideoStepTwoModal"}
                open={open}
                onClose={this.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={open}>
                    <Box className="VideoStepTwoModalBox">
                        <Grid container className={`VideoStepTwoModalMainGrid`}>
                            <Grid className={`VideoStepTwoModalMainGridFirstItem`} item xs={12}>
                                    <Grid container direction="row">
                                        <Grid xs={4} sm={2} md={1} item>
                                            <Avatar
                                                src={videoEmbedType===VIDEO_TYPES.Vimeo ? vimeoIcon : youtubeIcon}
                                                variant="square"
                                                className={classes.menuIcon}
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={10} md={11}>
                                            <Typography
                                            className={`Heading4 ModalHeading`}
                                            component="span"
                                            >
                                            {videoEmbedType===VIDEO_TYPES.Vimeo ? "Vimeo Embed" : "Youtube Embed"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                            </Grid>
                            <Grid className={`VideoStepTwoModalMainGridSecondItem`} item xs={12}>
                                    <Grid container direction="row">
                                    <Grid item xs={12}>
                                            <Typography
                                            className={`Heading6 EmbedCode BoldFont`}
                                            component="span"
                                            >
                                            Embed Code
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box mt="5px" pl={"2px"} width="100%">
                                            <FormControl className={`VideoEmbedFormControl`}>
                                                <CustomTextField
                                                fieldId="url"
                                                placeHolderText="Add or Paste embed code"
                                                fieldName="urlLink"
                                                fieldType="link"
                                                fieldValue={this.props.videoEmbededUrl}
                                                handleChange={this.props.handleOnchange}
                                                startAdornmentIcon={<EmbedIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#005487"} strokeWidth="0.055" fill={"#005487"} />}
                                                fieldError={this.props.videoError}
                                                helperText={this.props.videoErrorMessage}
                                                />
                                            </FormControl>
                                        </Box>
                                        <Box mt="30px">

                                            <Typography component={"span"} className="VideoEmbedInfoText">
                                            Add or paste embed code to the content you want to display on your site
                                            </Typography>
                                        </Box>
                                    </Grid>
                            </Grid>
                            <Grid className={`VideoStepTwoModalMainGridThirdItem`} item xs={12}>
                                <Box mt={"16px"} width="100%" display="flex" alignItems="center" >
                                    <Typography  className="Heading5 EmbedCode" style={{textDecoration:"underline",cursor:"pointer"}as const} component="span" onClick={this.props.openEmbedded}>
                                 
                                        Learn more about embeds
                                 
                                        <LinkOpenIcon style={{marginLeft:"5px",marginBottom:"-3px"}} width="20" height="20" viewBox="0 0 20 20" stroke={"#005487"} strokeWidth="1.5" fill='none' />
                                    </Typography>
                                 
                                </Box>
                            </Grid>
                            <Grid className={`VideoStepTwoModalMainGridThirdItem`} item xs={12}>
                            <CustomButton btnText="Embed" buttonId="user-profile-done-btn" disabled={this.props.disabledbtn} btnStyle={webStyle.DoneBtn} type={"submit"} handleButtonClick={this.props.handleSubmit}  />
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal >
        )
    }
    // Customizable Area End
}

// Customizable Area Start

const webStyle = {
    DoneBtn: {
      background: "#01548a",
      color: "#fff",
      width: "100%",
      marginTop:"10rem",
      borderRadius:"0px",
      padding:"18px",
      fontWeight: "bold"
   },
  }

export default withStyles(Poststyles, { withTheme: true })(VideoTypePostStepTwo);
// Customizable Area End