import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import {
  getStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  name: string;
  nameError: boolean;
  nameErrorMsg: string;
  address: string;
  addressError: boolean;
  addressErrorMsg: string;
  websiteLink: string;
  websiteLinkError: boolean;
  websiteLinkErrorMsg: string;
  email: string;
  emailError: boolean;
  emailErrorMsg: string;
  phoneNumber: string;
  phoneNumberError: boolean;
  phoneNumberErrorMsg: string;
  orgId: any;
  imagePass: any;
  imageError: any;
  imageErrorMsg: any;
  profileImage: any | ArrayBuffer | null | unknown;
  profileImageSrc: string;
  isProfilePicUploaded: boolean;
  profilePicErrorMsg: string;
  isProfileInfoUpdated: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}


  /*istanbul ignore next */
export default class CreateCommunityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  apiUpdateUserOrgnazization: string = "";
  apiGetUserOrganizationDetails: string = "";
  apiCreateOrganizationInfo: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
      // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      
    
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      name: "",
      loading: false,

      nameErrorMsg: "",
      address: "",
      nameError: false,
      addressError: false,

      websiteLink: "",
      addressErrorMsg: "",
      websiteLinkError: false,

      email: "",
      websiteLinkErrorMsg: "",

      emailErrorMsg: "",
      emailError: false,
      phoneNumber: "",

      phoneNumberErrorMsg: "",
      phoneNumberError: false,
      orgId: null,
      imageError: "",
      imagePass: "",
      imageErrorMsg: "",

      profileImageSrc: "",
      profileImage: "",

      profilePicErrorMsg: "",
      isProfilePicUploaded: false,
      isProfileInfoUpdated: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    if (localStorage.getItem("communityData") != "") {
      let parsedData = localStorage.getItem("communityData");
      if (parsedData != null) {
        this.setState({
          name: JSON.parse(parsedData)?.attributes?.name,
          websiteLink: JSON.parse(parsedData)?.attributes?.website,
          email: JSON.parse(parsedData)?.attributes?.email,
          address: JSON.parse(parsedData)?.attributes?.description,
          phoneNumber: JSON.parse(parsedData)?.attributes?.phone_number,
          profileImageSrc: JSON.parse(parsedData)?.attributes?.profile_pic,
        });
      }
    }

    this.handleRemovePreviousUserData();

    await this.handleGetUserOrgData();
  }

  handleRemovePreviousUserData = () => {
    removeStorageData("LoggedInUserData");
  };

  showLoaderForApi = () => {
    if (document.body) {
      document.body.style.overflow = "hidden";
    }
  };
  uploadProfileImage = (e: any) => {
    const file = e.target.files[0];
    if (file.type?.includes("image")) {
      this.setState({
        isProfilePicUploaded: true,
        profileImage: e.target.files[0],
        profilePicErrorMsg: "",
        isProfileInfoUpdated: false,
      });
      this.getFileSrc(e.target.files[0]);
    } else {
      toast.error("Please upload a valid image type file");
    }
  };

  getFileSrc = (file: any) => {
    if (typeof file === "string") {
      this.setState({ profileImageSrc: file });
    } else {
      this.setState({ profileImageSrc: window.URL.createObjectURL(file) });
    }
  };

  hideLoaderForApi = () => {
    if (document.body) {
      document.body.style.overflow = "auto";
    }
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "name") {
      this.setState({
        nameErrorMsg: "",
        nameError: false,
      });
    }

    if (name === "address") {
      this.setState({
        addressErrorMsg: "",
        addressError: false,
      });
    }
    if (name === "email") {
      this.validateEmail(value);
    }
    if (name === "websiteLink") {
      this.validateUrlPost(value);
    }
    if (name === "phoneNumber") {
      this.validatePhoneNumber(value);
    }
    this.setState((prev) => {
      return {
        ...prev,

        [name]: value,
      };
    });
  };

  validateUrlPost = (value: string) => {
    const urlRegex = /^(https?:\/\/(www\.)?|[wW]{3}\.)[a-zA-Z0-9]+(\.[a-zA-Z]{2,})+(\/[^\s]*)?$/;

    const isValidUrl = urlRegex.test(value);

    if (!isValidUrl) {
      this.setState({
        websiteLinkErrorMsg: "Please enter a valid website link.",
        websiteLinkError: true,
      });
    } else {
      this.setState({
        websiteLinkErrorMsg: "",
        websiteLinkError: false,
      });
    }
  };

  validateEmail = (value: string) => {
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    const isValidUrl = validEmailRegex.test(value);

    if (!isValidUrl) {
      this.setState({
        emailErrorMsg: "Please enter a valid email.",
        emailError: true,
      });
    } else {
      this.setState({
        emailErrorMsg: "",
        emailError: false,
      });
    }
  };

  validatePhoneNumber = (value: string) => {
    const phoneRegex = /^91\d{10}$/;

    const isValidNumber = phoneRegex.test(value);

    if (!isValidNumber) {
      this.setState({
        phoneNumberErrorMsg:
          "Please enter a valid contact number of 10 digits starting with 91.",
        phoneNumberError: true,
      });
    } else {
      this.setState({
        phoneNumberErrorMsg: "",
        phoneNumberError: false,
      });
    }
  };

  handleValidateForm = () => {
    let isValid = true;

    //check for valid form details
    if (!this.state.name) {
      this.setState({
        nameErrorMsg: "Please enter name.",
        nameError: true,
      });

      isValid = false;
    }
    if (!this.state.profileImage) {
      this.setState({
        profilePicErrorMsg: "Upload Image",
        isProfileInfoUpdated: true,
      });
    }
    if (!this.state.address) {
      this.setState({
        addressErrorMsg: "Please enter description.",
        addressError: true,
      });

      isValid = false;
    }

    if (!this.state.phoneNumber) {
      this.setState({
        phoneNumberErrorMsg: "Please enter contact number.",
        phoneNumberError: true,
      });

      isValid = false;
    }
    if (!this.state.websiteLink) {
      this.setState({
        websiteLinkErrorMsg: "Please enter your website link.",
        websiteLinkError: true,
      });

      isValid = false;
    }
    if (!this.state.email) {
      this.setState({
        emailErrorMsg: "Please enter email.",
        emailError: true,
      });

      isValid = false;
    }

    return isValid;
  };

  handleSubmitForm = async (e: any) => {
    e.preventDefault();
    const isValidForm = this.handleValidateForm();
    const {
      addressError,
      websiteLinkError,
      nameError,
      phoneNumberError,
      emailError,
    } = this.state;

    if (
      isValidForm &&
      !(
        addressError ||
        websiteLinkError ||
        nameError ||
        emailError ||
        phoneNumberError
      )
    ) {
      this.handleAddUpdateOrgDeta();
    }
  };

  handleAddUpdateOrgDeta = async () => {
    let signupToken = await getStorageData("SignupSuccessToken");
    let loginToken = await getStorageData("LoginSuccessToken");
    const header = {
      token: signupToken || loginToken,
    };
    const {
      name,
      address,
      email,
      websiteLink,
      phoneNumber,
      profileImage,
    } = this.state;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("profile_pic", profileImage);
    formData.append("email", email);
    formData.append("website", websiteLink);
    formData.append("phone_number", phoneNumber);
    formData.append("description", address);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreateOrganizationInfo = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCommunity
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSetUserOrgDetails = (responseJson: any) => {
    console.log(responseJson, "ResponseJ Son");
  };

  handleImageChange = (e: any) => {
    this.setState({
      imagePass: e.target?.files[0],
      imageError: false,
      imageErrorMsg: "",
    });
  };

  handleAddUpdateOrgDetails = (responseJson: any) => {
    if (!(responseJson?.errors || responseJson?.error)) {
      toast.success("Your information has been updated successfully.");

      this.setState({ loading: false }, () => {
        this.hideLoaderForApi();
      });

      this.props.navigation.navigate("LandingScreen");
    } else {
      if (responseJson?.errors?.length) {
        for (let key in responseJson.errors[0]) {
          toast.error(responseJson.errors[0]?.[key]);
        }
      } else if (responseJson?.error?.length) {
        for (let key in responseJson.error[0]) {
          toast.error(responseJson.error[0]?.[key]);
        }
      } else {
        toast.error("Someting went wrong,information could not be updated.");
      }
      this.setState({ loading: false }, () => {
        this.hideLoaderForApi();
      });
    }
  };

  handleGetUserOrgData = async () => {
    let signupToken = await getStorageData("SignupSuccessToken");

    let loginToken = await getStorageData("LoginSuccessToken");

    this.setState({ loading: true }, () => {
      this.showLoaderForApi();
    });
    const header = {
      token: signupToken || loginToken,

      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUserOrganizationDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      "get_community"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

   

      // Customizable Area Start
      switch (apiRequestCallId) {
        case this.apiGetUserOrganizationDetails:
          {
            this.handleSetUserOrgDetails(responseJson);
          }
          break;

        case this.apiCreateOrganizationInfo:
          {
            this.handleAddUpdateOrgDetails(responseJson);
          }

          break;

        default:
          return;
      }

      // Customizable Area End
    }

    // Customizable Area End

  }
  // Customizable Area End
}

