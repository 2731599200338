import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes: any;
    userFullName:string;
    profileImageSrc:any;
    finalPost:boolean;
    postText:string;
    uploadedImage:any | ArrayBuffer | null | unknown;
    handleClose:any;
    handleImageOpen:any;
    handleOpenVideoModalOne:any;
    handleLinkOpen:any;
    handleSubmit:any;
    isLoading?: boolean;
    editedImage?:boolean;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    postType: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
export default class CreatePostFinalModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiCreatePostCallId: any
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            postType: "",
        }

    }

    handleClose = () => {
        this.props.handleClose()
    };

    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        console.log("apiRequestCallId", apiRequestCallId)

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );


        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.apiCreatePostCallId) {
                if (!responseJson.errors) {
                    console.log("myresponse.........", responseJson)
                } else {
                    console.log(responseJson.errors)
                    this.parseApiErrorResponse(responseJson);
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }


        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const otpAuthTkn = message.getData(
                getName(MessageEnum.AuthTokenDataMessage)
            );
            if (otpAuthTkn && otpAuthTkn.length > 0) {

                runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
            }
        }
        // Customizable Area End
    }

}
// Customizable Area End