// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const EditIcon: any = (props: any) => {
   
    return (
        <SvgIcon {...props}>
            <path strokeWidth={props.strokeWidth} fill="none" d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" strokeLinecap="round" strokeLinejoin="round" />
            <path strokeWidth={props.strokeWidth} fill="none" d="M16.0404 3.02123L8.16043 10.9012C7.86043 11.2012 7.56043 11.7912 7.50043 12.2212L7.07043 15.2312C6.91043 16.3212 7.68043 17.0812 8.77043 16.9312L11.7804 16.5012C12.2004 16.4412 12.7904 16.1412 13.1004 15.8412L20.9804 7.96123C22.3404 6.60123 22.9804 5.02123 20.9804 3.02123C18.9804 1.02123 17.4004 1.66123 16.0404 3.02123Z" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path strokeWidth={props.strokeWidth} fill="none" d="M14.9102 4.14874C15.5802 6.53874 17.4502 8.40874 19.8502 9.08874" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
}

export default EditIcon;
// Customizable Area End