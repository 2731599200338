import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes: any;
    userFullName:string;
    profileImageSrc:any;
    openModal:boolean;
    createPostMainModal:any;
    createPost: string;
    createPostError:boolean;
    createPostErrorMsg:string;
    handleClose:any;
    handleChange:any;
    handleImageOpen:any;
    handleOpenVideoModalOne:any;
    handleLinkOpen:any;
    handleSubmit:any;
    eventModal:any;
    isLoading: boolean;
    handleImageSubmit:any;
    handleImageOnlyOpen:any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    communityFlag:any;
    communityData:any;
    userData:any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
/*istanbul ignore next */
export default class CreatePostMainModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiCreatePostCallId: any
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            communityData:{},
            communityFlag:"false",
            userData:{},
       }

    }

    async componentDidMount(){
        this.setState({communityFlag:localStorage.getItem("community")})
        if( localStorage.getItem("communityData")!="")
        {
         let parsedData=localStorage.getItem("communityData")
         if(parsedData!=null)
         {
           this.setState({communityData:JSON.parse(parsedData)})
         }
        
        }
        if( localStorage.getItem("profileData")!="")
        {
         let parsedData=localStorage.getItem("profileData")
         if(parsedData!=null)
         {
           this.setState({userData:JSON.parse(parsedData)})
         }
        
        }
    }

    handleModalClose = () => {
        this.props.handleClose()
    }

    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );


        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.apiCreatePostCallId) {
                if (!responseJson.errors) {
                    console.log("apiCreatePostCallId")
                } else {
                    console.log("error")
                }
            }
        }


        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const otpAuthTkn = message.getData(
                getName(MessageEnum.AuthTokenDataMessage)
            );
            if (otpAuthTkn && otpAuthTkn.length > 0) {

                runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
            }
        }
        // Customizable Area End
    }

    resetPost() {
        this.setState((prev) => {
          return {
            ...prev,
            isLoading: false,
            createPost: "",
          };
        });
        
      }

}

// Customizable Area End