// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Grid,
  FormControl,
  TextField,
  Link,
  InputLabel,
} from "@material-ui/core";
import ForgotPasswordPhoneVerificationController, {
  Props
} from "./ForgotPasswordPhoneVerificationController.web";
import { SideImage, Logo, Mail } from "./assets";

import PhoneIcon from "@material-ui/icons/Phone";
export const configJSON = require("./config");

// Customizable Area End

// Customizable Area Start
/*istanbul ignore next */
const webStyling = {
  logoCard: {
    width: "200px",
    marginTop: "38px",
    height: "200px",

    boxShadow: "-1px 3px 13px -1px rgba(0,0,0,0.75)",
    borderRadius: "50%",
  },
  PhoneDesc: {
    marginTop: "30px",
    color: "#989898",
  },
  Phone: {
    color: "#01548A",
    fontWeight: "bold",
    fontSize: "25px",
  } as const,

  otppin: {
    color: "grey",
    fontSize: 14,
    width: "35px",
    height: "35px",
    margin: "5px 5px",
    borderRadius: "5px",
    border: "1px solid #b3b3b3",
    textAlign: "center",
    padding: "5px 0px 5px 0px",
    justifyContent: "center",
    backgroundColor: "#fafafa",
  } as const,
  InputField: {
    marginTop: "10px",
  },

  LoginBtn: {
    width: "100%",
    textTransform: "none",
    backgroundColor: "#01548A",
    fontWeight: "bold",
    color: "#fff",

    borderRadius: "6px",
    marginTop: "5%",
    marginBottom: "5%",
  } as const,
  mainGridContainer: {
    height: "inherit",
  },

  fieldError: {
    textAlign: "start",
    fontSize: "16px",
    color: "red",
  } as const,
};

// Customizable Area End
  /*istanbul ignore next */
export default class ForgotPasswordPhoneVerification extends ForgotPasswordPhoneVerificationController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  ConditionalRender = () => {
    if (!this.state.email_verify) {
      return (
        <Box>
          <Box style={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              style={{
                color: "#01548A",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Verification Code
            </Typography>
            <Typography variant="body2" style={webStyling.PhoneDesc}>
              OTP has been send to your Phone Number
              <br />
              Please Verify
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <form
              onSubmit={this.verifyOtp}
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                  gap: "20px",
                }}
              >
                {this.state.otp.map((value: any, index: any) => (
                  <input
                    id="myInput"
                    data-test-id="txtOTPpin"
                    name={index == 0 ? "otp_code" : `otp${index + 1}`}
                    key={index}
                    onKeyDown={(e) => this.handleKeydown(e, index)}
                    type="number"
                    autoFocus={index === 0}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 1);
                    }}
                    onChange={(e) => this.handleOTPChange(e, index)}
                    value={value}
                    maxLength={1}
                    pattern="\d*"
                    inputMode="numeric"
                    style={{
                      color: "grey",
                      fontSize: 14,
                      width: "35px",
                      height: "35px",
                      margin: "5px 5px",
                      borderRadius: "5px",
                      border: "1px solid #b3b3b3",
                      textAlign: "center",
                      justifyContent: "center",
                      padding: "5px 0px 5px 0px",
                      backgroundColor: "#fafafa",
                    }}
                  />
                ))}
              </Box>

              {this.state.otpError !== "" && (
                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  {this.state.otpError}
                </p>
              )}
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  color: "red",
                }}
              >
                {this.state.FieldError}
              </Typography>
              <Box
                style={{
                  textAlign: "center",
                }}
              >
                {this.state.isDisabled ? (
                  <Typography
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                    variant="body2"
                  >
                    00:{this.state.otpTimer}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Didn’t receive code? &nbsp;
                    <span
                      style={{
                        fontWeight: "bold",

                        color: "#01548a",
                        cursor: "pointer",
                        marginTop: "30px",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Resend.
                    </span>
                  </Typography>
                )}
              </Box>

              <Box style={{ marginTop: "40px", textAlign: "center" }}>
                <Button
                  data-test-id="btnVerifyOTP"
                  type="submit"
                  variant="contained"
                  style={{
                    width: "100%",
                    textTransform: "none",
                    backgroundColor: "#01548A",
                    color: "#fff",
                    fontWeight: "bold",
                    borderRadius: "6px",
                    marginBottom: "5%",
                    marginTop: "5%",
                  }}
                >
                  Verify
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box>
          <Box style={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              style={{
                color: "#01548A",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Forgot Password
            </Typography>
            <Typography variant="body2" style={webStyling.PhoneDesc}>
              Enter your Email-id to Reset password
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <form
              onSubmit={this.verifyOtp}
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                  gap: "20px",
                }}
              >
                <FormControl style={webStyle.InputField}>
                  <TextField
                    data-test-id="txtInputEmail"
                    variant="outlined"
                    size="small"
                    placeholder="Email id"
                    name="email"
                    type="text"
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                    error={this.state.emailError !== ""}
                    helperText={this.state.emailError}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ marginLeft: "-78px" }}
                        >
                          <img src={Mail} alt="mail-icon" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  color: "red",
                }}
              >
                {this.state.FieldError}
              </Typography>
              <Typography
                style={{
                  textAlign: "right",
                  fontSize: "16px",
                  color: "#01548A",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={this.handleChangeGoToPhone}
              >
                Reset Password using Phone?
              </Typography>
              <Box style={{ marginTop: "40px", textAlign: "center" }}>
                <Button
                  data-test-id="btnVerifyOTP"
                  type="submit"
                  variant="contained"
                  style={{
                    width: "100%",
                    textTransform: "none",
                    backgroundColor: "#01548A",
                    color: "#fff",
                    fontWeight: "bold",
                    borderRadius: "6px",
                    marginBottom: "5%",
                    marginTop: "5%",
                  }}
                  onClick={this.handleEmailSubmit}
                >
                  Submit
                </Button>
                <Box
                  style={{
                    textAlign: "center",
                    marginBottom: "50px",
                    fontSize: 14,
                  }}
                >
                  <InputLabel
                    style={{
                      color: "rgb(124 123 128)",
                      fontFamily: "sans-serif",
                      fontSize: 14,
                    }}
                  >
                    Go back to
                    <Link
                      onClick={this.handleLogin}
                      style={{
                        color: "#01548A",
                        fontWeight: 600,
                        marginLeft: 10,
                        alignContent: "end",
                        fontSize: 14,
                      }}
                    >
                      Login?
                    </Link>
                  </InputLabel>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      );
    }
  };

  render() {
    return (
      <Box height={"inherit"} id="Main" className="PhoneVerifyMainBox">
        <Grid style={webStyling.mainGridContainer} container>
          <Grid item xs={12} sm={5}>
            <img
              src={SideImage}
              alt="side-img-signUp"
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>

          <Grid item xs={12} sm={7} style={{ backgroundColor: "#fff" }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Box style={webStyling.logoCard}>
                  <img
                    src={Logo}
                    alt="logo-img"
                    style={{ padding: 0, width: "200px", height: "200px" }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Grid item style={{ marginTop: "20px" }}>
                  {this.state.show === false ? (
                    <Box>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="h4"
                          style={{
                            color: "#01548A",
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        >
                          Phone Verification
                        </Typography>
                        <Typography
                          variant="body2"
                          style={webStyling.PhoneDesc}
                        >
                          Enter Your Phone Number to receive a <br /> One Time
                          Password
                        </Typography>
                      </Box>
                      <Box style={{ marginTop: "10px" }}>
                        <form
                          onSubmit={this.handleSubmit}
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                          }}
                        >
                          <FormControl style={webStyling.InputField}>
                            <TextField
                              id="PhoneNumber"
                              data-test-id="txtname"
                              variant="outlined"
                              size="small"
                              placeholder="Phone Number"
                              onChange={(e) => this.handleChange(e)}
                              type="number"
                              value={this.state.full_phone_number}
                              name="full_phone_number"
                              error={this.state.errorMsg !== ""}
                              helperText={this.state.errorMsg}
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ marginLeft: "-78px" }}
                                  >
                                    <PhoneIcon
                                      style={{ width: 20, height: 20 }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>

                          <Typography
                            style={{
                              textAlign: "center",
                              fontSize: "16px",
                              color: "red",
                            }}
                          >
                            {this.state.FieldError}
                          </Typography>
                          <Typography
                            style={{
                              textAlign: "right",
                              fontSize: "16px",
                              color: "#01548A",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={this.handleChangeGoToEmail}
                          >
                            Reset Password using Email?
                          </Typography>

                          <Box
                            style={{ marginTop: "40px", textAlign: "center" }}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              style={{
                                width: "100%",
                                textTransform: "none",
                                backgroundColor: "#01548A",
                                color: "#fff",
                                fontWeight: "bold",
                                borderRadius: "6px",
                                marginBottom: "5%",
                                marginTop: "5%",
                              }}
                              data-test-id="btnSignUp"
                            >
                              Send OTP
                            </Button>
                          </Box>
                        </form>
                      </Box>
                    </Box>
                  ) : (
                    <this.ConditionalRender />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
  // Customizable Area End


// Customizable Area Start

const webStyle = {
  logoCard: {
    width: "200px",
    height: "200px",
    borderRadius: "50%",
    boxShadow: "-1px 3px 13px -1px rgba(0,0,0,0.75)",
  },
  mainContainerGrid: {
    height: "inherit",
  },
  InputField: {
    marginTop: "10px",
  },
  Login: {
    color: "#01548A",
    fontWeight: "bold",
    fontSize: "25px",
  } as const,

  LoginBtn: {
    paddingLeft: "42%",
    paddingRight: "42%",
    textTransform: "none",
    backgroundColor: "#01548A",
    color: "#fff",
    fontWeight: "bold",
    borderRadius: "10px",
    marginBottom: "5%",
    marginTop: "5%",
  } as const,
  LoginSideImg: {
    height: "100%",
    width: "100%",
  },
};
// Customizable Area End
