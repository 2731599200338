// Customizable Area Start
import React from "react";
import SinglePostController from "./SinglePostController.web";
import NewPostBlock from "../../postcreation/src/NewPostBlock.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class SinglePost extends SinglePostController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const item = this.state.postData;
    const src = this.handleGetPostItemSrc(item);
    const profile = this.handleGetPostItemUserProfilePic(item);
    return (

      this.props.communityFlag?

      <>
      {this.state.loading && <Loader loading={this.state.loading} />}
      {this.props.Dataa.attributes && (
        <NewPostBlock
          key={this.props.Dataa.id}
          flag={this.props.communityFlag}
          communityFollow={this.props.communityFollow}
          data={this.props.Dataa}
          communityData={this.props.communityData}
          navigation={this.props.navigation}
          videoTitle={this.props.Dataa.attributes.video_title}
          getPostList={this.getPostList}
          postCards={{}}
          mediaPost={""}
          postComment={{}}
          id={this.props.Dataa.id}
          profile={this.props.Dataa.attributes.community_profile}
          fullName={
            this.props.Dataa.attributes.full_name || this.props.Dataa.attributes.community_name
          }
          createdAt={this.props.Dataa.attributes.created_at}
          description={this.props.Dataa.attributes.description}
          url={this.props.Dataa.attributes.url}
          src={src}
          itemType={this.props.Dataa.type}
          eventDescription={this.props.Dataa.attributes.description}
          eventName={this.props.Dataa.attributes.event_name}
          startTime={this.props.Dataa.attributes.start_time}
          startDate={this.props.Dataa.attributes.start_date}
          endTime={this.props.Dataa.attributes.end_time}
          liked={this.props.Dataa.like}
          eventType={this.props.Dataa.attributes.event_type}
          PostId={this.props.Dataa.attributes.id}
          postType={this.props.Dataa.attributes.post_type || this.props.Dataa.type}
          postCommented={this.props.Dataa.commentOpen}
          postlikecount={this.props.Dataa.attributes.post_like_count}
          messComment={{}}
          editPost={()=>{}}
          followUpdatedList={this.getPostList}
          followUpdatedCommunityTab={this.props.followUpdatedCommunityTab}
          deletePost={this.getPostList}
        />
      )}


    </>:
      <>
        {this.state.loading && <Loader loading={this.state.loading} />}
        {item.attributes && (
          <NewPostBlock
            key={item.id}
            data={item}
            navigation={this.props.navigation}
            videoTitle={item.attributes.video_title}
            getPostList={this.getPostList}
            postCards={{}}
            mediaPost={""}
            postComment={{}}
            id={item.id}
            profile={profile}
            fullName={
              item.attributes.full_name || item.attributes.community_name
            }
            createdAt={item.attributes.created_at}
            description={item.attributes.description}
            url={item.attributes.url}
            src={src}
            itemType={item.type}
            eventDescription={item.attributes.description}
            eventName={item.attributes.event_name}
            startTime={item.attributes.start_time}
            startDate={item.attributes.start_date}
            endTime={item.attributes.end_time}
            liked={item.like}
            eventType={item.attributes.event_type}
            PostId={item.attributes.id}
            postType={item.attributes.post_type || item.type}
            postCommented={item.commentOpen}
            postlikecount={item.attributes.post_like_count}
            messComment={{}}
            editPost={()=>{}}
            followUpdatedCommunityTab={this.props.followUpdatedCommunityTab}
            followUpdatedList={this.getPostList}
            deletePost={this.getPostList}
          />
        )}


      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
