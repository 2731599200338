import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    uploadedImage?:any;
    id?: string;
    classes: any;
    openModal:boolean;
    handleClose:any;
    handleFinalPostOpen:any;
    editedImage:boolean;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    imageLoad: string;
    uploadImage: any | ArrayBuffer | null | unknown;
    uploadImageError: boolean;
    uploadImageErrorMsg: string;
    drop: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
export default class CreatePostImageModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiCreatePostCallId: any
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            imageLoad: "upload",
            uploadImage: "",
            uploadImageError:false,
            uploadImageErrorMsg:"",
            drop: "Or drag a file here",
        }
    }
async componentDidMount() {
    console.log("Uploadded -1212",this.props.uploadedImage)
    if(this.props.uploadedImage)
    {
        console.log("Uploadded")
  
        this.setState({uploadImage:this.props.uploadedImage})
    }
}
    handleModalClose = () => {
        this.props.handleClose()
        this.setState({ imageLoad: "upload" ,  drop: "Or drag a file here",
        uploadImage: "" , uploadImageError:false , uploadImageErrorMsg : ""})
    }

    ReaderImg = (e: any) => {
        const file = e.target.files[0]
        if(file.type?.includes("image")){
        this.setState({ drop: "Uploading..." , uploadImageError:false , uploadImageErrorMsg : "" })
        this.setState({uploadImage: e.target.files[0]})
        }
        else{
            toast.error("Please upload a valid image type file")
            this.setState({ drop: "Or drag a file here" })
        }
    }

    handleDrop = (event: any) => {
        event.preventDefault();
        this.setState({ drop: "Uploading..." , uploadImageError:false , uploadImageErrorMsg : "" })
        const filesArray = event.dataTransfer.files;
        if(filesArray[0]?.type?.includes("image")){
        this.setState({ uploadImage: filesArray[0] })
        }
        else{
            toast.error("Please upload a valid image type file")
            this.setState({ drop: "Or drag a file here" })
        }
    };

    handleChangeTab = (tab:string) => {
        this.setState({ imageLoad: tab ,  drop: "Or drag a file here",
        uploadImage: "" , uploadImageError:false , uploadImageErrorMsg : ""})
    }

    handleDragOver = (event : any) => {
      event.preventDefault();
      event.stopPropagation();
    }

    handleDragEnd = (event : any) => {
        event.preventDefault();
        event.stopPropagation();
    }

    validatePostImage = () => {
        let isValid = true
        if(!this.state.uploadImage) {
            this.setState({
                uploadImageError:true,
                uploadImageErrorMsg:"Please upload an image"
            })
            isValid=false
        }
        return isValid;
    }
    
    handleUploadImage = () =>{
        if(this.validatePostImage()){
            this.props.handleFinalPostOpen(this.state.uploadImage)
            this.setState({uploadImage:"", drop: "Or drag a file here" , imageLoad:"upload"})
        }
    }

    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

       
    



       
        // Customizable Area End
    }

}

// Customizable Area End