// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ApiCall from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
export interface ReportEvent {
  data: [{ message: string }]
}

export interface ReportError {
  errors: [{ meassage: string }]
}

export interface Reason {
  title: string,
  active: boolean,
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tab: boolean;
  dropDownData: Array<Reason>;
  openDropDown: boolean;
  selectedReason: string;
  showReason: string;
  eventID: string;
  postId: string;
  postError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ReportController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  reportpostapiApiCallId: string = "";
  reportPostApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    
    this.state = {  
      showReason: "",
      eventID: "",
      postId: "",
      postError: "",    
      tab: true,
      dropDownData: [
        {
          title: "Violence",
          active: false,
        },
        {
          title: "Harrasment",
          active: false,
        },
        {
          title: "False Information",
          active: false,
        },
        {
          title: "Spam",
          active: false,
        },
        {
          title: "None",
          active: false,
        },
      ],
      openDropDown: false,
      selectedReason: "",
      
       
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const id = this.props.navigation.getParam("navigationBarTitleText")
    let postId = id.replace(/\D/g, "");
    if (id.includes("post")) return this.setState({ postId: postId });
    this.setState({ eventID: id });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {

        this.responseSuccessCell(apiRequestCallId, responseJson)
      } else if (responseJson && responseJson.errors) {
        this.responseFailureCell(apiRequestCallId, responseJson)

      }
      if (responseJson && apiRequestCallId === this.reportPostApiCallId) return this.reportPostApiResponse(responseJson);
    }
  }

  responseSuccessCell = async (apiRequestCallId: string, responseJson: ReportEvent) => {

    if (apiRequestCallId === this.reportpostapiApiCallId) {
      this.reportSucessCallBack(responseJson);
    }
  }

  responseFailureCell = async (apiRequestCallId: string, responseJson: ReportError) => {

    if (apiRequestCallId === this.reportpostapiApiCallId) {
      this.reportFailureCallBack(responseJson);
    }
  };

  reportApi = async () => {
    if (this.state.postId) return this.reportPostApi();
    if (this.state.showReason) {
      let formdata = new FormData();
      formdata.append("event_id", this.state.eventID);
      formdata.append("report_reason", this.state.showReason);
      this.reportpostapiApiCallId = await ApiCall({
        method: "PUT",
        endPoint: configJSON.reportEventEndPOint,
        body: formdata,
        type: 'formData'

      });
    } else { this.setState({ postError: "Please Select the reason" }) }
  };

  reportPostApi = async () => {
    if (!this.state.showReason) return this.setState({ postError: "Please Select the reason" });
    const loginToken = await getStorageData('LoginSuccessToken') 
    const signupToken = await getStorageData('SignupSuccessToken'); 
    
    let body = new FormData();
    body.append("post_id", this.state.postId)
    body.append("report_reason", this.state.showReason)

    this.reportPostApiCallId = sendAPIRequest(configJSON.reportPostEnd, {
      method: configJSON.PutAPiMethod, 
      body: body, 
      headers: { 
        token: signupToken || loginToken 
      } 
    })
  };

  reportPostApiResponse = (responseJson: { data: [{ message: string }] }) => {
    if (responseJson.data) {
      toast.success(responseJson.data[0].message);
      this.props.navigation.goBack();
    }
  };

  reportSucessCallBack = (res: ReportEvent) => {
    if (res.data) {
      this.setState({ showReason: "" })
      this.goBackreport()
      toast.success(res.data[0].message)
    }
  };

  reportFailureCallBack = (res: ReportError) => {
    toast.error(res.errors[0].meassage)
  };

  handleDetailButton = (title: string, itemIndex: number) => {
    this.state.dropDownData.forEach((item: { title: string, active: boolean }, index: number) => { 
      if (index === itemIndex) {
        item.active = !item.active;
        this.setState({
          dropDownData: this.state.dropDownData,
          selectedReason: title,
        });
      } else {
        item.active = false;
        this.setState({ dropDownData: this.state.dropDownData });
      }
    });
  };

  handleOpenDropDownButton = () => {
    this.setState({ openDropDown: !this.state.openDropDown, postError: "" })
  };

  goBackreport = () => {
    this.props.navigation.goBack()
  };

  handleDoneButton = () => {
    this.setState({ 
      openDropDown: !this.state.openDropDown,
      showReason: this.state.selectedReason,
    })
  };

  // Customizable Area End
}
