import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes: any;
    editedImage:boolean;
    linkOpen:boolean;
    urlLink:any;
    urlLinkError:boolean;
    urlLinkErrorMsg:string;
    description:string;
    descriptionError:boolean;
    descriptionErrorMsg:string;
    thumbnail:any;
    thumbnailError:boolean;
    thumbnailErrorMsg:string;
    handleLinkClose:any;
    ThumbnailImg:any;
    handlePublish:any;
    handleChange:any
    disablePublish:any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
  /*istanbul ignore next */
 export default class CreatePostLinkModalController extends BlockComponent<
     Props,
     S,
     SS
> {
    apiCreatePostCallId: any
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
        }

    }

    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        console.log("apiRequestCallId", apiRequestCallId)

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );


        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.apiCreatePostCallId) {
                if (!responseJson.errors) {
                    console.log("myresponse.........", responseJson)
                } else {
                    this.parseApiErrorResponse(responseJson);
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }


        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const otpAuthTkn = message.getData(
                getName(MessageEnum.AuthTokenDataMessage)
            );
            if (otpAuthTkn && otpAuthTkn.length > 0) {

                runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
            }
        }
        // Customizable Area End
    }

}
// Customizable Area End