// Customizable Area Start
import React from "react";
import { AppBar, Toolbar, Typography, TextField, Box, Grid, Card, CardMedia,IconButton,Popper,MenuList, MenuItem, Paper,Grow,ClickAwayListener, Avatar, CardContent, List, Tabs, Tab, ListItem, InputAdornment, Button } from '@material-ui/core';
import { createStyles, withStyles, Theme, styled } from "@material-ui/core/styles";
import SearchIcon from "./SVGIcons/SearchIcon.web";
import LayoutController, { Props, ResponseUpcommingEvent, configJSON } from "./LayoutController.web";
import { global, gallery, close, people, SaveIcon,HelpIcon, MyEvent, SettingIcon, Broadcaste, logOut,Refresh } from "./assets";
import ListItemText from '@material-ui/core/ListItemText';
import CalendarIcon from "./SVGIcons/CalendarIcon.web";
import { eagel, HeaderFVLogo, User } from "../../blocks/landingpage/src/assets"
import "./Layout.web.css"
import FilterSearchIcon from "./SVGIcons/FilterSearchIcon.web";
import { LogoutModal } from "../../blocks/Settings/src/LogoutModal.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import Broadcast from "../../blocks/LinkedAccounts/src/Broadcast.web";
import moment from "moment";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles.web";
import SwitchAccount from "../../blocks/LinkedAccounts/src/SwitchAccount.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
    createStyles({
        nabvar: {
            background: "#01548a",
            display: "Flex", 
            justifyContent: "space-between",
            alignItems: "center",
            height: "120px",
            [theme.breakpoints.down('sm')]: {
                height: "60px"
            }
        },
        header: {
            boxShadow: "none",
            left: 0,
            right:0,
            top:0,
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "5px"
        },
        inputInput: {
            width: '350px',
            backgroundColor: "#fff",
            borderRadius: "10px",
            [theme.breakpoints.down('xs')]: {
                width: '100px',
            },
        },
        userCard: {
            textAlign: "center",
            boxShadow: "none",
            border: "1px solid #808080",
            width: "100%",
            margin: "0 auto",
            borderRadius: "12px"
        },
        userCommunity: {
            background: "transparent",
            boxShadow: "none",
            border: "1px solid #808080",
            width: "100%",
            margin: "0 auto",
            borderRadius: "12px"
        },
        media: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        mediaPost: {
            maxWidth: "100%",
            height: "300px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
        },
        line: {
            fontSize: "40px",
            color: "lightgrey"
        },
        create: {
            border: 'none', "& fieldset": { border: 'none' },
            borderRadius: "10px",
            background: "#F4F4F4",
            height: "30px",
            padding: "10px",
            width: "100%"
        },
        Icons: {
            display: "flex",
            justifyContent: "space-around"
        },
        iconBtn: {
            '&:hover': {
                borderRadius: "0px"
            },
        },
        messComment: {
            outline: "0px solid",
            border: "none",
            padding: "5px",
            width: "97%",
        },
        headerLogoSpan: {
            backgroundColor: "#fff",
            padding: "4px"
        },
        headerLogo: {
            width: "150px",
            objectFit: "contain",
            [theme.breakpoints.up('sm')]: {
                width: '100px',
            },
        },
        upcomingCommunityTypo: {
            marginLeft: "5%"
        },
        avatar: {
            width: "150px",
            height: "150px",
            objectFit: "contain",
            "& .MuiAvatar-img":{
                objectFit: "initial",
            }
        },
        communityAvatar: {
            width: "150px",
            height: "150px",
            objectFit: "contain",
            borderRadius: "8px",
            "& .MuiAvatar-img":{
                objectFit: "initial",
            }
        },
        avatarImg: {
            width: "50px",
            height: "50px"
        },

        avatarNav: {
            width: "50px",
            height: "50px",
        },

        headerProfilePicImg:{
            width:"inherit",
            height:"inherit",
            objectFit:"contain"
        },
        headerProfilePicImgDiv: {
            width: "50px",
            height: "50px",
            borderRadius: "50%"
        },
        profileAvatar: {
            width: "50px",
            height: "50px",
            objectFit: "contain"
        },
        mainBodyContainer: {
            marginTop: "20px",
            padding: "20px 0px"
        },
        activeBorderBottom: {
            backgroundColor: "#005487",
            width: "120px"
        },
        listItem: {
            padding: "0px",
            display: "flex",
            flexDirection: "column"
        },
        listItemIcon: {
            minWidth: "0px"
        },
        listItemText: {
            color: "#005487",
            textTransform: "capitalize",
            fontSize: "16px"
        },
        LayoutContainerSpace: {
            padding: "0",
            [theme.breakpoints.up("md")]: {
                padding: "0 80px",
            },
        },
        userImageStyle: {
            width:"50px",
            height:"50px",
            objectFit:"cover",
            borderRadius: "50%",
        },
        btn: {
            maxWidth:"50px",
            padding: "2px 10px",
            border: "solid 1px #005487",
            fontFamily: "Verdana",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "norma",
            letterSpacing: "normal",
            textSlign: "center",
            color: "#005487",
            borderRadius: "0px",
            whiteSpace:"nowrap",
            fontSize:"10px",
            "& .MuiButton-label":{
                fontSize:"10px"
            },

        },

        btnFontStyles:{
            fontSize:"10px",
        },


        FriednRequest: {
            boxShadow: "none",
            border: "1px solid #808080",
            width: "100%",
            margin: "0 auto",
            borderRadius: "12px"
        },
        btn1: {
            background: "#005487",
            marginRight: "5px",
            color: "white",
            "&:hover": {
                color: "#005487",
            },
        },
        friendCardText:{
            whiteSpace:"nowrap"
        },
        text: {
            fontDamily: "Verdana",
            fontSize: "12px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.83",
            letterSpacing: "-0.41px",
            color: "#808080",
        },
        mutualImage: {
            height: "16px",
            width: "16px",
            borderRadius: "50%",
        },
        mutualImage1: {
            position: "absolute",
        },
        mutualImage2: {
            position: "absolute",
            top: "12%",
            left: "29%",
        },
        cardUserImage: {
            height: "50px",
            width: "50px",
            borderRadius: "50%",
            cursor: "pointer"
        },
        requestBtn: {
            width: "112px",
            paddingTop: "2.5px",
            paddingBottom: "2.5px",
            border: "solid 1px #005487",
            fontFamily: "Verdana",
            color: "#005487",
            fontSize: "14px !important",
            borderRadius: "0px",
            fontWeight: "normal",
            textTransform: "initial",
        },
        requestBtn1: {
            backgroundColor: "#005487",
            color: "white",
            "&:hover": {
                color: "white",
                backgroundColor: "#005487",
            },
        },
        showallbtn: {
            color: "#005487",
            fontFamily: "Verdana",
            fontSize: "14px !important",
            textTransform: "initial",
            fontWeight:"normal"
        },
        requestBtnGroup: {
            display: "flex",
            gap:"10px",
            marginTop: "10px",
            flexWrap: "wrap"
        },
        pointer:{
            cursor: "pointer"
        },
        searchImg:{
            width: "40px",
            height: "40px",
            borderRadius: "50px"
        },
        margin:{
            margin: "auto"
        },
        navUser: {
            width: "50px",
            height: "50px"
        },
        navUserModal: {
            width: "48px",
            height: "48px"
        },
        navCommunity: {
            width: "50px",
            height: "50px",
            borderRadius: "3px"
        },
        navCommunityModal: {
            width: "48px",
            height: "48px",
            borderRadius: "3px"
        },
        profileBtn: {
            color: "#005487",
            border: "1px solid #005487",
            height: "40px",
            borderRadius: "12px",
            width:"88%"
        },
        poperDiv: {
            zIndex: 2,
            width: "340px",
            "@media(max-width:600px)":{
                width: "280px"
            }
        },
        borderLogout: {
            borderTop: "1px solid #dcdcdc"
        }

    })
// Customizable Area End

export class Layout extends LayoutController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }

    
    // Customizable Area End

    renderMutualConnections = (mutualConnectionData: { mutual_count: number, files: { photo: string }[] }[]) => {
        const { classes } = this.props
        if (mutualConnectionData.length > 0) {
            const mutualCount = mutualConnectionData[0].mutual_count;

            if (mutualCount === 0) {
                return (
                    <Box ml="12px">
                        <Typography className={classes.text}>--</Typography>
                    </Box>
                );
            } else if (mutualCount === 1) {
                return (
                    <img
                        className={classes.mutualImage}
                        src={mutualConnectionData[0].files[0].photo}
                        alt="profile"
                    />
                );
            } else {
                return (
                    <Box
                        paddingRight="10px"
                        width="2rem"
                        position="relative"
                        display="flex"
                        alignItems="center"
                    >
                        <img
                            className={`${classes.mutualImage} ${classes.mutualImage1}`}
                            src={mutualConnectionData[0].files[0].photo}
                            alt="..."
                        />
                        <img
                            className={`${classes.mutualImage} ${classes.mutualImage2}`}
                            src={mutualConnectionData[0].files[1].photo}
                            alt="..."
                        />
                    </Box>
                );
            }
        } else {
            return (
                <Box ml="12px">
                    <Typography className={classes.text}>--</Typography>
                </Box>
            );
        }
    };

    renderFriend = (friend: { id: string, full_name: string,requested_at?:string,request_id?: string, profile_picture: string, mutual_connection_data: { mutual_count: number, files: { photo: string }[] }[] },index:number, type: string, length: number) => {
        const { mutual_connection_data } = friend
        const { classes } = this.props
        return (
            <Box key={friend.id} borderBottom={(index < length - 1)? "1px solid black": ""}>
            <Box py="16px"  display="flex" px={"20px"}>
                <img
                    src={friend.profile_picture}
                    alt="profile"
                    className={classes.cardUserImage}
                    onClick={()=>this.openProfile(friend.id)}
                />
                <Box ml="12px" display="flex" flexDirection={"column"} width="100%">
                    
                        <Box display="flex" justifyContent={"space-between"} alignItems="center" flexWrap="wrap">
                        <Box component="p" fontSize="17px" sx={{ fontWeight: "bold", fontFamily: "Verdana" }} m={0}>
                            {friend.full_name}
                        </Box>
                        {friend.requested_at && <Box component="p" m={0} sx={{ fontFamily: "Verdana", fontSize: "12px" }} color="#808080"> 
                        {friend.requested_at}
                    </Box>}</Box>
                        <Box>
                            <Box display="flex" position="relative">
                                <Box display="flex">
                                    {this.renderMutualConnections(mutual_connection_data)}
                                </Box>
                                {mutual_connection_data[0]?.mutual_count > 0 && (
                                    <Box ml="12px">
                                        <Typography className={classes.text}>
                                            {mutual_connection_data[0].mutual_count} Mutual Friends
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        {type !== "Friends" && <Box className={classes.requestBtnGroup}>
                            <Button data-test-id={`accept-button-${friend.id}`}
                                  onClick={() => type=== "Recommendation" ? this.addFriendRequest(friend.id) : this.updateRequest(true, friend.request_id??"")}
                                className={`${classes.requestBtn} ${classes.requestBtn1}`}>
                                {type === "Recommendation" ? "Add friend" : "Accept"}
                            </Button>
                            <Button data-test-id={`delete-button-${friend.id}`}
                                  onClick={() => type=== "Recommendation" ? this.removeRecommendation(friend.id) :this.updateRequest(false, friend.request_id??"")}
                                className={`${classes.requestBtn}`}>
                                {type === "Recommendation" ? "Remove" : "Delete"}
                            </Button>
                        </Box>}
                    </Box>
            </Box>
            </Box>
        );
    };

    renderRecommended = (type: string) => {
        const { recommendedFriendsList, requestList, friendsList } = this.state;
        let data: {id:string,full_name:string,profile_picture: string,request_id?: string,requested_at?: string,mutual_connection_data:{mutual_count:number,files:{photo:string}[]}[]}[]= [];
        if (type === "Request") {
            data = requestList;
        } else if (type === "Recommendation") {
            data = recommendedFriendsList;
        } else if (type === "Friends") {
            data = friendsList
        }
        return (data)?.filter((_, index) => index < 3).map((friend, index) => this.renderFriend(friend, index, type, Math.min(3,data.length)));
    };


    renderShowAll = () => {
        return (
        <div onClick={this.toggleShowAll} className="showall">
           {configJSON.show_all}
        </div>
        )
    }

    renderListSearchItem = (profilePic: string, text: string, type: string) => {
        const {classes} = this.props;
        let temp;
        if (profilePic) {
             temp = <img src={profilePic} className={classes.searchImg}  alt="search" />
        } else if (type === "Post") {
            temp = <Box className={classes.searchImg} display={"flex"}><img src={gallery} alt="search" className={classes.margin}/></Box>
        } else {
            temp = <Box className={classes.searchImg} display={"flex"}><img src={global}  alt="search" className={classes.margin}/></Box>
        }
        return (
            <Box display={"flex"} alignItems={"center"}>
                {temp}
                <Box component={"p"} m={0} ml={"16px"} fontFamily={"Verdana"} color="#808080">{text.length>30 ? text.slice(0,30)+'...' : text}</Box>
            </Box>
        )
    }
    render() {

        // Customizable Area Start
      
        const { classes, children } = this.props
        return (

            <Box height={"100%"} className="Layout">
                {this.state.openBroadcast &&<Broadcast open={this.state.openBroadcast} handleClose={this.handleCloseBroadcast} navigation={this.props.navigation} id={"broadcaste"}/>}
                {this.state.switchAccount && <SwitchAccount navigation={this.props.navigation} id={"switch-account"} open={this.state.switchAccount} handleClose={this.handleMyAccount} resetData={this.getLoginUserInformation}/>}
                {this.state.communityModal && <CustomisableUserProfiles open={this.state.communityModal} handleClose={this.closeCommunityModal} type="homePage" navigation={this.props.navigation} id="home-page-communitiy"/>}
                <Box width={"100%"} sx={{
                    display: this.handleConditionaRenderForInitialScreens() ? "none" : "flex",
                    marginBottom: "32px",
                    height: {xs:this.handleConditionalRenderForUserProfile() ? "60px" : "132px",md:this.handleConditionalRenderForUserProfile() ? "120px" : "192px"}
                }}>
                    <AppBar position="fixed" color="transparent" className={classes.header}>
                        <Toolbar className={classes.nabvar}>
                            <Typography className={classes.headerLogoSpan} component={"span"}>
                                <img className={classes.headerLogo} src={HeaderFVLogo} alt="Logo" />
                            </Typography>
                            <div className="search-section">
                                <div className={classes.search}>
                                    <TextField
                                        ref={this.state.searchInputRef}
                                        data-test-id="txtInput"
                                        value={this.state.searchInput}
                                        onChange={this.handleSearchInputChange}
                                        placeholder="Search"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment className={classes.inputAdornment} position="start">
                                                    <div style={{cursor:"pointer"}}>
                                                        <SearchIcon onClick={this.showSearchModel} width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="1.5" fill="none" />
                                                    </div>

                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment className={classes.inputAdornment} position="start">
                                                    <Button onClick={this.filterToggle}>
                                                        <FilterSearchIcon
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            stroke="#808080"
                                                            strokeWidth="1.5"
                                                            fill="none"
                                                        />
                                                    </Button>

                                                    {this.state.filter && (
                                                        <List style={webPage.listStyle}  >
                                                            <ListItem className="ListMenu">
                                                                <ListItemText
                                                                    className="ListItemText"
                                                                    onClick={() =>
                                                                        this.handleSelect(this.state.list[0])
                                                                    }
                                                                    primary="Photos"
                                                                />
                                                            </ListItem>

                                                            <ListItem className="ListMenu">
                                                                <ListItemText
                                                                    className="ListItemText"
                                                                    onClick={() =>
                                                                        this.handleSelect(this.state.list[1])
                                                                    }
                                                                    primary="Videos"
                                                                />
                                                            </ListItem>
                                                            <ListItem className="ListMenu">
                                                                <ListItemText
                                                                    className="ListItemText"
                                                                    onClick={() =>
                                                                        this.handleSelect(this.state.list[2])
                                                                    }
                                                                    primary="Events"
                                                                />
                                                            </ListItem>
                                                            <ListItem className="ListMenu">
                                                                <ListItemText
                                                                    className="ListItemText"
                                                                    onClick={() =>
                                                                        this.handleSelect(this.state.list[3])
                                                                    }
                                                                    primary="URL's"
                                                                />
                                                            </ListItem>

                                                        </List>
                                                    )}
                                                </InputAdornment>
                                            ),
                                            disableUnderline: true
                                        }}
                                        inputProps={{ 'aria-label': 'search', className: `${classes.inputInput}` }}
                                    />

                                </div>
                                {this.state.isSearchModel && <div ref={this.state.searchModelRef} className="view_shape">

                                    {this.state.searchInput ?
                                        (
                                            <>
                                                {this.state.searchResults
                                                    .filter((_search, index: number) => (this.state.showAllEnabled || index < 3))
                                                    .map((search) => (
                                                        <Box key={search.id} className={`${classes.pointer}`} onClick={() => this.handleSearchItemClick(search, search.type)}>
                                                            {this.renderListSearchItem(search.profilePic, search.search_result, search.type)}
                                                        </Box>
                                                    ))}
                                                {this.state.searchResults.length === 0 && <span className="noResults">{configJSON.noSearchResultsMessage}</span>}
                                                {this.state.searchResults.length > 3 && !this.state.showAllEnabled && this.renderShowAll()}
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                            <Box component={"p"} m={0} color="#005478" fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Recent</Box>
                                                {this.state.recentSearchesList
                                                    .filter((_search, index: number) => (this.state.showAllEnabled || index < 3))
                                                    .map((search) => (
                                                        <Box key={search.id} display={"flex"} justifyContent={"space-between"} alignItems={"center"} className={`${classes.pointer}`} onClick={() => this.handleSearchItemClick(search, search.type)}>
                                                            {this.renderListSearchItem(search.profilePic, search.search_result, search.type)}
                                                            <Box component={"span"}><img src={close} alt="remove" onClick={(e)=>this.deleteRecentSearch(e,search.deleteId)}/></Box>
                                                        </Box>
                                                    ))}
                                                {this.state.recentSearchesList.length === 0 && <span className="noResults">{configJSON.noSearchResultsMessage}</span>}
                                                {this.state.recentSearchesList.length > 3 && !this.state.showAllEnabled && this.renderShowAll()}
                                            </>
                                        )
                                    }
                                </div>}
                            </div>
                            <IconButton aria-controls={this.state.showUserProfileModel ? 'menu-list-grow' : ''} aria-haspopup="true" onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.setProfileModel(e)}>
                                <Avatar className={this.state.information?.type === "account" ? classes.navUser : classes.navCommunity} src={this.state.information?.attributes?.profile_picture} />
                            </IconButton>
                            <Popper open={Boolean(this.state.showUserProfileModel)} className={classes.poperDiv} anchorEl={this.state.showUserProfileModel} role={undefined} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'start top' : 'start bottom'  }}
                                    >
                                        <Paper style={webPage.modalButton}>
                                            <ClickAwayListener onClickAway={this.closeProfileModal}>
                                                <MenuList autoFocusItem={Boolean(this.state.showUserProfileModel)} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                                    <Box my={"12px"} px={"20px"} borderBottom={"1px solid #dcdcdc"} display={"flex"} flexDirection={"column"}>
                                                        <Box display="flex">
                                                        <Avatar className={this.state.information?.type === "account" ? classes.navUserModal : classes.navCommunityModal} src={this.state.information?.attributes?.profile_picture} />
                                                        <Box display={"flex"} flexDirection={"column"} ml={"12px"}>
                                                            <Box component={"p"} m={0} fontFamily={"Verdana"} fontWeight={'bold'} fontSize={"17px"}>{this.state.information?.attributes?.full_name}</Box>
                                                            <Box component={"p"} m={0} color="#808080" fontFamily={"Verdana"} fontSize={"12px"}>{this.state.information?.attributes?.email}</Box>
                                                        </Box>
                                                        </Box>
                                                        <Box mt={"16px"} mb={"20px"} width={"100%"} display={"flex"} justifyContent={"center"}>
                                                            <Button className={classes.profileBtn} onClick={() => this.handleMenuClickItem("OtherUserProfile")}>View Profile</Button>
                                                        </Box>
                                                    </Box>
                                                    <Box component={"p"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"} color={"#005487"} m={"20px 20px 0px"}>Account</Box>
                                                    {(this.state.information.attributes.is_local_admin || this.state.information.type === "community") && <MenuItem onClick={this.handleMyAccount}>
                                                        <Box display={"flex"} alignItems={"center"} p={"5px"} py={"11px"}>
                                                            <img src={Refresh} alt="Refresh" />
                                                            <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"}>Switch Account</Box>
                                                        </Box>
                                                    </MenuItem>}
                                                    <MenuItem onClick={() => this.handleMenuClickItem("SavePost")}>
                                                        <Box display={"flex"} alignItems={"center"} p={"5px"} py={"11px"}>
                                                            <img src={SaveIcon} alt="save" />
                                                            <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"} >Save Post</Box>
                                                        </Box>
                                                    </MenuItem>
                                                    {this.state.information.type === "community" && <MenuItem onClick={this.myCommunityNavigation}>
                                                        <Box display={"flex"} alignItems={"center"} p={"5px"} py={"11px"}>
                                                            <img src={people} alt="people" />
                                                            <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"} >My Community</Box>
                                                        </Box>
                                                    </MenuItem>}
                                                    <MenuItem onClick={() => this.handleMenuClickItem("MyEvent")}>
                                                        <Box display={"flex"} alignItems={"center"} p={"5px"} py={"11px"}>
                                                            <img src={MyEvent} alt="MyEvent" />
                                                            <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"} >My Events</Box>
                                                        </Box>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => this.handleMenuClickItem("Settings")}>
                                                        <Box display={"flex"} alignItems={"center"} p={"5px"} py={"11px"}>
                                                            <img src={SettingIcon} alt="SettingIcon" />
                                                            <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"} >Settings</Box>
                                                        </Box>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => this.handleMenuClickItem("Help")}>
                                                        <Box display={"flex"} alignItems={"center"} p={"5px"} py={"11px"}>
                                                            <img src={HelpIcon} alt="HelpIcon" />
                                                            <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"} >Help</Box>
                                                        </Box>
                                                    </MenuItem>
                                                    {this.state.information.type === "community" && <MenuItem onClick={this.handleBroadcaste}>
                                                        <Box display={"flex"} alignItems={"center"} p={"5px"} py={"11px"}>
                                                            <img src={Broadcaste} alt="Broadcast" />
                                                            <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"} >Broadcast Message</Box>
                                                        </Box>
                                                    </MenuItem>}
                                                    <MenuItem onClick={() => this.setLogoutModel(true)} className={classes.borderLogout}>
                                                        <Box display={"flex"} alignItems={"center"} p={"5px"} py={"11px"}>
                                                            <img src={logOut} alt="logOut" />
                                                            <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"} >Log Out</Box>
                                                        </Box>
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Toolbar>


                                    <LogoutModal
                                        title={configJSON.logoutTitle}
                                        deactivateSubmit={this.handleLogoutSuccess}
                                        open={this.state.showLogoutModel}
                                        onClose={()=>this.setLogoutModel(false)}
                                        handleLogout={this.handleLogoutSuccess}
                                    />

                        {!this.handleConditionalRenderForUserProfile() &&
                            <TabsContainer>
                                <Tabs
                                    value={this.state.tabValue}
                                    onChange={this.handleChangeTab}
                                    variant="scrollable"
                                    scrollButtons="on"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="scrollable force tabs example"
                                >
                                    {this.generateNavbarMenuList().map(item => {
                                        if (item.id === 3 && this.state.information.attributes.is_notification_available && this.state.tabValue !== 3) {
                                            return <Tab label={item.label} key={item.label} icon={item.redIcon} {...this.a11yProps(item.id)} />
                                        }
                                        return <Tab label={item.label} key={item.label} icon={item.icon} {...this.a11yProps(item.id)} />
                                    })}
                                </Tabs>
                            </TabsContainer>
                        }
                    </AppBar >
                </Box>
                
                        <Grid component={Box} className={`LayoutContainer ${!this.hideAsideInfo()? "LayoutContainerResponse" : ''}`} container mb={"40px"} marginX={{xs:this.hideAsideInfo()? "" :"24px",md:this.hideAsideInfo() ? "" : "100px"}}>
                           
                            {!this.hideAsideInfo() && 
                            <Grid item component={Box} display={{xs: "none", md: "block"}} md={4} lg={3} >
                                <aside>
                                <Box display={"flex"} flexDirection="column" alignItems={"center"} className="gap-36">
                                    
                                        <Card className={classes.userCard}>
                                            <Box display={"flex"} flexDirection="column" justifyContent={"center"} pt={"45px"} pb={"35px"} pr={"10px"} pl={"10px"} >
                                                <CardMedia className={`${classes.media}`}>
                                                   
                                                        <Avatar
                                                            aria-label="profile"
                                                            className={this.state.information.type==="community"?classes.communityAvatar:classes.avatar}
                                                            src={this.state.information.attributes.profile_picture}
                                                        />
                                                        

                                                </CardMedia>
                                                <CardContent>
                                                    
                                                        <Typography variant="body2" component="p" className={`Heading4 BoldFont FontColor6 TextCapitalize`} >
                                                         {this.state.information.attributes.full_name}
                                                     </Typography>
                                                       {this.state.information.type === "community" && <>
                                                            <br></br>
                                                      <a style={{color:"#0075a9",textDecoration:"underline",cursor:"pointer"}} onClick={this.handleContactInfoNavigation}>contact Info</a>
                                                        </>}
                                                    

                                                   { this.state.information.type === "account" && <> {this.state.information.attributes.pronouns && <Typography variant="body2" component="p" className={`Heading4 BoldFont FontColor2`}>({this.state.information.attributes.pronouns})</Typography>}
                                                        <Typography variant="body2" className={`Heading5 FontColor2`} component="p">{`${this.state.information.attributes.city}, ${this.state.information.attributes.state}`}</Typography></>}

                                                    <Grid container spacing={2} justifyContent="center" style={{ marginTop: "15px" }}>
                                                        <Grid item>
                                                            <Typography variant="body2" className={`Heading4 BoldFont FontColor6`} component="p" >Post</Typography>
                                                            <Typography className={`Heading5 BoldFont PrimaryColor1`}>{this.state.information.attributes.total_post_count}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <div className={classes.line}>|</div>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body2" component="p" className={`Heading4 BoldFont FontColor6`}>
                                                                Followers
                                                            </Typography>
                                                            <Typography className={`Heading5 BoldFont PrimaryColor1 pointer`} onClick={this.handleFollowerList}>{this.state.information.attributes.follower_count}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Box>
                                        </Card>
                                    
                                    <Grid container  >
                                        <Card className={classes.userCommunity}>
                                            <Box>
                                                <Box display={"flex"} alignItems="center" justifyContent={"space-between"} padding={"20px 20px"}>
                                                    <Typography component="span" className="Heading3 BoldFont FontColor6 wordWrap">Communities</Typography>
                                                    {this.state.communitiesData.length>0 && <Button className={classes.showallbtn} onClick={this.showAllCommunities}>{configJSON.show_all}</Button>}
                                                </Box>
                                                {this.state.communitiesData.length > 0 ? <CommunityBox component={"section"}>
                                                    {this.state.communitiesData.filter((_, index) => index < 3).map(item =>
                                                        <Box className={"singleItem"} key={item.id} alignItems="center" display="flex" onClick={()=>this.openCommunity(item.id)}>
                                                            <Avatar className="img" src={item.img} />
                                                            <Box className="community-name" m={0} component={"p"} >{item.name}</Box>
                                                        </Box>
                                                    )}
                                                </CommunityBox>
                                                 : <Box style={webPage.upcomming}><Typography variant="body2" className={`HeadingFont6 FontColor2 BoldFont ${classes.upcomingCommunityTypo}`}>no communities</Typography></Box> }
                                            </Box>

                                        </Card>
                                    </Grid>
                                    <Grid container >
                                        <Card className={classes.userCommunity}>
                                            <Box display={"flex"} alignItems="center" justifyContent={"space-between"} padding={"20px 20px"}>
                                                <Typography component="span" className="Heading3 BoldFont FontColor6 wordWrap">Upcoming Events</Typography>
                                                {this.state.upcommingEvent.length>3 && <Button className={classes.showallbtn} onClick={this.showAllEventFn}>{this.state.showAllEvents ? configJSON.show_less : configJSON.show_all}</Button>}
                                            </Box>
                                            {this.state.upcommingEvent.length > 0 ? <EventBox sx={{maxHeight:"305px"}} bgcolor={"#f4f4f4"}>
                                                {this.state.upcommingEvent.filter((_, index) => this.state.showAllEvents ? _ : index < 3).map((item: ResponseUpcommingEvent) => {
                                                return (<React.Fragment key={item.id}>
                                                    {
                                                        <Box display={"flex"} className="pointer" mb={"1px"} alignItems={"center"} px={"18px"} sx={{height:"60px"}} bgcolor={"#fff"} borderRadius={"12px"} onClick={() => this.eventDetails(item.id)}>
                                                                <CalendarIcon className={`CursorPointer`} width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="1.5" fill="none" />
                                                            <Box color={"#005487"} ml={"20px"}>
                                                                <Box component={"p"} mb={"6px"} fontSize={"16px"} fontFamily={"Verdana"} fontWeight={"bold"} className="wordWrap" m={0}>{item.attributes.event_name}</Box>
                                                                <Box component={"p"} fontSize={"12px"} fontFamily={"Verdana"} className="wordWrap" m={0}>{moment(new Date(item.attributes.start_date)).format("Do-MMM")} to {moment(new Date(item.attributes.end_date)).format("Do-MMM")}</Box>
                                                            </Box>
                                                        </Box>
                                                    }
                                                </React.Fragment>
                                                )
                                            })}
                                            </EventBox> 
                                            : <Box style={webPage.dateEvent}><Typography variant="body2" className={`HeadingFont6 FontColor2 BoldFont ${classes.upcomingCommunityTypo}`}>no upcoming Event</Typography></Box>}
                                        </Card>
                                    </Grid>
                                    {/* Friends listing card starts here */}
                         {this.isOtherUserProfileViewPage() &&<Card className={classes.FriednRequest}>
                                        <Box
                                            display={"flex"}
                                            alignItems="center"
                                            justifyContent={"space-between"}
                                            padding={"20px 20px"}
                                            borderBottom={"1px solid #005487"}
                                        >
                                            <Typography
                                                component="span"
                                                className="Heading4 BoldFont LinkEmbedTitle wordWrap"
                                            >
                                                Friends
                                            </Typography>
                                            {this.state.friendsList.length >3 && <Button className={classes.showallbtn} onClick={this.showAllFriends}>{configJSON.show_all}</Button>}        
                                        </Box>
                                        <Box style={webPage.FRequestTabMain}>
                                            {/* Cards */}
                                            {this.state.requestLoader.friends ? <Box display={"flex"} justifyContent={"center"} my={"3px"}><CircularProgress size={"35px"} /></Box>: this.state.friendsList.length === 0 ?
                                                <Box component="p" textAlign={"center"}> No Friends Found</Box> : this.renderRecommended("Friends")}
                                        </Box>
                                    </Card>}
                                    {this.shouldRequestsListShow() &&
                                        <React.Fragment>
                                            
                                                <Card className={classes.FriednRequest}>
                                                    <Box
                                                        display={"flex"}
                                                        alignItems="center"
                                                        justifyContent={"space-between"}
                                                        padding={"20px 20px"}
                                                        borderBottom={"1px solid #005487"}
                                                    >
                                                        <Typography
                                                            component="span"
                                                            className="Heading4 BoldFont LinkEmbedTitle wordWrap"
                                                        >
                                                            Friend Request
                                                        </Typography>
                                                        {this.state.requestList && this.state.requestList.length > 3 && <Button className={classes.showallbtn} onClick={this.handleShowAllRequest}>{configJSON.show_all}</Button>}
                                                    </Box>
                                                    <Box style={webPage.FRequestTabMain}>
                                                        {this.state.requestLoader.request ? <Box display={"flex"} justifyContent={"center"} my={"3px"}><CircularProgress size={"35px"} /></Box> : this.state.requestList.length === 0 ?
                                                <Box component="p" textAlign={"center"}> No Requests Found</Box> : this.renderRecommended("Request")}
                                                    </Box>
                                                </Card>
                                            
                                            
                                                <Card className={classes.FriednRequest}>
                                                    <Box
                                                        display={"flex"}
                                                        alignItems="center"
                                                        justifyContent={"space-between"}
                                                        padding={"20px 20px"}
                                                        borderBottom={"1px solid #005487"}
                                                    >
                                                        <Typography
                                                            component="span"
                                                            className="Heading4 BoldFont LinkEmbedTitle wordWrap"
                                                        >
                                                            Recommended for you
                                                        </Typography>
                                                        {this.state.recommendedFriendsList.length > 3 && <Button className={classes.showallbtn} onClick={this.showAllRecommanded}>{configJSON.show_all}</Button>}
                                                    </Box>
                                                    <Box style={webPage.FRequestTabMain}>
                                                        {this.state.requestLoader.recommandation ? <Box display={"flex"} justifyContent={"center"} my={"3px"}><CircularProgress size={"35px"} /></Box> : this.state.recommendedFriendsList?.length === 0 ?
                                                <Box component="p" textAlign={"center"}> No recommendation Found</Box> : this.renderRecommended("Recommendation")}
                                                    </Box>
                                                </Card>
                                            
                                        </React.Fragment>
                                    }
                                </Box>
                                </aside></Grid>}

                            { <Grid item xs={12} md={this.hideAsideInfo() ? 12 : 8} lg={this.hideAsideInfo() ? 12 : 9}>
                            <main>
                                {React.cloneElement(children, {
                                    resetData: ()=>this.getLoginUserInformation()
                                })}
                            </main>
                            </Grid>}
                        </Grid>
                
            </Box>
        );
        // Customizable Area End
    }

}


// Customizable Area Start

const webPage = {
    upcomming: {
        display: "flex",
        bgcolor: "#fff",
        alignItems: "center",
        borderRadius: "12px",
        background: "#fff",
        padding: "10px 20px",
        marginBottom: "3px",
    },
    modalButton: {
        boxShadow: "2px 4px 16px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "12px",
    },
    dateEvent: {
        display: "flex",
        bgcolor: "#fff",
        alignItems: "center",
        borderRadius: "12px",
        background: "#fff",
        padding: "10px 20px",
        marginBottom: "3px",
        cursor: "pointer"
    },

    boxWidth:{
        width: "100%"
    },

    startDate: { 
        color: "#005487", 
        fontFamily: "Verdana",
        fontSize: "12px",
        marginLeft: "5%"
        },

        communitiesDis: { 
            color: "#808080", 
            fontFamily: "Verdana",
            fontSize: "12px",
            marginLeft: "5%"
            },
    eventNameBox: {
        display: "flex",
        alignItems: "center",
    },

    imgStyle: {
        width: "20px",
        cursor: "pointer"
    },
    listStyle: {
        backgroundColor: "white",
        position: "absolute",
        padding:"7px",
        right: "-0.5rem",
        top: "44px",
        borderRadius: "1rem",
        width: "100%"
    } as const,
    FRequestTabMain: {
        display: "flex",
        bgcolor: "#fff",
        borderRadius: "12px",
        background: "#fff",
        marginBottom: "3px",
        "flex-direction": "column",
    },
}

const EventBox = styled(Box)({
    overflowY: "auto",
    "& .pointer":{
        cursor: "pointer"
    }
})

const CommunityBox = styled(Box)({
    backgroundColor: "#808080",
    borderRadius: "12px",
    "& .singleItem": {
        paddingInline: "16px",
        minHeight: "60px",
        cursor:"pointer",
        marginTop: "1px",
        backgroundColor: "#fff"
    },
    "& .img":{
        width: "24px",
        height: "24px",
        borderRadius: "3px",
        marginRight: "12px"
    },
    "& .community-name":{
        fontSize: "15px",
        fontFamily: "Verdana",
        fontWeight: "bold",
        color: "#808080"
    }
});
const TabsContainer = styled(Box)({
    width:"100%",
    "& .MuiTabs-flexContainer":{
      width:"max-content",
      margin: "auto",
    },
    "& .MuiTab-root":{
      width:"120px",
      minWidth:"120px",
      fontSize: "12px",
      textTransform: "capitalize",
      padding: 0,
    },
    "& .Mui-selected": {
      color: "#005487"
    },
    "& .PrivateTabIndicator": {
      backgroundColor: "#005487",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#005487"
    },
    "& .MuiTabs-root":{
      alignItems: "center",
      height:"72px",
      backgroundColor: "#fff",
    }
  })
export default withStyles(styles, { withTheme: true })(Layout);
// Customizable Area End