// Customizable Area Start
import React from "react";
import { Box,
    Grid,
  Typography,
  FormControl,
  Avatar,
  FormHelperText,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import createComunityController, {
  Props,
} from "./CreateCommunityController.web";
import CameraIcon from "./SVGIcons/CameraIcon.web";
import NoProfileIcon from "./SVGIcons/NoProfileIcon.web";

import CustomTextField from "../../../components/src/CustomTextField.web";

import {
  DescriptionIcon,
  PhoneIcon,
  WebsiteIcon,
  Email,
  UserName,
  profilePick,
} from "./assets";

import CustomButton from "../../../components/src/CustomButton.web";
// Customizable Area End

// Customizable Area Start
/*istanbul ignore next */
const Poststyles = (theme: Theme) =>
  createStyles({
    mainGridContainer: {
      borderRadius: "12px",
      padding: "20px 24px",
      backgroundColor: "#fff",
    },
    doneBtn: {
      height: "56px",
      width: "500px",

      objectFit: "contain",
      margin: "40px 165 0 89px",

      color: "white",
      backgroundColor: "#005487",
    },
    urlPostGrid: {
      flexDirection: "row",
      display: "flex",

      alignItems: "center",
      justifyContent: "center",

      marginBottom: "20px",
      width: "100%",
    },
    urlDemographicPostGrid: {
      flexDirection: "column",
      display: "flex",

      width: "100%",
      alignItems: "center",
      marginBottom: "15px",
    },
    urlPostGridFormControl: {
      width: "92%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    inputBox: {
      right: "0px",
      position: "absolute",
      bottom: "0px",

      height: "25px",
      width: "26px",
      cursor: "pointer",
      opacity: 0,
    },

    cursorPointer: {
      cursor: "pointer",
      height: "24px",
      width: "24px",
    },
    label: {
      marginBottom: "10px",
    },
    avatar: {
      height: "78px",
      width: "78px",

      borderRadius: "30%",
      objectFit: "contain",
    },
  });

// Customizable Area End
  /*istanbul ignore next */
export class CreateCommunity extends createComunityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    const {
      name,
      nameError,
      nameErrorMsg,
      address,
      addressError,
      addressErrorMsg,
      websiteLink,
      websiteLinkError,
      websiteLinkErrorMsg,
      phoneNumber,
      phoneNumberError,
      phoneNumberErrorMsg,
      email,
      emailError,
      emailErrorMsg,
    } = this.state;
    const { classes } = this.props;
    return (
      <Box height={"inherit"} style={{ overflowY: "scroll", height: "100vh" }}>
        <Box width="inherit" pl={"25px"} pr={"25px"}>
          <Grid
            className={`${classes.mainGridContainer} CustomisableOrganizationInformationMainBox`}
            container
            direction="row"
          >
            <Grid item xs={12}>
              <Box
                display={"flex"}
                alignItems={"center"}
                padding={"5px"}
                justifyContent={"flex-start"}
              >
                <Typography
                  component={"p"}
                  className="PrimaryColor1 Heading7 BoldFont"
                >
                  Community Information
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box position={"relative"} width="fit-content">
                    {!this.state.profileImageSrc ? (
                      <img
                        style={{
                          height: "5rem",
                          border: "1px solid",
                          borderRadius: "22px",
                          marginBottom: "6px",
                          padding: "16px",
                        }}
                        src={profilePick}
                      />
                    ) : (
                      <Avatar
                        style={{ height: "117px", width: "117px" }}
                        className={classes.avatar}
                        src={this.state.profileImageSrc}
                      />
                    )}
                    <Box
                      position={"absolute"}
                      className={classes.cursorPointer}
                      right="0px"
                      bottom="0px"
                      bgcolor={"#fff"}
                      width={"16px"}
                      height="15px"
                      borderRadius={"50%"}
                      border={"1px solid #808080"}
                      padding={"5px"}
                    >
                      <CameraIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="#808080"
                        strokeWidth="1.0"
                        fill="none"
                      />
                      <input
                        type="file"
                        accept="image/png, image/jpg, image/jpeg,"
                        className={classes.inputBox}
                        onChange={this.uploadProfileImage}
                      />
                    </Box>
                  </Box>
                  <Box color="red" marginLeft="2px" fontStyle="smaller">
                    {this.state.profilePicErrorMsg}
                  </Box>
                </Box>
              </Grid>

              <Grid className={classes.urlPostGrid} item xs={12}>
                <FormControl
                  className={`CustomTextFieldFormControl ${classes.urlPostGridFormControl}`}
                >
                  <Typography
                    component={"span"}
                    className={`Heading6 BoldFont PrimaryColor2 ${classes.label}`}
                  >
                    Community Name*
                  </Typography>
                  <CustomTextField
                    placeHolderText="Enter Community Name"
                    fieldName="name"
                    fieldId="user-community-name"
                    fieldValue={name}
                    fieldType="text"
                    handleChange={this.handleChange}
                    fieldError={nameError}
                    helperText={""}
                    startAdornmentIcon={<img src={UserName} alt="userName" />}
                  />
                </FormControl>
              </Grid>
              <Grid style={{ marginLeft: "56px" }} item lg={3}>
                {nameError && <FormHelperText>{nameErrorMsg}</FormHelperText>}
              </Grid>
              <Grid className={classes.urlPostGrid} item xs={12}>
                <FormControl
                  className={`CustomTextFieldFormControl ${classes.urlPostGridFormControl}`}
                >
                  <Typography
                    component={"span"}
                    className={`Heading6 BoldFont PrimaryColor2 ${classes.label}`}
                  >
                    Community Website*
                  </Typography>
                  <CustomTextField
                    placeHolderText="Enter Community Website"
                    fieldName="websiteLink"
                    fieldId="user-community-websiteLink"
                    fieldValue={websiteLink}
                    fieldType="text"
                    handleChange={this.handleChange}
                    fieldError={websiteLinkError}
                    helperText={""}
                    startAdornmentIcon={<img src={WebsiteIcon} alt="global" />}
                  />
                </FormControl>
              </Grid>
              <Grid style={{ marginLeft: "56px" }} item lg={3}>
                {websiteLinkError && (
                  <FormHelperText>{websiteLinkErrorMsg}</FormHelperText>
                )}
              </Grid>
              <Grid className={classes.urlPostGrid} item xs={12}>
                <FormControl
                  className={`CustomTextFieldFormControl ${classes.urlPostGridFormControl}`}
                >
                  <Typography
                    component={"span"}
                    className={`Heading6 BoldFont PrimaryColor2 ${classes.label}`}
                  >
                    Community E-mail ID*
                  </Typography>
                  <CustomTextField
                    placeHolderText="Enter your Community E-mail ID"
                    fieldName="email"
                    fieldId="user-community-Email"
                    fieldValue={email}
                    fieldType="text"
                    handleChange={this.handleChange}
                    fieldError={emailError}
                    helperText={""}
                    startAdornmentIcon={<img src={Email} alt="email" />}
                  />
                </FormControl>
              </Grid>
              <Grid style={{ marginLeft: "56px" }} item lg={3}>
                {emailError && <FormHelperText>{emailErrorMsg}</FormHelperText>}
              </Grid>
              <Grid className={classes.urlPostGrid} item xs={12}>
                <FormControl
                  className={`CustomTextFieldFormControl ${classes.urlPostGridFormControl}`}
                >
                  <Typography
                    component={"span"}
                    className={`Heading6 BoldFont PrimaryColor2 ${classes.label}`}
                  >
                    Phone Number
                  </Typography>
                  <CustomTextField
                    placeHolderText="Enter your Phone Number"
                    fieldName="phoneNumber"
                    fieldId="user-community-phone"
                    fieldValue={phoneNumber}
                    fieldType="number"
                    handleChange={this.handleChange}
                    fieldError={phoneNumberError}
                    helperText={""}
                    startAdornmentIcon={<img src={PhoneIcon} alt="phone" />}
                  />
                </FormControl>
              </Grid>
              <Grid style={{ marginLeft: "56px" }} item lg={3}>
                {phoneNumberError && (
                  <FormHelperText>{phoneNumberErrorMsg}</FormHelperText>
                )}
              </Grid>
              <Grid className={classes.urlPostGrid} item xs={12}>
                <FormControl
                  className={`CustomTextFieldFormControl ${classes.urlPostGridFormControl}`}
                >
                  <Typography
                    component={"span"}
                    className={`Heading6 BoldFont PrimaryColor2 ${classes.label}`}
                  >
                    Community Description
                  </Typography>
                  <CustomTextField
                    placeHolderText="Add Community Description here"
                    fieldName="address"
                    fieldId="user-community-description"
                    fieldValue={address}
                    fieldType="text"
                    handleChange={this.handleChange}
                    fieldError={addressError}
                    helperText={""}
                    startAdornmentIcon={
                      <img src={DescriptionIcon} alt="description" />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid style={{ marginLeft: "56px" }} item lg={3}>
                {addressError && (
                  <FormHelperText>{addressErrorMsg}</FormHelperText>
                )}
              </Grid>
              <Grid className={classes.urlPostGrid} item xs={12}>
                <CustomButton
                  btnText="Done"
                  buttonId="user-profile-done-btn"
                  btnStyle={webStyle.DoneBtn}
                  type={"submit"}
                  handleButtonClick={this.handleSubmitForm}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  DoneBtn: {
    color: "#fff",
    background: "#01548a",

    borderRadius: "0px",
    width: "45%",
    padding: "18px",
  },
};
export default withStyles(Poststyles, { withTheme: true })(CreateCommunity);
// Customizable Area End
