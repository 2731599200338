// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const ArrowBottomIcon: any = (props: any) => {
    return (
        <SvgIcon fontSize="small" {...props}>
            <path d="M12 6H14.67C17.98 6 19.34 8.35 17.68 11.22L16.34 13.53L15 15.84C13.34 18.71 10.63 18.71 8.97 15.84L7.63 13.53L6.29 11.22C4.66 8.35 6.01 6 9.33 6H12Z" strokeWidth={props.strokeWidth} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
        </SvgIcon>
    );
}

export default ArrowBottomIcon;
// Customizable Area End