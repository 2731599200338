import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  openModal: boolean;
  handleClose: any;
  handleFinalPostOpen: any;
  event: any;
  handleChange: any;
  eventErrorMessage: any;
  eventDescModal: any;
  handleDescModal: any;
  eventError: any;
  handleDescClose: any;
  timezone: any;
  handleChangeDropdownValue: any;
  MenuProps: any;
  renderValueTimezone: any;
  timezoneError: any;
  timezoneList: any;
  timezoneErrorMsg: any;
  handleRadioSelect: any;
  radioSelect: any;
  dateStart: any;
  handleKeyPress: any;
  handleDateStartChange: any;
  dateStartError: any;
  dateStartErrorMsg: any;
  dateEnd: any;
  handleDateEndChange: any;
  dateEndError: any;
  dateEndErrorMsg: any;
  street: any;
  streetError: any;
  streetErrorMsg: any;
  city: any;
  cityError: any;
  cityErrorMsg: any;
  state: any;
  stateError: any;
  stateErrorMsg: any;
  zipcode: any;
  zipcodeError: any;
  zipcodeErrorMsg: any;
  eventUrlErrorMessage: any;
  eventUrlError: any;
  eventUrl: any;
  handleImageChange: any;
  image: any;
  imageWidth: any;
  imageError: any;
  imageErrorMessage: any;
  startTime: any;
  handleChangeDropdownStartTImeValue: any;
  renderValueStartTimezone: any;
  timezoneStartTimeError: any;
  timezoneStartTimeList: any;
  endTime: any;
  handleChangeDropdownEndTimeValue: any;
  renderValueEndTimezone: any;
  timezoneEndError: any;
  timezoneEndTimeList: any;
  eventDesc: any;
  eventDescError: any;
  eventErrorDescMessage: any;
  handleSubmit: any;
  radioSelectError: any;
  radioSelectErrorMsg: any;
  imageErrorMsg: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
  eventDetails: any;
  startDate: any;
  endDate: any;
  startTime: string | null;
  endTime: string | null;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
/*istanbul ignore next */
export default class CreateEventStepOneController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiCreatePostCallId: any
    constructor(props: Props) {
        super(props);

        this.state = {
        eventDetails:null,
        startDate:null,
        endDate:null,
        startTime:null,
        endTime:null
        }
    }  
    




    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }

}

// Customizable Area End