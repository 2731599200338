// Customizable Area Start
import React from "react";
import { Typography, Box, Grid, Button } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CreatePostFinalModalController, { Props } from "./CreatePostFinalModalController.web";
import { PostUser } from "./assets";
import CloseIcon from "./SVGIcons/CloseIcon.web";
import GlobalIcon from "./SVGIcons/GlobalIcon.web";
import ArrowBottomIcon from "./SVGIcons/ArrowBottomIcon.web";
// Customizable Area End

// Customizable Area Start
const Poststyles = (theme: Theme) =>
    createStyles({
        title: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            display: 'none',
        },
        line: {
            color: "lightgrey",
            fontSize: "40px",

        },
        create: {
            height: "30px",
            padding: "10px",
            width: "100%",
            border: 'none', "& fieldset": { border: 'none' },
            borderRadius: "10px",
            background: "#F4F4F4",
        },
        userCard: {
            border: "1px solid grey",
            width: "50%",
            margin: "0 auto",
            textAlign: "center",
            boxShadow: "none",
        },
        userCommunity: {
            border: "1px solid grey",
            width: "50%",
            margin: "0 auto",
            background: "transparent",
            boxShadow: "none",
        },
        media: {
            margin: "0 auto",
            width: "100px",
            height: "100px",
        },
        mediaPost: {
            height: "300px",
            maxWidth: "100%",
        },

        Icons: {
            justifyContent: "space-around",
            display: "flex",
        },
        iconBtn: {
            '&:hover': {
                borderRadius: "0px"
            },
        },

        modal: {
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            padding: "10px",
            borderRadius: "12px",
        },
        postModal: {
            boxShadow: "none",
            width:"100%"
        },
        postLinkModal: {
            boxShadow: "none",
            border: "1px solid #000"
        },
        description: {
            outline: "none",

            borderRadius: "10px",

        },
        postUserDescription: {
            borderTop: "2px solid lightgrey",
            borderBottom: "2px solid lightgrey",
            padding: "10px 10px"
        },
        PostFinalPublishBtn: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems:"center",
            padding:"8px 16px"
        },
        imageUploads: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid grey"
        },
        postBox:{
            maxHeight:"350px",
            overflow:"auto"
        },
        cursor:{
            cursor:"pointer"
        },
        publicTypo:{
            padding:"0px 4px"
        },
        topDiv:{
            padding:"18px 22px 8px 22px"
        },
        postTextBlock:{
            background: "#e8e8e8", 
            padding: "21px 18px", 
            borderRadius: "10px"
        },
        avatar:{
            width:"50px",
             height:"50px",
             objectFit:"contain"
         },
        avatarImg:{
            width:"inherit",
            height:"inherit"
        }
    })
// Customizable Area End
/*istanbul ignore next */

 export class CreatePostFinalModal extends CreatePostFinalModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
     render() {
         const { classes , userFullName , profileImageSrc , finalPost , postText, uploadedImage, isLoading } = this.props
         console.log("props.postText", postText )
         return (
             <Modal
                 aria-labelledby="transition-modal-title"
                 aria-describedby="transition-modal-description"
                 className={`CreatePostModals ${classes.modal}`}
                 open={finalPost}
                 onClose={this.handleClose}
                 closeAfterTransition
                 BackdropComponent={Backdrop}
                 BackdropProps={{
                     timeout: 200,
                 }}
            > 
                 <Fade in={finalPost}>
                     <Box className={`${classes.paper} CreatePostModalMainBox`}>
                        <Card className={classes.postModal}>
                             <Grid container direction="column">
                                 <Grid item xs={12} className={classes.topDiv}>
                                     <Box display="flex" justifyContent="space-between" mb={2}>
                                     <Typography variant="body2" className={`Heading4 BoldFont FontColor5`}>Create Post</Typography>
                                         <CloseIcon className={`CursorPointer`} onClick={this.handleClose} width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="1.5" fill="none" />
                                     </Box>
                                 </Grid>
                                  <Grid item xs={12} className={classes.postUserDescription}>
                                     <Box>
                                         <CardHeader
                                             avatar={
                                                 <Avatar aria-label="recipe" className={classes.avatar}>
                                                     {profileImageSrc ? <img className={classes.avatarImg} src={profileImageSrc} alt="" /> : <img src={PostUser} alt="" />}
                                                 </Avatar>
                                             }
                                             title={
                                                <Typography variant="body2" className={`Heading4 BoldFont TextCapitalize`} >{userFullName}</Typography>
                                            }
                                            subheader={
                                                <Box display={"flex"} >
                                                    <GlobalIcon width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="1.5" fill="none" /> 
                                                    <Typography component="span" className={`Heading6 FontColor2 ${classes.publicTypo}`} >
                                                        Public</Typography>
                                                    <ArrowBottomIcon width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="1.5" fill="none"/>
                                                </Box>
                                            }
                                        />
                                    </Box>
                                    <Box className={classes.postBox} pt={"5px"} pb={"5px"} display="flex" flexDirection="column" gridGap="10px" >
                                         {postText &&
                                             <Box className={`${classes.postTextBlock}`}>
                                                <Typography>
                                                    {postText}
                                                 </Typography>
                                            </Box>
                                        }
                                    
                                        {this.props.editedImage?<>
                                            {
                                            uploadedImage &&
                                            <Box>
                                                <img src={uploadedImage} style={{ width: '100%', height: "225px"}} />
                                            </Box>
                                        }
                                        </>:<>
                                        {
                                            uploadedImage &&
                                            <Box>
                                                <img src={uploadedImage ? URL.createObjectURL(uploadedImage) : ""} style={{ maxWidth: '100%', maxHeight: "225px"}} />
                                            </Box>
                                        }
                                        </>}
                                       
                                    </Box>
                                 </Grid>
                                 <Grid item xs={12} alignItems="center">
                                        <Box className={classes.PostFinalPublishBtn}>
                                            <Button disabled={isLoading} variant="contained" style={webPage.Postbtn} color="primary" onClick={this.props.handleSubmit} type="submit">Done</Button>
                                        </Box>
                                 </Grid>
                             </Grid>
                         </Card>
                     </Box>
                 </Fade>
            </Modal>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
const webPage = {
    IconColor: {
        color: "#5B7389"
    },
    imageBtn: {
        borderBottom: "2px solid #01548a",
        borderRadius: "0px",
    },
    Postbtn: {
        background: "#01548a",
        color: "#fff",
        borderRadius:"0px",
        width:"165px",
        padding:"8px 0px"
    },
    Publishbtn: {
        background: "#01548a",
        color: "#fff",
        width: "100%"
    },
    label: {
        color: "#01548a",
        fontSize: "14px",
        fontWeight: "bold",
        marginBottom: "10px",
        
    } as const
}


export default withStyles(Poststyles, { withTheme: true })(CreatePostFinalModal);
// Customizable Area End