// Customizable Area Start
import React from 'react';
import { SideImage, Logo } from "./assets";
import {
    Box,
    Button,
    Typography,
    Grid,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import TermsAndConditionController, {Props} from './TermsAndConditionsNewController.web';
import './TermsAndConditionsNew.web.css'
// Customizable Area End
// Customizable Area Start
const webStyle = {
 
    logoCard: {
        width: "200px",
        height: "200px",
        boxShadow: "-1px 3px 13px -1px rgba(0,0,0,0.75)",
        borderRadius: "50%"
    },
    TermsBio:{
        textAlign:"start"
    }as const,
    Terms: {
        color: "#01548A",
        fontSize: "25px",
        fontWeight: "bold",
        textAlign:"center",
        fontFamily:"Verdana, Geneva, Tahoma, sans-serif"
    } as const,
    TermsDescription:{ 
        fontSize: "16px", 
        marginTop:"10px",
        fontFamily:"Verdana, Geneva, Tahoma, sans-serif"
    },
    Accept: {
        paddingLeft: "42%",
        paddingRight: "42%",
        textTransform: "none",
        backgroundColor: "#01548A",
        color: "#fff",
        fontWeight: "bold",
        borderRadius: "6px",
        marginTop: "5%",
        marginBottom: "5%",
        fontFamily:"Verdana, Geneva, Tahoma, sans-serif"
    } as const,
    OpacityAccept: {
        paddingLeft: "42%",
        paddingRight: "42%",
        textTransform: "none",
        backgroundColor: "#01548A",
        color: "#fff",
        fontWeight: "bold",
        borderRadius: "6px",
        marginTop: "5%",
        marginBottom: "5%",
        fontFamily:"Verdana, Geneva, Tahoma, sans-serif",
        opacity: "0.3"
    } as const,
}
// Customizable Area End

export class TermsAndConditionsNew extends TermsAndConditionController {
     // Customizable Area Start
    constructor(props: Props) {

        super(props);
       
        
    }
    // Customizable Area End
    // Customizable Area Start
    render() {
        return (
      <Box className='TermsConditionMainBox'>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <img src={SideImage} alt="side-img-signUp" style={{ width: "100%", height: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <Grid item>
                                <Box style={webStyle.logoCard}>
                                    <img src={Logo} alt="logo-img" style={{ padding: 0, width: "200px", height: "200px" }} />
                                </Box>
                            </Grid>
                            <Grid item style={{ width: "50%", marginTop: "20px" }}>

                                <Box>
                                    <Typography variant='h4' style={webStyle.Terms}>Terms & Conditions</Typography>
                                </Box>
                                <Box style={webStyle.TermsBio}>
                                    <Typography variant='body2' style={webStyle.TermsDescription}> &nbsp; &nbsp; &nbsp;1.By accessing this app you are agreeing to be bound by these website Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this website. This materials contained in this website are protected by applicable copyright and trade mark law.</Typography>
                                    <Typography variant='body2' style={webStyle.TermsDescription}> &nbsp; &nbsp; &nbsp;2.By accessing this app you are agreeing to be bound by these website Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this website. This materials contained in this website are protected by applicable copyright and trade mark law.</Typography>
                                    <Typography variant='body2' style={webStyle.TermsDescription}> &nbsp; &nbsp; &nbsp; 3.By accessing this app you are agreeing to be bound by these website Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this website. This materials contained in this website are protected by applicable copyright and trade mark law.</Typography>
                                    <FormControlLabel
                                        control={<Checkbox
                                            color="primary"
                                            checked={this.props.agree}
                                            data-test-id={"btnRememberMe"}
                                            
                                            onChange={this.props.setAgree}
                                            name="agree" />}
                                        label="I Accept the Terms and Conditions"
                                    />
                                    <Button type="submit" variant="contained"
                                    data-test-id="btnSignUp"
                                    disabled={!this.props.agree}
                                    style={this.props.agree?webStyle.Accept:webStyle.OpacityAccept} onClick={this.props.handleAccept}>
                                        Accept
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

      
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default TermsAndConditionsNew
// Customizable Area End