// Customizable Area Start
import React from "react";
import { Select , MenuItem , Checkbox , ListItemText , Input , InputAdornment , Box , Typography} from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomMultipleSelectDropdownController, { Props } from "./CustomMultipleSelectDropdownController.web";
import CheckboxUnCheckIcon from "./SVGIcons/CheckboxUnCheckIcon.web";
import CheckboxCheckIcon from "./SVGIcons/CheckboxCheckIcon.web";
import CustomButton from "./CustomButton.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
    createStyles({
        textField:{
            borderRadius: "12px",
            border: "solid 2px #f4f4f4",
            padding: "18px 0px",
        },
        textfieldInput:{
          fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
          fontWeight: "normal",
          paddingLeft: "5px",
        },
        errorBorder:{
          border: "1px solid red !important",
        },
        inputAdornment:{
          paddingLeft:"15px"
        },
        textFieldFont:{
            fontSize: "16px" 
        },
        userProfileSelectBootstrapInput: {
            borderRadius: "12px",
            border: "solid 2px #f4f4f4",
            backgroundColor: "#fff",
            padding: "12px 15px",
            fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
            fontSize: "16px",
            cursor:"none !important"
          },
          notSelectedMenuitem:{
            color:"#005487"
          },
          selectedMenuitem:{
            color:"#fff",
            backgroundColor:"#005487 !important"
          },
          cursorPointer:{
            cursor:"pointer"
          },
          selectAllText:{
          fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
          fontSize:"14px",
          color:"#808080"
          }
    })

// Customizable Area End

export class CustomMultipleSelectDropdown extends CustomMultipleSelectDropdownController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes , fieldName, fieldError , fieldValue , fieldId , fieldMenuItems, startAdornmentIcon , MenuProps , iconComponent } = this.props
        const applyClass :any = (item : any) => {
          if(fieldValue.indexOf(item.value) > -1) return `${classes.selectedMenuitem}`;
          else return `${classes.notSelectedMenuitem}`
        }
        return (
            <>
            <Select
            id={fieldId}
            value={fieldValue}
            style={fieldValue.length>0?webStyle.black:webStyle.darkGrey}
            name={fieldName}
            className={fieldError ?`${classes.userProfileSelectBootstrapInput} ${classes.errorBorder}` : `${classes.userProfileSelectBootstrapInput}`}
            onChange={this.props.handleChange}
            fullWidth
            MenuProps={MenuProps}
            error={fieldError}
            renderValue={()=>fieldValue.length>0?fieldValue.filter(item=>item).join(', '):this.props.placeHolderText}
            disableUnderline
            displayEmpty
            multiple
            open={this.state.openMenu}
            input={<Input id={`${fieldName}-my-select`} startAdornment={
              <InputAdornment position="start">
                {startAdornmentIcon}
              </InputAdornment>
            } />}
            native={false}
            IconComponent={() => (
              <InputAdornment onClick={this.handleToggleMenu} className={classes.cursorPointer} position="start">
               {iconComponent}
              </InputAdornment>
            )}
          >
            {fieldMenuItems.map((item:any) => (
              <MenuItem className={applyClass(item)} key={`${item.value}`} value={item.value}>
                <ListItemText primary={item.label} />
                <Checkbox icon={<CheckboxUnCheckIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#005487"} strokeWidth="1.5" fill={"none"} />}
                checkedIcon={<CheckboxCheckIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#fff"} strokeWidth="1.5" fill={"#005487"} />} checked={(fieldValue.indexOf(item.value) > -1)} />
              </MenuItem>
            ))}
             <Box borderTop={"1px solid #808080"} display={"flex"} justifyContent="flex-start" alignItems={"center"} p={"2%"} mt="10px">
              <Typography component={"span"} className={`${classes.selectAllText}`}>Select all that apply</Typography>
             </Box>
             <Box display={"flex"} justifyContent="flex-end" alignItems={"center"} mt="10px" >
                <CustomButton type={"button"} buttonId="select-dropdown-btn" handleButtonClick={this.handleToggleMenu} btnStyle={webStyle.DoneBtn} btnText="Done" />
              </Box>
          </Select>
          </>
        );
        // Customizable Area End
    }

}


// Customizable Area Start
const webStyle = {
  DoneBtn: {
    background: "#01548a",
    color: "#fff",
    width: "140px",
    borderRadius:"0px",
    padding:"18px"
 },
 darkGrey:{
  color: "darkgrey"
 },
 black: {
  color: "black"
 }
}
export default withStyles(styles, { withTheme: true })(CustomMultipleSelectDropdown);
// Customizable Area End