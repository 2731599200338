// Customizable Area Start
import React from "react";
import { Grid , Typography , Box} from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import LearnEmbeddedModalController, { Props , VIDEO_TYPES } from "./LearnEmbeddedModalController.web";
import "./VideoTypePost.web.css";
import CloseIcon from "./SVGIcons/CloseIcon.web";
// Customizable Area End

// Customizable Area Start
const Poststyles = (theme: Theme) =>
    createStyles({
        title: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            display: 'none',
        },
        menuIcon:{
            width: "24px",
            height: "24px",
            objectFit: "contain",
        },
        inputAdornment:{
            padding:"0px 8px"
        }
    })
// Customizable Area End


export class LearnEmbeddedModal extends LearnEmbeddedModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const {  open , videoEmbedType} = this.props
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={"VideoPostModal VideoStepTwoModal"}
                style={{height:"40rem"}}
                open={open}
                onClose={this.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={open}>
                    <Box className="VideoStepTwoModalBox" style={{height:"85%"}}>
                        <Grid container className={`VideoStepTwoModalMainGrid`} style={{overflow:"auto",maxHeight:"40rem"}}>
                            <Grid className={`VideoStepTwoModalMainGridFirstItem`} item xs={12}>
                                    <Grid container direction="row">

                                   

                                        <Grid item xs={8} sm={10} md={11}>

                                        <Box display="flex" justifyContent="space-between" mb={2}>
                                        <Typography
                                            className={`Heading4 ModalHeading`}
                                            component="span"
                                            style={{color:"#005487"}}
                                            >
                                            {videoEmbedType===VIDEO_TYPES.Vimeo ? "Vimeo Embed" : "Youtube Embed"}
                                            </Typography>
                                        <CloseIcon style={{marginRight:"-3rem"}} className={`CursorPointer`} onClick={this.props.onClose} width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="1.5" fill="none" />
                                    </Box>
                                         
                                        </Grid>
                                    </Grid>
                            </Grid>
                            <Grid style={{marginBottom:"4rem"}} className={`VideoStepTwoModalMainGridSecondItem`} item xs={12}>
                                    <Grid container direction="row">
                                    <Grid item xs={12}>
                                            <Typography
                                            className={`ButtonText EmbedCode BoldFont`}
                                            component="span"
                                              style={{color:"black"}}
                                            >
                                            Embed
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                              <Typography style={{fontSize:"smaller"}}>
                                              Embed accessing this app you are agreeing to be bound by these app Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable.<br></br><br></br>
If you do not agree with any of these terms, you are prohibited from using or accessing this application. This materials contained in this application are protected by applicable copyright and trade mark law.<br></br><br></br>
By accessing this app you are agreeing to be bound by these app Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this application. This materials contained in this application are protected by applicable copyright and trade mark law.<br></br><br></br>
By accessing this app you are agreeing to be bound by these app Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this application.<br></br><br></br>
This materials contained in this application are protected by applicable copyright and trade mark law.By accessing this app you are agreeing to be bound by these app Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws.
                                              </Typography>
                                    </Grid>
                            </Grid>
                         
                        </Grid>
                    </Box>
                </Fade>
            </Modal >
        )
    }
    // Customizable Area End
}

// Customizable Area Start

export default withStyles(Poststyles, { withTheme: true })(LearnEmbeddedModal);
// Customizable Area End