// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const CalendarIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M8 2V5" fill={props.fill} strokeWidth={props.strokeWidth} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 2V5" fill="none" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.5 9.09H20.5" fill="none" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" fill="none" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.6947 13.7H15.7037" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.6947 16.7H15.7037" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.9955 13.7H12.0045" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.9955 16.7H12.0045" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.29431 13.7H8.30329" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.29431 16.7H8.30329" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
}

export default CalendarIcon;
// Customizable Area End