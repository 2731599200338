import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities";
import { getNotificationPermission } from "../../../components/src/firebase";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
export const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  classes: any;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  password: string;
  checkedRememberMe: boolean;
  emailError: string;
  passwordError: string;
  isLoading: boolean;
  showPassTwo: boolean;
  status: any;
  FieldError: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
  // Customizable Area Start

  // Customizable Area End
export default class NewLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: any = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.state = {
      email: "",
      password: "",
      checkedRememberMe: false,
      // errors: {
      emailError: "",
      passwordError: "",
      isLoading: false,
      status: "",
      showPassTwo: false,
      FieldError: "",
      // },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    let username = this.getCookie("myusrname");
    let psed = this.getCookie("mypswd");
    if (username != null && psed != null) {
      this.setState({
        email: username,
        password: psed,
        checkedRememberMe: true,
      });
    }

    let userData = await localStorage.getItem("LoggedInUserData");

    if (userData) {
      this.props.navigation.navigate("LandingScreen");
    }
  }

  getCookie(name: string) {
    const cookieName = `${name}=`;
    const cookies = document.cookie.split(";");

    for (const element of cookies) {
      let cookie = element.trim();
      if (cookie.startsWith(cookieName)) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
  }

  clearCookie(name: string, path: string) {
    // Set the cookie to expire in the past
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}`;
  }

  handleSignUp = () => {
    this.props.navigation.navigate("SignIn");
  };
  handleChange = (event: any) => {
    const { name, value } = event.target;

    if (name === "email") {
      this.setState({
        emailError: "",
      });
    }
    if (name === "password") {
      this.setState({
        passwordError: "",
      });
    }

    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  handleChangeCheck = (event: any) => {
    this.setState({ checkedRememberMe: event.target.checked });

    if (this.state.checkedRememberMe) {
      this.clearCookie("myusrname", "/Login");
      this.clearCookie("mypswd", "/Login");
    }
  };

  setRememberMe = (value: any) => {
    this.setState({ checkedRememberMe: value });
  };

  forgotPassword = () => {
    this.props.navigation.navigate("phoneVerification");
  };

  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    console.log("apiRequestCallId", apiRequestCallId);

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.apiEmailLoginCallId) {
        if (!responseJson.errors) {
          this.setState({ status: responseJson });
          setStorageData("LoginSuccessToken", responseJson.meta.token);
          localStorage.setItem("SelectedScreenId", "1");

          localStorage.setItem("community", "false");
          localStorage.setItem("privatePost", "");
          localStorage.setItem("communityData", "");
          if (responseJson.meta.can_skip_verfication) {
            this.props.navigation.navigate("LandingScreen");
          } else {
            this.props.navigation.navigate("PhoneVerificationLoginFlow");
          }
        } else {
          console.log(responseJson.errors);
          this.setState({ FieldError: responseJson.errors[0]?.failed_login });
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
  }
  handleForgotPassNavigation = () => {
    this.props.navigation.navigate("ForgotPasswordPhoneVerification");
  };
  EyeChangeTwo = () => {
    this.setState({ showPassTwo: !this.state.showPassTwo });
  };

  handleSubmit = async (e: any) => {
    if (this.state.checkedRememberMe) {
      document.cookie = "myusrname=" + this.state.email + ";path=/Login";
      document.cookie = "mypswd=" + this.state.password + ";path=/Login";
    }
    e.preventDefault();
    const { email, password } = this.state;

    if (!validEmailRegex.test(this.state.email)) {
      this.setState({
        emailError: configJSON.errorEmailNotValid,
      });

      this.setState({
        FieldError: "",
      });
    }
    if (!email) {
      this.setState({
        emailError: configJSON.EmailRequired,
      });
      this.setState({
        FieldError: "",
      });
    }
    if (!password) {
      this.setState({
        passwordError: configJSON.PasswordRequired,
      });

      this.setState({
        FieldError: "",
      });
    }

    this.setState({ isLoading: true });

    if (
      email !== "" &&
      password !== "" &&
      validEmailRegex.test(this.state.email)
    ) {
      this.setState({
        emailError: "",
      });

      this.setState({
        passwordError: "",
      });

      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };
      const deviceId = await getNotificationPermission();
      const attrs = {
        email: this.state.email,
        password: this.state.password,
        device_id: deviceId ?? "",
      };

      const data = {
        type: "email_account",
        attributes: attrs,
      };

      const httpBody = {
        data: data,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiEmailLoginCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginRegisterAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }
  };
  // Customizable Area End
}