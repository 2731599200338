// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const GlobalIcon: any = (props: any) => {
    return (
        <SvgIcon fontSize="small" {...props}>
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path d="M8 3H9C7.05 8.84 7.05 15.16 9 21H8" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path d="M15 3C16.95 8.84 16.95 15.16 15 21" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path d="M3 8.99998C8.84 7.04998 15.16 7.04998 21 8.99998" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" fill="none" />
        </SvgIcon>
    );
}

export default GlobalIcon;
// Customizable Area End