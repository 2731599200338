import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import {
  setStorageData,
  getStorageData,
} from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
const validPasswordRegex = RegExp(
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]|\\:;"'<,>.?/~`])(?!.*\s).{8,}$/
);
// Customizable Area End

// Customizable Area Start
/*istanbul ignore next */
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onComplete: (otp: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  full_phone_number: any;
  show: boolean;
  email_verify: boolean;
  otp: string[];
  error: boolean;
  FieldError: any;
  errorMsg: string;
  otpError: string;
  email: string;
  emailError: any;
  successMessage: any;
  otpTimer: any;
  isDisabled:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

/*istanbul ignore next */
export default class ForgotPasswordPhoneVerificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiPhoneId: any;
  apiVerifyotpCallId: any;
  createAccountApiEmailId: any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.verifyOtp = this.verifyOtp.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.state = {
      full_phone_number: "",
      errorMsg: "",
      show: false,
      otp: ["", "", "", ""],
      error: false,
      FieldError: "",
      otpError: "",
      email_verify: false,
      email: "",
      emailError: "",

      successMessage: "",
      otpTimer: 60,
      isDisabled:false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  handleChange = (e: any) => {
    const regex = /^[0-9\b]+$/;

    this.setState({ errorMsg: "" });
    this.setState({ FieldError: "" });
    if (e.target.value === "" || regex.test(e.target.value)) {
      this.setState({ full_phone_number: e.target.value });
    }
  };
  /*istanbul ignore next */
  handleChangeEmail = (e: any) => {
    this.setState({ email: e.target.value, FieldError: "", emailError: "" });
  };
  /*istanbul ignore next */
  handleLogin = () => {
    this.props.navigation.navigate("LogIn");
  };
  handleChangeGoToEmail = () => {
    this.setState({ email_verify: true, show: true });
  };
  handleChangeGoToPhone = () => {
    this.setState({ email_verify: false, show: false });
  };

  handleOTPChange = (e: any, index: number) => {
    this.setState({ otpError: "", FieldError: "" });
    const otp = [...this.state.otp];
    otp[index] = e.target.value;
    this.setState({ otp });
    const inputs = document.querySelectorAll('input[type="number"]');

    if (e.target.value.length === 1) {
      const nextInput = inputs[index + 1];
      if (nextInput) {
        (nextInput as HTMLElement).focus();
      }
    }
  };
  /*istanbul ignore next */
  handleKeydown = (e: any, ind: any) => {
    const inputs = document.querySelectorAll('input[type="number"]');
    if (e.target.value.length === 0 && e.key === "Backspace") {
      const nextInput = inputs[ind - 1];
      if (nextInput) {
        (nextInput as HTMLElement).focus();
      }
    }
  };

  apicalls = (
    apiRequestCallId: any,
    responseJson: any,
    errorReponse: any,
    message: any
  ) => {
 
    if (apiRequestCallId === this.apiVerifyotpCallId) {
      if (!responseJson.errors) {
   
        const msg: Message = new Message(
          getName(MessageEnum.NavigationResetPasswordMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
        toast.success("OTP verified successfully!")
      } else {
        this.setState({
          FieldError:
            responseJson.errors[0]?.message ||
            responseJson.errors[0]?.otp ||
            responseJson.errors[0]?.pin,
        });
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
  };

  /*istanbul ignore next */
  async receive(_from: string, message: Message) {
  
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.createAccountApiPhoneId) {
      if (!responseJson.errors) {
        this.startTimer();
        localStorage.setItem("forgotPasswordToken", responseJson?.meta?.token);
        this.setState({ show: true, errorMsg: "" });
      } else {
        this.parseApiErrorResponse(errorReponse);

        this.setState({
          FieldError:
            responseJson.errors[0]?.phone ||
            responseJson.errors[0]?.password ||
            responseJson.errors[0]?.otp ||
            responseJson.errors[0]?.full_phone_number,
        });
      }
    }

    if (apiRequestCallId === this.createAccountApiEmailId) {
      if (!responseJson.errors) {
        toast.success("Reset password link has been sent into your email");
        setStorageData("mobiletoken", responseJson?.meta?.token);
      } else {
        this.parseApiErrorResponse(errorReponse);

        this.setState({ FieldError: responseJson.errors[0]?.otp });
      }
    }

    this.apicalls(apiRequestCallId, responseJson, errorReponse, message);


  }


  handleEmailSubmit = (e: any) => {
    e.preventDefault();
    if (!this.state.email) {
      this.setState({ emailError: "Please Enter Email" });
    }

    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const isValidEmail = validEmailRegex.test(this.state.email);

    if (!isValidEmail) {
      this.setState({ emailError: "Please Enter Valid Email" });
    }

    if (this.state.email) {
      const header = {};
      const formData = new FormData();

      formData.append("email", this.state.email);

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createAccountApiEmailId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_forgot_password/passwords/send_resest_password_link"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };


  startTimer = () => {
    // Disable the button to prevent multiple clicks
    this.setState({ isDisabled: true });

    // Set the timer for 60 seconds
  let timerInterval = setInterval(() => {
      this.setState((prevState) => ({
       otpTimer: prevState.otpTimer - 1,
      }));

      // Check if the timer has reached 0
      if (this.state.otpTimer < 0) {
        // Enable the button and reset the timer
        this.setState({
          otpTimer: 60,
          isDisabled: false,
        });

        // Clear the interval to stop the timer
        clearInterval(timerInterval);
      }
    }, 1000);
  };



  handleSubmit = (e: any) => {
    e.preventDefault();
    this.setState({ otpError: "", FieldError: "", otpTimer: 60 });

    /*istanbul ignore next */
    if (!this.state.full_phone_number) {
      this.setState({ errorMsg: "Please Enter Phone number" });
    }

    if (this.state.full_phone_number) {
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };

      const attrs = {
        full_phone_number: this.state.full_phone_number,
      };

      const data = {
        //   type: "email_account",
        attributes: attrs,
      };

      const httpBody = {
        data: data,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createAccountApiPhoneId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.ForgotPasswordPhoneVerficationEndPointWeb
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  /*istanbul ignore next */
  verifyOtp = async (e: any) => {
    e.preventDefault();

    let forgotPasswordToken = await getStorageData("forgotPasswordToken");

    if (
      this.state.otp[0] === "" ||
      this.state.otp[1] === "" ||
      this.state.otp[2] === "" ||
      this.state.otp[3] === ""
    ) {
      this.setState({ FieldError: "" });
      this.setState({ otpError: "Enter 4 digit OTP" });
    }

    if (
      this.state.otp[0] !== "" &&
      this.state.otp[1] !== "" &&
      this.state.otp[2] !== "" &&
      this.state.otp[3] !== ""
    ) {
      this.setState({ otpError: "" });
      const header = {
        "Content-Type": "application/json",
        token: forgotPasswordToken,
      };
      const data = {
        data: {
          token: forgotPasswordToken,
          otp_code: this.state.otp.join(""),
        },
      };

      const httpBody = data;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiVerifyotpCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.ForgotPasswordphoneOTPEndpointWeb
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        // configJSON.loginAPiMethod,
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }
  };

  // Customizable Area End
}
