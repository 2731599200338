// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const FolderCloudIcon: any = (props: any) => {
    return (
        <SvgIcon style={{fontSize:"2.5rem"}} {...props}>
            <path d="M9 22H7C3 22 2 21 2 17V7C2 3 3 2 7 2H8.5C10 2 10.33 2.44001 10.9 3.20001L12.4 5.20001C12.78 5.70001 13 6 14 6H17C21 6 22 7 22 11V13" strokeWidth={props.strokeWidth} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path d="M13.76 18.32C11.41 18.49 11.41 21.89 13.76 22.06H19.32C19.99 22.06 20.65 21.81 21.14 21.36C22.79 19.92 21.91 17.04 19.74 16.77C18.96 12.08 12.18 13.86 13.78 18.33" strokeWidth={props.strokeWidth} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none" />
        </SvgIcon>
    );
}

export default FolderCloudIcon;
// Customizable Area End