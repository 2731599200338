// Customizable Area Start
import React from "react";
import { Typography, IconButton, Box, Grid, Button, FormControl , FormHelperText } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CreatePostMainModalController, { Props } from "./CreatePostMainModalController.web";
import { PostUser } from "./assets";
import "./CreatePostNew.web.css"
import CloseIcon from "./SVGIcons/CloseIcon.web";
import GlobalIcon from "./SVGIcons/GlobalIcon.web";
import ArrowBottomIcon from "./SVGIcons/ArrowBottomIcon.web";
import GalleryIcon from "./SVGIcons/GalleryIcon.web";
import VideoIcon from "./SVGIcons/VideoIcon.web";
import EventIcon from "./SVGIcons/EventIcon.web";
import EmbedIcon from "./SVGIcons/EmbedIcon.web";
// Customizable Area End

// Customizable Area Start
const Poststyles = (theme: Theme) =>
    createStyles({
        title: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            display: 'none',
        },
        line: {
            color: "lightgrey",
            fontSize: "40px",

        },
        create: {
            height: "30px",
            padding: "10px",
            width: "100%",
            border: 'none', "& fieldset": { border: 'none' },
            borderRadius: "10px",
            background: "#F4F4F4",
        },
        Icons: {
            justifyContent: "space-around",
            display: "flex",
        },
        iconBtn: {
            '&:hover': {
                borderRadius: "0px"
            },
        },
        modal: {
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            padding: "10px",
            borderRadius: "10px",
        },
        postModal: {
            boxShadow: "none",
            width:"100%"
        },
        postUserDescription: {
            borderTop: "2px solid lightgrey",
            borderBottom: "2px solid lightgrey",
            padding: "10px 10px"
        },
        PostIcons: {
            display: "flex",
            justifyContent: "space-between",
            marginTop:"7px"
        },
        publicTypo:{
            padding:"0px 4px"
        },
        topDiv:{
            padding:"12px 22px 8px 22px"
        },
        avatar:{
            width:"50px",
            height:"50px",
            objectFit:"contain"
        },
        avatarImg:{
            width:"inherit",
            height:"inherit"
        }
    })
// Customizable Area End

/*istanbul ignore next */
export class CreatePostMainModal extends CreatePostMainModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { classes , openModal , userFullName , profileImageSrc , createPost , createPostError, createPostErrorMsg, isLoading } = this.props
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={`CreatePostModals ${classes.modal}`}
                open={openModal}
                onClose={this.handleModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={openModal}>
                    <Box className={`${classes.paper} CreatePostModalMainBox`} >
                        <Card className={classes.postModal}>
                            <Grid container direction="column">
                            <Grid item xs={12} className={classes.topDiv}>
                                    <Box display="flex" justifyContent="space-between" mb={2}>
                                    <Typography variant="body2" className={`Heading4 BoldFont FontColor5`}>Create Post</Typography>
                                        <CloseIcon className={`CursorPointer`} onClick={this.handleModalClose} width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="1.5" fill="none" />
                                    </Box>
                                </Grid>
                                <Grid xs={12} item className={classes.postUserDescription}>
                                    <Box>
                                        <CardHeader
                                            avatar={
                                                this.state.communityFlag=="false"?
                                                
                                                <Avatar aria-label="recipe" className={classes.avatar}>
                                                    {profileImageSrc ? <img className={classes.avatarImg} src={profileImageSrc} alt="" /> : <img src={PostUser} alt="" />}
                                                </Avatar>:
                                                       
                                                       <Avatar aria-label="recipe" className={classes.avatar} style={{borderRadius:"3px"}}>
                                                       {this.state.communityData?.attributes?.profile_pic ? <img className={classes.avatarImg} src={this.state.communityData?.attributes?.profile_pic} alt="" /> : <img src={PostUser} alt="" />}
                                                   </Avatar>
                                            }
                                            title={
                                                <Typography variant="body2" className={`Heading4 BoldFont TextCapitalize`}>{ this.state.communityFlag=="false"?userFullName:this.state.communityData?.attributes?.name}</Typography>
                                            }
                                            subheader={
                                                <Box display={"flex"} >
                                                    <GlobalIcon width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="1.5" fill="none" /> 
                                                    <Typography component="span" className={`Heading6 FontColor2 ${classes.publicTypo}`} >
                                                     {this.state.userData?.is_private?"private":"public"}</Typography>
                                                    <ArrowBottomIcon width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="1.5" fill="none"/>
                                                </Box>
                                            }
                                        />
                                    </Box>
                                    <FormControl style={{ width: "100%" }}>
                                        <TextField
                                            className={`CreatePostTextfield`}
                                            data-test-id="txtInputPassword"
                                            placeholder="Create a post"
                                            size="small"
                                            type="text"
                                            name="createPost"
                                            value={createPost}
                                            onChange={this.props.handleChange}
                                            multiline
                                            InputProps={{disableUnderline:true}}
                                            fullWidth
                                            inputProps={{
                                                className: createPostError ? "ErrorBorder CreatePostTextfieldInput Heading5" : "CreatePostTextfieldInput Heading5"
                                            }}
                                            error={createPostError}
                                        />
                                        {createPostError && <FormHelperText>{createPostErrorMsg}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <CardActions className={classes.PostIcons}>
                                        <Box>
                                            <IconButton aria-label="photo" className={classes.iconBtn} onClick={!this.props.createPostMainModal? this.props.handleImageOpen:this.props.handleImageOnlyOpen}>
                                                <GalleryIcon className={`CursorPointer`} width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="1.5" fill="none" />
                                            </IconButton>

                                           { !this.props.createPostMainModal && <><IconButton aria-label="video" className={classes.iconBtn} //onClick={this.props.handleOpenVideoModalOne}
                                            >
                                                <VideoIcon className={`CursorPointer`} width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="1.5" fill="none" onClick={this.props.handleOpenVideoModalOne} />
                                            </IconButton>
                                            <IconButton aria-label="event" className={classes.iconBtn} onClick={this.props.eventModal}>
                                                <EventIcon className={`CursorPointer`} width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="0.055" fill="#808080" />
                                            </IconButton>

                                            <IconButton aria-label="link" className={classes.iconBtn} onClick={this.props.handleLinkOpen}>
                                                <EmbedIcon className={`CursorPointer`} width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="0.55" fill="#808080" />
                                            </IconButton></>}
                                        </Box>
                                        <Box>
                                            <Button style={webPage.Postbtn} disabled={isLoading} variant="contained" color="primary" type="submit" onClick={!this.props.createPostMainModal? this.props.handleSubmit:this.props.handleImageSubmit}>Publish</Button>
                                        </Box>
                                    </CardActions>
                                </Grid>
                            </Grid>
                        </Card>
                    </Box>
                </Fade>
            </Modal>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
const webPage = {
    IconColor: {
        color: "#5B7389"
    },
    imageBtn: {
        borderBottom: "2px solid #01548a",
        borderRadius: "0px",
    },
    Postbtn: {
        background: "#01548a",
        color: "#fff",
        borderRadius:"0px",
        width:"165px",
        padding:"8px 0px"
    },
    Publishbtn: {
        background: "#01548a",
        color: "#fff",
        width: "100%"
    },
    label: {
        color: "#01548a",
        fontSize: "14px",
        fontWeight: "bold",
        marginBottom: "10px"
    } as const
}


export default withStyles(Poststyles, { withTheme: true })(CreatePostMainModal);
// Customizable Area End