// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const DropdownIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.47 10.64L12 14.16L15.53 10.64" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
}

export default DropdownIcon;
// Customizable Area End