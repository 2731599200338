// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const CameraIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M6.75626 21.9995H17.2363C19.9963 21.9995 21.0963 20.3095 21.2263 18.2495L21.7463 9.98951C21.8863 7.82951 20.1663 5.99951 17.9963 5.99951C17.3863 5.99951 16.8263 5.64951 16.5463 5.10951L15.8263 3.65951C15.3663 2.74951 14.1663 1.99951 13.1463 1.99951H10.8563C9.82626 1.99951 8.62626 2.74951 8.16626 3.65951L7.44626 5.10951C7.16626 5.64951 6.60626 5.99951 5.99626 5.99951C3.82626 5.99951 2.10626 7.82951 2.24626 9.98951L2.76626 18.2495C2.88626 20.3095 3.99626 21.9995 6.75626 21.9995Z" strokeWidth={props.strokeWidth} fill="none" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 7.99951H12" strokeWidth={props.strokeWidth} fill="none" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.0039 18.0005C13.7939 18.0005 15.2539 16.5405 15.2539 14.7505C15.2539 12.9605 13.7939 11.5005 12.0039 11.5005C10.2139 11.5005 8.75391 12.9605 8.75391 14.7505C8.75391 16.5405 10.2139 18.0005 12.0039 18.0005Z" strokeWidth={props.strokeWidth} fill="none" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
}

export default CameraIcon;
// Customizable Area End