// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const LinkOpenIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M10.8333 9.16665L17.6667 2.33331" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.3333 5.66669V1.66669H14.3333" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.16666 1.66669H7.5C3.33333 1.66669 1.66666 3.33335 1.66666 7.50002V12.5C1.66666 16.6667 3.33333 18.3334 7.5 18.3334H12.5C16.6667 18.3334 18.3333 16.6667 18.3333 12.5V10.8334" strokeLinecap="round" fill='none' strokeWidth={props.strokeWidth} strokeLinejoin="round" />
        </SvgIcon>
    );
}

export default LinkOpenIcon;
// Customizable Area End