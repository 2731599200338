// Customizable Area Start
import React from "react";
import { Typography, Box, Grid, Button, FormControl, InputAdornment , FormHelperText } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CreatePostLinkModalController, { Props } from "./CreatePostLinkModalController.web";
import "./CreatePostNew.web.css"
import EmbedIcon from "./SVGIcons/EmbedIcon.web";
import EditIcon from "./SVGIcons/EditIcon.web";
import CameraIcon from "./SVGIcons/CameraIcon.web";
// Customizable Area End

// Customizable Area Start
const Poststyles = (theme: Theme) =>
    createStyles({
        title: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            display: 'none',
        },
        line: {
            color: "lightgrey",
            fontSize: "40px",

        },
        create: {
            height: "30px",
            padding: "10px",
            width: "100%",
            border: 'none', "& fieldset": { border: 'none' },
            borderRadius: "10px",
            background: "#F4F4F4",
        },
        userCard: {
            border: "1px solid grey",
            width: "50%",
            margin: "0 auto",
            textAlign: "center",
             boxShadow: "none",
        },
        userCommunity: {
             border: "1px solid grey",
             width: "50%",
             margin: "0 auto",
             background: "transparent",
             boxShadow: "none",
        },
        media: {
             margin: "0 auto",
             width: "100px",
             height: "100px",
        },
        paper: {
             backgroundColor: theme.palette.background.paper,
             padding: "10px",
             borderRadius: "10px",
        },
        postLinkModal: {
             boxShadow: "none",
             border: "1px solid #000",
             borderRadius: "10px",
        },
        description: {
             outline: "none",
             borderRadius: "10px",
             paddingBottom:"8px",
        },
        PostIcons: {
             display: "flex",
             justifyContent: "space-between"
        },
        imageUploads: {
             display: "flex",
             justifyContent: "space-between",
             borderBottom: "1px solid grey"
        },
        postLinkBox: {
             height: "308px",
             overflow: "auto"
        },
        closeBtn: {
             padding: "0px"
        },
        urlPostGrid:{
             display:"flex",
             flexDirection:"row",
             justifyContent:"center",
             alignItems:"center",
             width:"100%"
        },
         urlPostGridFormControl:{
             width:"92%"
        },
         thumbnail: {
             display: "flex",
             flexDirection: "column",
             alignItems: "center",
             background: "#F4F4F4",
             borderRadius: "10px",
             border: 'none', "& fieldset": { border: 'none' },
             width: "100%"
        },
         publishBtnBox:{
             display:"flex",
             justifyContent:"center",
             alignItems:"center",
        },
         inputAdornment:{
             padding:"0px 8px"
         }
    })
// Customizable Area End

 /*istanbul ignore next */
export class CreatePostLinkModal extends CreatePostLinkModalController {

     constructor(props: Props) {
         super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
         const { classes, linkOpen, thumbnail, urlLink , urlLinkError, urlLinkErrorMsg , descriptionError ,description , descriptionErrorMsg , thumbnailError , thumbnailErrorMsg } = this.props
         return (
             <Modal
                 aria-labelledby="transition-modal-title"
                 aria-describedby="transition-modal-description"
                 className={`CreatePostModals ${classes.modal}`}
                 open={linkOpen}
                 onClose={this.props.handleLinkClose}
                 closeAfterTransition
                 BackdropComponent={Backdrop}
                 BackdropProps={{
                     timeout: 200,
                 }}
             >
                 <Fade in={linkOpen}>
                     <Box className={`${classes.paper} CreatePostModalMainBox`}>
                         <Card className={classes.postLinkModal} style={{ padding: "5px" }}>
                             <Grid container direction="column">
                                 <Grid item xs={12}>
                                     <Box display="flex" justifyContent="space-between" mb={2} paddingTop="8px" paddingLeft="17px">
                                         <Typography variant="body2" className={`Heading4 BoldFont`}>Create a Post</Typography>
                                     </Box>
                                 </Grid>
                                 <Grid item xs={12}>
                                     <Grid className={classes.postLinkBox} container style={{ display: "flex", gap: "10px" }}>
                                         <Grid className={classes.urlPostGrid} item xs={12}>
                                         <FormControl className={classes.urlPostGridFormControl}>
                                             <Typography component={"span"} className={`Heading6 BoldFont LinkEmbedTitle`} style={webPage.label}>URL</Typography>
                                             <TextField
                                                 placeholder="Add or paste URL here"
                                                 name="urlLink"
                                                 className={urlLinkError ? "LinkUrlTextfield ErroBoundary" : "LinkUrlTextfield"}
                                                 type="link"
                                                 value={urlLink}
                                                 onChange={this.props.handleChange}
                                                 fullWidth
                                                 error={urlLinkError}
                                                 InputProps={{
                                                     startAdornment: (
                                                         <InputAdornment className={classes.inputAdornment} position="start">
                                                         <EmbedIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#005487"} strokeWidth="0.055" fill={"#005487"} />
                                                         </InputAdornment>
                                                     ),
                                                     disableUnderline:true
                                                 }}
                                                 inputProps={{
                                                     className:"LinkUrlInput Heading5"
                                                 }}
                                             />
                                             {urlLinkError && (
                                             <FormHelperText>
                                                 {urlLinkErrorMsg}
                                             </FormHelperText>
                                            )}
                                         </FormControl>
                                         </Grid>
                                         <Grid className={classes.urlPostGrid} item xs={12}>
                                        <FormControl className={classes.urlPostGridFormControl}>
                                            <Typography component={"span"} className={`Heading6 BoldFont LinkEmbedTitle`} style={webPage.label}>Thumbnail</Typography>
                                            <Box
                                            className={thumbnailError ? `${classes.thumbnail} ErroBoundary` : `${classes.thumbnail}`}>
                                                {thumbnail === "" ? (
                                                    <Box display="flex" flexDirection="column" alignItems="center" style={{ width: "100%", padding: "10px" }} >
                                                        <CameraIcon width="24" height="24" viewBox="0 0 24 24" stroke="#808080" strokeWidth="1.5" fill="none"/>

                                                        <Button
                                                            component="label"
                                                            className={`Heading4 BoldFont FontColor2`}
                                                        >
                                                            Upload Thumbnail Image
                                                            <input
                                                                type="file"
                                                                hidden
                                                                onChange={this.props.ThumbnailImg}
                                                            />
                                                        </Button>
                                                        <Typography variant="body2" className={`Heading4 FontColor2`} style={{textAlign: "center"}}>Upload cover image minimum width <br />400 pixel (16:9)</Typography>
                                                    </Box>
                                                ) : (
                                                    this.props.editedImage?
                                                    <Box width="100%">
                                                    <img src={thumbnail} style={{ width: '100%', height: "150px" }} />
                                                </Box>:
                                                    <Box width="100%">
                                                        <img alt="image" src={thumbnail ? URL.createObjectURL(thumbnail) : ""} style={{ width: '100%', height: "150px" }} />
                                                    </Box>
                                                )}
                                            </Box>
                                            {thumbnailError && (
                                            <FormHelperText>
                                                {thumbnailErrorMsg}
                                            </FormHelperText>
                                            )}
                                        </FormControl>
                                         </Grid>
                                         <Grid className={classes.urlPostGrid} item xs={12}>
                                         <FormControl className={classes.urlPostGridFormControl}>
                                             <Typography component={"span"} className={`Heading6 BoldFont LinkEmbedTitle`} style={webPage.label}>Description</Typography>
                                             <TextField
                                                 className={descriptionError ? "LinkUrlDescTextfield ErroBoundary" : "LinkUrlDescTextfield"}
                                                 data-test-id="txtInputPassword"
                                                 placeholder="Add post description here"
                                                 type="text"
                                                 error={descriptionError}
                                                 name="description"
                                                 value={description}
                                                 onChange={this.props.handleChange}
                                                 multiline
                                                 fullWidth
                                                 InputProps={{
                                                     startAdornment: (
                                                         <InputAdornment className={classes.inputAdornment} position="start">
                                                         <EditIcon width="24" height="24" viewBox="0 0 24 24" stroke="#005487" strokeWidth="1.5" fill="none" />
                                                         </InputAdornment>
                                                     ),
                                                     disableUnderline:true
                                                 }}
                                                 inputProps={{
                                                     className:"CreateLinkTypePostDescription Heading5"
                                                 }}
                                             />
                                             {descriptionError && (
                                             <FormHelperText>
                                                 {descriptionErrorMsg}
                                             </FormHelperText>
                                             )}
                                         </FormControl>
                                         </Grid>
                                    
                                    
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mt={2} className={`${classes.publishBtnBox} Heading5 BoldFont`} width="100%">
                                        <Button disabled={this.props.disablePublish} variant="contained" type="submit" onClick={this.props.handlePublish} style={webPage.Publishbtn}>Publish</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                         </Card>
                     </Box>
                </Fade>
            </Modal>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
const webPage = {
        IconColor: {
        color: "#5B7389"
    },
    imageBtn: {
         borderBottom: "2px solid #01548a",
         borderRadius: "0px",
    },
    Postbtn: {
         background: "#01548a",
         color: "#fff"
    },
    Publishbtn: {
         background: "#01548a",
         color: "#fff",
         width: "95%",
         borderRadius:"0px",
         padding:"18px"
    },
    label: {
        marginBottom: "10px",
    } as const
}


export default withStyles(Poststyles, { withTheme: true })(CreatePostLinkModal);
// Customizable Area End