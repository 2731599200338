// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Grid,
  FormControl,
  TextField,

} from "@material-ui/core";
import ResetPasswordController, { Props } from "./ResetPasswordController.web";
import { SideImage, Logo,  Lock, Eye, Hideeye } from "./assets";
export const configJSON = require("./config");

// Customizable Area End

// Customizable Area Start
const webStyling = {
  logoCard: {
    width: "200px",
    marginTop: "38px",
    height: "200px",

    boxShadow: "-1px 3px 13px -1px rgba(0,0,0,0.75)",
    borderRadius: "50%",
  },
  PhoneDesc: {
    marginTop: "30px",
    color: "#989898",
  },
  Phone: {
    color: "#01548A",
    fontWeight: "bold",
    fontSize: "25px",
  } as const,

  otppin: {
    color: "grey",
    fontSize: 14,
    width: "35px",
    height: "35px",
    margin: "5px 5px",
    borderRadius: "5px",
    border: "1px solid #b3b3b3",
    textAlign: "center",
    padding: "5px 0px 5px 0px",
    justifyContent: "center",
    backgroundColor: "#fafafa",
  } as const,
  InputField: {
    marginTop: "10px",
  },

  LoginBtn: {
    width: "100%",
    textTransform: "none",
    backgroundColor: "#01548A",
    fontWeight: "bold",
    color: "#fff",

    borderRadius: "6px",
    marginTop: "5%",
    marginBottom: "5%",
  } as const,
  mainGridContainer: {
    height: "inherit",
  },

  fieldError: {
    textAlign: "start",
    fontSize: "16px",
    color: "red",
  } as const,
};

// Customizable Area End
export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  ConditionalRender = () => {
    return (
      <Box>
        <Box style={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            style={{
              color: "#01548A",
              fontWeight: "bold",
              fontSize: "25px",
            }}
          >
            Reset Password
          </Typography>
        </Box>
        <Box style={{ marginTop: "10px" }}>
          <form
            style={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <FormControl style={webStyle.InputField}>
                <TextField
                  data-test-id="txtInputPassword"
                  variant="outlined"
                  size="small"
                  id="passwword"
                  placeholder="Password"
                  style={{
                    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                  }}
                  name="password"
                  type={this.state.showPassTwoConfirm?"text":"password"}
                  value={this.state.password}
                  onChange={this.handleChangePassword}
                  error={this.state.password_error !== ""}
                  helperText={this.state.password_error}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ marginLeft: "-78px" }}
                      >
                        <img src={Lock} alt="lock-icon" />
               
                      </InputAdornment>
                    ),
                    endAdornment:(
                      <InputAdornment position="end">
                      <img onClick={this.EyeChangeTwoConfirm} style={{cursor:"pointer",position:"relative",left:"63px"}} src={this.state.showPassTwoConfirm?Eye:Hideeye} alt="lock-icon"/>
                       
                    </InputAdornment>
                  )
                  }}
                />
              </FormControl>
              <FormControl style={webStyle.InputField}>
                <TextField
                  data-test-id="txtInputConfirmPassword"
                  variant="outlined"
                  size="small"
                  id="password_confirmation"
                  placeholder="Confirm Password"
                  style={{
                    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                  }}
                  type={this.state.showPassTwo?"text":"password"}
                  name="password_confirmation"
                  value={this.state.password_confirmation}
                  onChange={this.handleChangeConfirmPassword}
                  error={this.state.confirmPassword_error !== ""}
                  helperText={this.state.confirmPassword_error}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ marginLeft: "-78px" }}
                      >
                        <img src={Lock} alt="lock-icon" />
                      </InputAdornment>
                    ),
                    endAdornment:(
                      <InputAdornment position="start">
                      <img onClick={this.EyeChangeTwo} style={{cursor:"pointer",position:"relative",left:"65px"}} src={this.state.showPassTwo?Eye:Hideeye} alt="lock-icon"/>
                     
                    </InputAdornment>
                  )
                  }}
                />
              </FormControl>
            </Box>

            <Typography
              style={{
                textAlign: "center",
                fontSize: "16px",
                color: "red",
              }}
            >
              {this.state.FieldError}
            </Typography>

            {this.state.successMessage != "" && (
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  color: "green",
                }}
              >
                {this.state.successMessage}
              </Typography>
            )}

            <Box style={{ marginTop: "40px", textAlign: "center" }}>
              <Button
                data-test-id="btnVerifyOTP"
                type="submit"
                variant="contained"
                style={{
                  width: "100%",
                  textTransform: "none",
                  backgroundColor: "#01548A",
                  color: "#fff",
                  fontWeight: "bold",
                  marginBottom: "5%",
                  borderRadius: "6px",
                  marginTop: "5%",
                }}
                onClick={this.handlePasswordSubmit}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    );
  };

  render() {
    return (
 <Box  id="Main"  height={"inherit"}className="PhoneVerifyMainBox">
     <Grid style={webStyling.mainGridContainer} container>
             <Grid item xs={12} sm={5}>
     <img
             
              alt="side-img-signUp"
              src={SideImage}
              style={{  height: "100%",width: "100%" }}
            />
          </Grid>

          <Grid item xs={12} sm={7} style={{ backgroundColor: "#fff" }}>

            <Grid
              container
      
              justifyContent="center"
              direction="column"
              alignItems="center"
            >
                  <Grid item>
          <Box style={webStyling.logoCard}>
                  <img
                
                    alt="logo-img"
                    src={Logo}
                    style={{  width: "200px", padding: 0, height: "200px" }}
                  />
                  </Box>
        </Grid>
               <Grid item>
                <Grid item style={{ marginTop: "20px" }}>
                  {this.ConditionalRender()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const webStyle = {
  logoCard: {
    width: "200px",
    height: "200px",
    borderRadius: "50%",
    boxShadow: "-1px 3px 13px -1px rgba(0,0,0,0.75)",
  },
  mainContainerGrid: {
    height: "inherit",
  },
  InputField: {
    marginTop: "10px",
  },
  Login: {
    color: "#01548A",
    fontWeight: "bold",
    fontSize: "25px",
  } as const,

  LoginBtn: {
    paddingLeft: "42%",
    paddingRight: "42%",
    textTransform: "none",
    backgroundColor: "#01548A",
    color: "#fff",
    fontWeight: "bold",
    borderRadius: "10px",
    marginBottom: "5%",
    marginTop: "5%",
  } as const,
  LoginSideImg: {
    height: "100%",
    width: "100%",
  },
};
// Customizable Area End
