// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const CloseIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M9.17 14.83L14.83 9.16998" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path d="M14.83 14.83L9.17 9.16998" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" fill="none" />
        </SvgIcon>
    );
}

export default CloseIcon;
// Customizable Area End