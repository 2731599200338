// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { getStorageData , removeStorageData, setStorageData } from "../../framework/src/Utilities";
import { toast } from "react-toastify"
import React from "react";
import { handleErrorArray, isLocalAdmin } from "./common";
import ApiCall from "./ApiCall.web";
import { AssessmentIconLayout, BlueAssessment, BlueCalender, BlueChat, BlueHome, BlueMedal, BlueNotification, Calender, Chat, CommunitiesIcon, Home, Medal, RedNotification, notificationIcon, people } from "./assets";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export interface IFriendsList {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    profile_picture: string | null;
  }
}

export interface ResponseUpcommingEvent{
  [x: string]: any;
  id: string,
  type: string,
  attributes: {
      id: number,
      event_name: string,
      description: string,
      start_date: string,
      end_date:string,  
  }
}

interface IUser {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    country_code: string;
    email: string;
    first_name: string;
    full_phone_number: string|number;
    last_name: string;
    full_name: string;
    job_title: string;
    phone_number: string;
    type: string;
    pronouns: string;
    language: string[];
    race: string;
    ethnicity: string;
    gender: string;
    state: string;
    is_private: boolean;
    city: string;
    country: string; // Type depends on your country object structure
    dob: string;
    timezone: string;
    is_local_admin: boolean;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    profile_picture: string;
    background_image: string | null;
    follower_count: number;
    is_Community_present: boolean;
    selected_communities: { data: { id: string, attributes: { profile_pic: string, name: string } }[] } | null;
    friend_list_count: number;
    is_in_connection: boolean;
    is_notification_available: boolean;
    is_friend_request_sent: boolean;
    is_already_request_available: boolean;
    is_biometric_or_facial_available: boolean;
    total_post_count: number;
  }
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  children: any;
  // Customizable Area End
}

interface S {
    // Customizable Area Start
  loading: boolean;
  email:string;
  city:string;
  state:string;
  profileImageSrc:string;
  followerCount:number;
  selectedTabId:any;
  list:any;
  filter:boolean;
  selectedValue:any;
  isSearchModel: boolean;
  recentSearchesList: {id: string|number,profilePic: string, search_result: string, type: string, deleteId: string|number}[];
  searchInput: string;
  searchResults: {id: string|number,profilePic: string, search_result: string, type: string}[];
  isLoading: boolean;
  showAllEnabled: boolean;
  searchModelRef: React.RefObject<HTMLDivElement>;
  searchInputRef: React.RefObject<HTMLDivElement>;
  userProfileData: any;
  showUserProfileModel: HTMLButtonElement | null;
  showLogoutModel: boolean;
  requestList: {id:string,request_id:string,full_name:string,profile_picture: string,requested_at: string,mutual_connection_data:{mutual_count:number,files:{photo:string}[]}[]}[];
  friendsList: {id:string,full_name:string,profile_picture:string,mutual_connection_data:{mutual_count:number,files:{photo:string}[]}[]}[];
  recommendedFriendsList: {id:string,full_name:string,profile_picture: string,mutual_connection_data:{mutual_count:number,files:{photo:string}[]}[]}[];
  communityFlag:any;
  communityData:any;
  showAllEvents: boolean;
  contactId:any;
  openBroadcast: boolean;
  upcommingEvent:Array<ResponseUpcommingEvent>;
  communitiesData:{img:string,name:string,id:string}[];
  requestLoader : {
    friends: boolean,
    request: boolean,
    recommandation: boolean
  };
  information: IUser;
  communityModal: boolean;
  switchAccount: boolean;
  tabValue:number;
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

/*istanbul ignore next */
 export default class LayoutController extends BlockComponent<
   Props,
   S,
   SS
> {
  // Customizable Area Start
  apiGetUserData:string ="";
  apiGetRecentSearches: string = "";
  apiGetSearchResults: string = "";
  apiDeleteRecentSearch: string = "";
  apiGetUserProfileData: string = "";
  apiCreateRecentSearchApiCallid: string = "";
  requestsListApiCallId: string = "";
  updateRequestApiCallId: string = "";
  userId: string = "";
  getFriendsListApiCallId: string = "";
  getRecommendedFriendsListApiCallId: string = "";
  addFriendRequestApiCallId: string = "";
  removeRecommendationApiCallId: string = "";
  apiGetCommunityDetails:string="";
  getUserInfoAPI: string = "";
  getcommunityInfoId: string = "";
  upCommingEventsListAPICallId: string ="";
  // Customizable Area End
     constructor(props: Props) {
     super(props);
    // Customizable Area Start
     this.receive = this.receive.bind(this);
     this.subScribedMessages = [
       getName(MessageEnum.AccoutLoginSuccess),
       getName(MessageEnum.RestAPIResponceMessage),
       getName(MessageEnum.NavigationPayLoadMessage),
       getName(MessageEnum.CountryCodeMessage),
    ]

    this.state = {
      loading: false,
      requestLoader : {
        friends: false,
        request: false,
        recommandation: false
      },
      email:"",
      city:"",
      state:"",
      profileImageSrc:"",
      followerCount:0,
      selectedTabId:Number(localStorage.getItem("SelectedScreenId")),
      isSearchModel: false,
      searchInput: "",
      recentSearchesList: [],
      searchResults: [],
      isLoading: false,
      showAllEnabled: false,
      searchModelRef: React.createRef<HTMLDivElement>(),
      searchInputRef: React.createRef<HTMLInputElement>(),
      list:["photos","videos","events","urls","posts"],
      filter:false,
      selectedValue:"",
      userProfileData: {},
      showUserProfileModel: null,
      showAllEvents: false,
      showLogoutModel: false,
      requestList: [],
      friendsList: [],
      recommendedFriendsList: [],
      communityFlag:"",
      communityData:{},
      openBroadcast: false,
      contactId: "",
      upcommingEvent:[],
      communitiesData:[],
      information: {
        id: "",
        type: "",
        attributes: {
          activated: false,
          country_code: "",
          email: "",
          first_name: "",
          full_phone_number: "",
          last_name: "",
          full_name: "",
          job_title: "",
          phone_number: "",
          type: "",
          pronouns: "",
          language: [],
          race: "",
          ethnicity: "",
          gender: "",
          state: "",
          is_private: false,
          city: "",
          country: "",
          dob: "",
          timezone: "",
          is_local_admin: false,
          created_at: "",
          updated_at: "",
          device_id: null,
          profile_picture: "",
          background_image: null,
          follower_count: 0,
          is_Community_present: false,
          selected_communities: null,
          is_notification_available: false,
          friend_list_count: 0,
          is_in_connection: false,
          is_friend_request_sent: false,
          is_already_request_available: false,
          is_biometric_or_facial_available: false,
          total_post_count: 0,
        }
      },
      communityModal: false,
      switchAccount: false,
      tabValue:0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  // Customizable Area End
  }
  // Customizable Area Start

  generateNavbarMenuList = () => {
   return [
      {
        id: 0,
        icon: <img src={this.state.tabValue === 0 ? BlueHome : Home} alt="home" />,
        label: "Home",
        screen: "LandingScreen"
      },
      {
        id: 1,
        icon: <img src={this.state.tabValue === 1 ? BlueChat : Chat} alt="chat" />,
        label: "Chat",
        screen: "Chat"
      },
      {
        id: 2,
        icon: <img src={this.state.tabValue === 2 ? BlueCalender : Calender} alt="calender" />,
        label: "Calendar",
        screen: "CalendarEvents"
      },
      {
        id: 3,
        icon: <img src={this.state.tabValue === 3 ? BlueNotification : notificationIcon} alt="Notification" />,
        redIcon: <img src={RedNotification} alt="rednotification" />,
        label: "Notifications",
        screen: "Notifications"
      },
      {
        id: 4,
        icon: <img src={this.state.tabValue === 4 ? people : CommunitiesIcon} alt="CommunitiesIcon" />,
        label: "Communities",
        screen: "Communities"
      },
      {
        id: 5,
        icon: <img src={this.state.tabValue === 5 ? BlueAssessment : AssessmentIconLayout} />,
        label: "Assessment Test",
        screen: "AssessmentDashboard"
      },
      {
        id: 6,
        icon: <img src={this.state.tabValue === 6 ? BlueMedal : Medal} />,
        label: "Achievements",
        screen: "AchievementListing"
      }
    ]
  }

   highLightNavBar = () => {
     const path = window.location.pathname.toLowerCase()
     switch (true) {
       case path.includes('chat'):
         this.setState({ tabValue: 1 });
         break;
       case path.includes('achievement'):
         this.setState({ tabValue: 6 });
         break;
       case path.includes('landingscreen'):
         this.setState({ tabValue: 0 });
         break;
       case path.includes('calendar'):
         this.setState({ tabValue: 2 });
         break;
       case path.includes('communit'):
         this.setState({ tabValue: 4 });
         break;
       case path.includes('notification'):
         this.setState({ tabValue: 3 });
         break;
       case path.includes('assessment'):
       case path.includes('dashboard'):
       case path.includes('test'):
         this.setState({ tabValue: 5 });
         break;
       default:
         return this.setState({ tabValue: 800 })
     }
   }

hideAsideInfo = () => {
    return ( window.location.pathname?.toLowerCase()?.includes("/forgotpasswordphoneverification") || window.location.pathname?.toLowerCase()?.includes("/phone-verification-login") || window.location.pathname?.toLowerCase()?.includes("/create-community") || window.location.pathname?.toLowerCase()?.includes("/login") || window.location.pathname?.toLowerCase()?.includes("/resetpassword")|| window.location.pathname?.toLowerCase()?.includes("/settings") || window.location.pathname?.toLowerCase()?.includes("/phoneverification") || window.location.pathname?.toLowerCase()?.includes("/signup") || window.location.pathname?.toLowerCase() === "/" || window.location.pathname?.toLowerCase()?.includes("/termsandcondition") || window.location.pathname?.toLowerCase()?.includes("/user-organization") || window.location.pathname?.toLowerCase()?.includes("/user-profile") || window.location.pathname?.toLowerCase()?.includes("/user-organization-information") || window.location.pathname?.toLowerCase()?.includes("/user-profile-information") || window.location.pathname?.toLowerCase()?.includes("/contact-info"))||window.location.pathname?.toLowerCase()?.includes("/help")||window.location.pathname?.toLowerCase()?.includes("/linkedaccounts/creation")
  }

  handleConditionaRenderForInitialScreens = () => {
    return (window.location.pathname?.toLowerCase()?.includes("/forgotpasswordphoneverification") || window.location.pathname?.toLowerCase()?.includes("/phone-verification-login") || window.location.pathname?.toLowerCase()?.includes("/create-community") ||window.location.pathname?.toLowerCase()?.includes("/login") || window.location.pathname?.toLowerCase()?.includes("/resetpassword")|| window.location.pathname?.toLowerCase()?.includes("/phoneverification") || window.location.pathname?.toLowerCase()?.includes("/signup") || window.location.pathname?.toLowerCase() === "/" || window.location.pathname?.toLowerCase()?.includes("/termsandcondition") ||  window.location.pathname?.toLowerCase()?.includes("/user-profile-information") || window.location.pathname?.toLowerCase()?.includes("/user-organization") || window.location.pathname?.toLowerCase()?.includes("/user-profile"))
  }

  handleConditionalRenderForUserProfile = () => {
    return (window.location.pathname?.toLowerCase()?.includes("/user-organization") ||  window.location.pathname?.toLowerCase()?.includes("/user-profile") || window.location.pathname?.toLowerCase()?.includes("/user-organization-information") || window.location.pathname?.toLowerCase()?.includes("/user-profile-information") || window.location.pathname?.toLowerCase()?.includes("/create-community") )||window.location.pathname?.toLowerCase()?.includes("/linkedaccounts/creation")
  }

  shouldRequestsListShow = () => {
    return (window.location.pathname.toLowerCase().includes(`profile/${this.state.information.id}`) || window.location.pathname.toLowerCase() === "/profile");
  }

  isOtherUserProfileViewPage = () => {
    return (window.location.pathname.toLowerCase().includes("/profile/"));
  }
  handleSelectNavbarItem = (e:any , value:any) => {
    this.setState({ selectedTabId : value})
    localStorage.setItem("SelectedScreenId" , value)
  }

  handleMenuClickItem = (screenName : any) => {
    if(screenName) {
      this.props.navigation.navigate(screenName)
      this.setState({showUserProfileModel: null})
    }
  }

  handleMyAccount = () =>{
    this.setState({switchAccount:!this.state.switchAccount, showUserProfileModel: null})
  }

  handleBroadcaste = () => {
    this.setState({openBroadcast: true,showUserProfileModel: null})
  }

  myCommunityNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LinkedAccounts");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    this.setState({showUserProfileModel: null})

  }

  handleSearchItemClick = async (searchItem: {id: string | number,profilePic: string,search_result: string,type: string}, model: string, isRecentSearchClick?: boolean) => {
  localStorage.setItem("SelectedScreenId","9")
  this.closeSearchModal();
if(model=="Organization")
{
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "UserOrganizationInformationEdit");

  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  message.addData(
    getName(MessageEnum.NavigationScreenNameMessage),
    searchItem.id
  );
  this.send(message);

}

 else if(model=="Post")
{
  const msg1 = new Message(getName(MessageEnum.NavigationPostMessage));
      msg1.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      msg1.addData(
        getName(MessageEnum.UrlPathParamMessage),
        searchItem.id
      );
      this.send(msg1)
}
 else  if(model==="Account" ){
      this.navigateToAccount(searchItem)
    

 }else if(model === "Community"){
  this.navigateToProfile(searchItem,model)
 }
    
    const  { signupToken, loginToken } = await this.getTokens();

    const header = {
      token: signupToken || loginToken
    };

  const formData = new FormData()
  formData.append("title",searchItem.search_result)
  formData.append("recent_searchable_type", searchItem.type);
  formData.append("recent_searchable_id", String(searchItem.id));

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiCreateRecentSearchApiCallid = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createRecentSearchApiEndpoint,
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createRecentSearchApiMethod,
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  navigateToProfile = (searchItem: any,model:string) =>{
    this.props.navigation.navigate("OtherUserProfile",{id:`${searchItem.id}-${model}`});
      this.closeSearchModal()
      
  }

  navigateToAccount = (searchItem:any)=>{
    this.props.navigation.navigate("OtherUserProfile",{id:`${searchItem.id}`});

  }

  closeSearchModal = () => {
    this.setState({
      showAllEnabled: false,
      isSearchModel: false,
      searchResults: [],
      searchInput: "",
    })
  }

  openProfile =(id: string) => {
    this.props.navigation.navigate("OtherUserProfile", {id:id});
  }

  openCommunity = (id: string) => {
    this.props.navigation.navigate("OtherUserProfile", {id:`${id}-Community`});
  }

  upCommingEventsList = async () => {
    this.setState({ loading: true })
    this.upCommingEventsListAPICallId = await ApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.upcomingEventEndpoint,

    });
  };

  upComingEventsListSucessCallBack = (res:{data: ResponseUpcommingEvent}) => {
    let isDataSort = res.data.sort((a: ResponseUpcommingEvent, b: ResponseUpcommingEvent) => {
      let c: number = new Date(a?.attributes?.start_date).getTime();
      let d: number = new Date(b?.attributes.start_date).getTime();
      return c - d
    })
    setStorageData("upcomingEvents",JSON.stringify(isDataSort))
    this.setState({ upcommingEvent: isDataSort, loading: false })
  };
  upComingEventsListFailureCallBack = (res: any) => {
    this.setState({loading:false})
  };


  eventDetails = (items:string) =>{
    this.props.navigation.navigate("CalendarEventDetails",{id:items})
  }

  notCallApiRoute = () => {
    const path = window.location.pathname;
    return path === "/" || path?.toLowerCase().includes("login") || path?.toLowerCase().includes("signup") || path?.toLowerCase().includes("phoneverification") || path?.toLowerCase().includes("forgotpasswordphoneverification") || path?.toLowerCase().includes("resetpassword");
  }
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.routeHandle()
    const isLA = await isLocalAdmin()

      this.getLoginUserInformation();
    
  this.upCommingEventsList()

    const userId = this.props.navigation.getParam("id");
    const isOtherUserProfileViewPage = this.isOtherUserProfileViewPage();
    if(userId && isOtherUserProfileViewPage){
      this.userId = userId;
      this.getFriendsList();
    }
    await this.handleCommunityData();


  const userProfileData= await getStorageData("profileData");

  
  if(!userProfileData || localStorage.getItem("privatePost")=="true" || localStorage.getItem("privatePost")=="false" || localStorage.getItem("userNameSetting")=="true" )
  {
    await this.getUserProfileData();
  }
 else if(localStorage.getItem("userProfileInfo")=="true")
  {
    await this.getUserProfileData();
  }
  else{

    this.setState({
      userProfileData: JSON.parse(userProfileData)
    })
  }

   
 
    
    document.body.addEventListener("click", this.handleOutsideClick);
    
    if(this.shouldRequestsListShow() && !isLA){
      await this.getRequests();
      await this.getRecommendedFriendsList();
    }



    this.setState({communityFlag:localStorage.getItem("community")})
    if( localStorage.getItem("communityData")!="")
    {
     let parsedData=localStorage.getItem("communityData")
     if(parsedData!=null)
     {
       this.setState({communityData:JSON.parse(parsedData)})
     }
    
    }


    // Customizable Area End
 
  }

 // Customizable Area Start

 async componentDidUpdate(prevProps: Readonly<Props>) {
  const prevUserId = prevProps.navigation.getParam("id");
  const userId = this.props.navigation.getParam("id");
  const isOtherUserProfileViewPage = this.isOtherUserProfileViewPage();

  if (isOtherUserProfileViewPage && (userId !== prevUserId)) {
    // The 'id' parameter has changed, call getFriendsList
    this.userId = userId;
    this.getFriendsList();
  }
}

  getLoginUserInformation = async () => {
    this.setExistingData(); // for left side section existing data
    this.highLightNavBar(); //  for navbar highlight
    const wait = this.notCallApiRoute()// no need to call on this route
    if(wait) return
    const isLA = await isLocalAdmin();
    if(isLA){
      this.getCommunityInformaation()
    } else {
      this.getUserInformation()
    }
  }

  setExistingData = async () => {
    const isLA = await isLocalAdmin();
    const upcomingEvents = await getStorageData("upcomingEvents",true);
    const profileData = await getStorageData("profileData",true);
    if(isLA && profileData){
      upcomingEvents && this.upComingEventsListSucessCallBack({data:upcomingEvents})
      profileData?.id && this.getCommunityInformationResponse({"data":profileData})
    }else {
      upcomingEvents && this.upComingEventsListSucessCallBack({data:upcomingEvents})
      profileData?.id && this.getUserInformationResponse({"data":profileData, errors: [] })
    }
  }

   getUserInformation = async () => {
     const { signupToken, loginToken } = await this.getTokens();

     const header = {
       token: signupToken ?? loginToken
     };

     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
     this.getUserInfoAPI = requestMessage.messageId;
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       `${configJSON.getUserProfileDataApiEndPoint}`
     );

     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.getUserProfileDataApiMethod
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );

     runEngine.sendMessage(requestMessage.id, requestMessage);
   }

   getCommunityInformaation = async () => {
    const { signupToken, loginToken } = await this.getTokens();
     const header = {
       token: signupToken ?? loginToken
     };
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
     this.getcommunityInfoId = requestMessage.messageId;
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       `${configJSON.get_community}?is_post_invisible=true`
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.getUserProfileDataApiMethod
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
   }

   getUserInformationResponse = (responseJson: { data: IUser, errors: {[key:string]:string}[] }) => {
     if (responseJson.data) {
       this.setState({ information: responseJson.data })
       setStorageData("profileData",JSON.stringify(responseJson.data))
       if (responseJson.data.attributes.selected_communities?.data) {
         this.leftSideCommunitySection(responseJson.data.attributes.selected_communities.data); // for left side followed community data
       }
     }else if(responseJson.errors){
      this.handleLogoutSuccess();
      localStorage.clear(); 
     }
   }

   getCommunityInformationResponse = (responseJson: {
     data: {
       id: string;
       type: string;
       attributes: {
         name: string;
         email: string;
         phone_number: number;
         website: string;
         description: string;
         account_id: number;
         profile_pic: string;
         background_profile_pic: string | null;
         post_count: number;
         follower_count: number;
         is_followed_by_user: boolean;
         is_notification_available: boolean;
       };
     };
   }) => {
     if (responseJson.data) {
      setStorageData("profileData",JSON.stringify(responseJson.data))
      this.setState({information: {
        id:responseJson.data.id,
        type: responseJson.data.type,
        attributes: {
          activated: true,
          country_code: "",
          email: responseJson.data.attributes.email,
          first_name: "",
          full_phone_number: responseJson.data.attributes.phone_number,
          last_name: "",
          full_name: responseJson.data.attributes.name,
          job_title: "",
          phone_number: "",
          type: "",
          pronouns: "",
          language: [],
          race: "",
          ethnicity: "",
          gender: "",
          state: "",
          is_private: false,
          city: "",
          country: "",
          dob: "",
          timezone: "",
          is_local_admin: false,
          created_at: "",
          updated_at: "",
          device_id: null,
          profile_picture: responseJson.data.attributes.profile_pic,
          background_image: responseJson.data.attributes.background_profile_pic,
          follower_count: responseJson.data.attributes.follower_count,
          is_Community_present: false,
          selected_communities: null,
          friend_list_count: 0,
          is_in_connection: false,
          is_friend_request_sent: false,
          is_already_request_available: false,
          is_biometric_or_facial_available: false,
          is_notification_available: responseJson.data.attributes.is_notification_available,
          total_post_count: responseJson.data.attributes.post_count,
        }
      },
    communitiesData:[]})
     }
   }

   leftSideCommunitySection = (communities: { id: string, attributes: { profile_pic: string, name: string } }[]) => {
     const updatedCommunitiesData = communities.map(({ id, attributes }) => ({
       img: attributes.profile_pic,
       name: attributes.name,
       id: id
     }));

     this.setState({
       communitiesData: updatedCommunitiesData
     });
   }


 filterToggle=()=>{

  this.setState({filter: !this.state.filter,isSearchModel:false})
}
 handleSelect=(value:any)=>{

  this.props.navigation.navigate("Filteritems",{id: value})
  this.setState({selectedValue:value, filter:false})
 

 }
routeHandle=()=>{
  if(window.location.pathname?.toLowerCase()?.includes("landingscreen") )
  {
    localStorage.setItem("SelectedScreenId", "1");
  
  }

  else if(window.location.pathname?.toLowerCase()?.includes("chat") )
  {
    localStorage.setItem("SelectedScreenId", "2");
    this.setState({selectedTabId:localStorage.getItem("SelectedScreenId")})
  
  }
  else if(window.location.pathname?.toLowerCase()?.includes("calendarevents"))
  {
    localStorage.setItem("SelectedScreenId", "3");
    this.setState({selectedTabId:localStorage.getItem("SelectedScreenId")})
  }
  else if(window.location.pathname?.toLowerCase()?.includes("notifications"))
  {
    localStorage.setItem("SelectedScreenId", "4");
    this.setState({selectedTabId:localStorage.getItem("SelectedScreenId")})
  }
  else if(window.location.pathname?.toLowerCase()?.includes("communities"))
  {
    localStorage.setItem("SelectedScreenId", "5");
    this.setState({selectedTabId:localStorage.getItem("SelectedScreenId")})
  }
  else if(window.location.pathname?.toLowerCase()?.includes("assessment-dashboard"))
  {
    localStorage.setItem("SelectedScreenId", "6");
    this.setState({selectedTabId:localStorage.getItem("SelectedScreenId")})
  }
  else if(window.location.pathname?.toLowerCase()?.includes("achievement-listing"))
  {
    localStorage.setItem("SelectedScreenId", "7");
    this.setState({selectedTabId:localStorage.getItem("SelectedScreenId")})
  }
}
 handleContactInfoNavigation = async ()=>{
  const isLA = await isLocalAdmin();
  this.props.navigation.navigate("ContactInfo",{id: isLA ? this.state.information.id + '-community' : this.state.information.id})
 }
 async componentWillUnmount() {
  super.componentWillUnmount();
  document.body.removeEventListener("click", this.handleOutsideClick);
}

  
  async receive(_from: string, message: Message) {
    
   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
   

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // Customizable Area Start
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.upCommingEventsListAPICallId) {
          this.upComingEventsListSucessCallBack(responseJson);
        }

      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.upCommingEventsListAPICallId) {
          this.upComingEventsListFailureCallBack(responseJson);
        }
      }
      this.setState({
        isLoading: false
      })
      if (apiRequestCallId === this.getUserInfoAPI) return this.getUserInformationResponse(responseJson)
      if (apiRequestCallId === this.getcommunityInfoId) return this.getCommunityInformationResponse(responseJson)
        if(apiRequestCallId === this.apiGetCommunityDetails)
        {
          if(responseJson?.data?.attributes?.email)
          {
            let resp= JSON.stringify(responseJson?.data)
            if(resp!=undefined)
            {
              localStorage.setItem("communityData",resp) 
              if(this.state.communityFlag=="true")
            
             {  localStorage.setItem("LoggedInUserPostCountCommunity", JSON.stringify(responseJson?.data?.attributes?.post_count));}
            }
            
        
          }
          else{
            localStorage.setItem("communityData","No-Data") 
          }
        }
        if(apiRequestCallId === this.apiGetRecentSearches){
          if(responseJson.data){
            const searchResults = responseJson.data.map(({ id, attributes }: {
              id: string |number,
              attributes : { 
              title: string,
              recent_searchable_type: string,
              recent_searchable_id: number | string,
              profile_picture?: string
              }
            }) => ({ deleteId: id , id: attributes.recent_searchable_id, search_result: attributes.title, type: attributes.recent_searchable_type, profilePic: attributes.profile_picture ?? "" }));
            this.setState({ recentSearchesList:searchResults })
        }
        }
        if(apiRequestCallId === this.apiGetSearchResults){
          
          if(responseJson.data && !responseJson.data[0].message){
            const searchResults = responseJson.data.map(({ id, search_result, model, photo }: {
              id: number | string,
              search_result: string,
              model: string,
              photo?: string
            }) => ({ id, search_result, type: model, profilePic: photo ?? "" }));
            this.setState({ searchResults })
          }else{
            this.setState({ searchResults: [] })
          }
        }
        if(apiRequestCallId === this.apiDeleteRecentSearch){
          if(responseJson.errors){
            if(responseJson.errors && responseJson.errors.length > 0){
             
              responseJson.errors.forEach((errObj:any) => {
   
                if(responseJson?.errors[0].token!=="Invalid token")
                {
              
                toast.error(errObj.message)}
              });
            }
          }

          if(responseJson[0]){
            toast.success(responseJson[0].message);
            this.fetchRecentSearches();
          }
        }
        if(apiRequestCallId === this.apiGetUserProfileData){
          if(responseJson && responseJson.data && responseJson.data.attributes){
            this.setState({
              contactId: responseJson.data.id,
              userProfileData: responseJson.data.attributes
            })
            if(this.state.communityFlag=="false")
          
            {  localStorage.setItem("LoggedInUserPostCount", JSON.stringify(responseJson?.data?.attributes?.total_post_count));}
            
          
            
       
            if(localStorage.getItem("privatePost")!=="")
            {
              localStorage.setItem("privatePost","")
              window.location.reload();
            }
            if(    localStorage.getItem("userNameSetting")=="true")
            {
              localStorage.setItem("userNameSetting","false")
            }
       
          }
          else if( responseJson && responseJson.errors){
            
            
              responseJson.errors.forEach((error: any)=>{
            if(responseJson?.errors[0].token!=="Invalid token" || responseJson?.errors[0].token!=="Token has Expired")
            {
              localStorage.clear();
            }
                if(responseJson?.errors[0].token!=="Invalid token")
                {
                  
               toast.error(Object.values(error).join(","))}


             })
            
  
         
          }
        }
        if(apiRequestCallId === this.requestsListApiCallId){
          this.setState({requestLoader:{...this.state.requestLoader,request:false}})
          if(responseJson && responseJson.data){
            const temp = responseJson.data.map((_: { id: string, attributes: { full_name: string,sender_id: string, requested_at: string, profile_picture: string, mutual_friends_count: { mutual_count: number, files: { photo: string }[] }[] } }) => {
              return (
                {
                  id: _.attributes.sender_id,
                  full_name: _.attributes.full_name,
                  request_id: _.id,
                  profile_picture: _.attributes.profile_picture,
                  requested_at: _.attributes.requested_at,
                  mutual_connection_data: _.attributes.mutual_friends_count,
                }
              )
            })
            this.setState({
              requestList: temp
            })
          } else {
              this.setState({
                requestList: []
              })
          }
      }
  
      if(apiRequestCallId === this.updateRequestApiCallId){
        this.setState({requestLoader:{...this.state.requestLoader,request:false}})
        if(responseJson.errors){
            toast.error(responseJson.errors)
        } else if(responseJson.message){
            toast.success(responseJson.message);
            this.getRequests();
        }
      }

      if(apiRequestCallId === this.getFriendsListApiCallId){
        this.setState({requestLoader:{...this.state.requestLoader,friends:false}})
        if(responseJson && responseJson.data){
          const tempFriends = responseJson.data.map((_: { id: string, attributes: { full_name: string, profile_picture: string, mutual_connection_data: { mutual_count: number, files: { photo: string }[] }[] } }) => {
            return (
              {
                id: _.id,
                full_name: _.attributes.full_name,
                profile_picture: _.attributes.profile_picture,
                mutual_connection_data: _.attributes.mutual_connection_data,
              }
            )
          })
          this.setState({
            friendsList: tempFriends
          })
        } else {
          this.setState({
            friendsList: []
          })
        }
      }

      if(apiRequestCallId === this.getRecommendedFriendsListApiCallId){
        this.setState({requestLoader:{...this.state.requestLoader,recommandation:false}})
        if(responseJson && responseJson.data){
          const temp = responseJson.data.map((_:{id:string,attributes:{full_name:string,profile_picture: string,mutual_connection_data:{mutual_count:number,files:{photo:string}[]}[]}})=>{
            return (
           { id: _.id,
            full_name: _.attributes.full_name,
            profile_picture: _.attributes.profile_picture,
            mutual_connection_data: _.attributes.mutual_connection_data,}
          )})
          this.setState({
            recommendedFriendsList: temp
          })
        }else{
          this.setState({
            recommendedFriendsList: []
          })
        }
      }
      if(apiRequestCallId === this.addFriendRequestApiCallId){
        this.setState({requestLoader:{...this.state.requestLoader,recommandation:false}})
        if(responseJson && responseJson.data && responseJson.message){
            toast.success(responseJson.message);
            this.getRecommendedFriendsList();
        }
        else if (responseJson && responseJson.errors) {
            handleErrorArray(responseJson.errors);
        }
      }
      if(apiRequestCallId === this.removeRecommendationApiCallId){
        this.setState({requestLoader:{...this.state.requestLoader,recommandation:false}})
        if(responseJson && responseJson.message){
          toast.success(responseJson.message);
          this.getRecommendedFriendsList();
        }
      }

      // Customizable Area End
    }
  

  }

  getTokens = async () => {
    let signupToken = await getStorageData('SignupSuccessToken');
    let loginToken = await getStorageData('LoginSuccessToken');
    let userData = await getStorageData("LoggedInUserData",true);
    return { signupToken, loginToken, userData};
  }
    

   a11yProps(index: number) {
     return {
       id: `scrollable-force-tab-${index}`,
       'aria-controls': `scrollable-force-tabpanel-${index}`,
     };
   }
   handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
     const stateUpdates: { [key: number]: string } = {
       0: 'LandingScreen',
       1: 'Chat',
       2: 'CalendarEvents',
       3: 'Notifications',
       4: 'Communities',
       5: 'AssessmentDashboard',
       6: 'AchievementListing'
     };

      this.setState({ tabValue: newValue});
      this.props.navigation.navigate(stateUpdates[newValue]);
   };


  handleSearchInputChange = async (e: any) => {
    this.showSearchModel();
    localStorage.setItem("filterUser",e.target.value);
    this.setState({
      searchInput: e.target.value
    }, async () => {
      if(this.state.searchInput.trim().length > 0){
        await this.fetchSearchResults();
      } else {
        await this.fetchRecentSearches();
      }
    })
  }

  startLoading = () => {
    this.setState({
      isLoading: true
    })
  }
  fetchRecentSearches = async () => {
    this.startLoading()
    const  { signupToken, loginToken } = await this.getTokens();

    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetRecentSearches = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecentSearchesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchSearchResults = async () => {
    this.startLoading()
    const  { signupToken, loginToken } = await this.getTokens();

    const header = {
      token: signupToken || loginToken
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSearchResults = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSearchResultApiEndPoint}?q=${this.state.searchInput}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSearchResultApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteRecentSearch = async (e:React.MouseEvent<HTMLElement>,id: number|string) => {
    e.stopPropagation();
    const  { signupToken, loginToken } = await this.getTokens();

    const header = {
      token: signupToken || loginToken
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteRecentSearch = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteRecentSearchApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteRecentSearchApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserProfileData = async () => {
    const  { signupToken, loginToken } = await this.getTokens();

    const header = {
      token: signupToken || loginToken
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUserProfileData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserProfileDataApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserProfileDataApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  toggleShowAll = () => {
    this.setState({
      showAllEnabled: true
    })
  }



    showSearchModel = () => {
      this.setState({filter: false})
      if(!this.state.isSearchModel){
        this.fetchRecentSearches();
      }

   
      this.setState({
        isSearchModel: true
      });
    
      
      
     
    }
  
 

  setProfileModel = (event:React.MouseEvent<HTMLButtonElement>) =>{
    this.setState({
      showUserProfileModel: event.currentTarget
    })
  }

  closeProfileModal = () => {
    this.setState({
      showUserProfileModel: null
    })
  }
  handleListKeyDown(event: { key: string }) {
    if (event.key === 'Tab') {
      this.setState({  showUserProfileModel: null });
    }
  }

  setLogoutModel = (isShow: boolean) => {
    this.setState({
      showLogoutModel: isShow
    })
  }


  handleLogoutSuccess = async () => {
    await removeStorageData("SignupSuccessToken")
    await removeStorageData("LoginSuccessToken")
    await removeStorageData("profileData");
    await removeStorageData("community");
    await removeStorageData("communityData");
    this.props.navigation.navigate("LogIn")
  }


  handleOutsideClick = (event: MouseEvent) => {

    if (
      event.target instanceof Node &&
      this.state.searchInputRef.current &&
    this.state.searchModelRef.current &&
    !this.state.searchInputRef.current.contains(event.target) &&
    !this.state.searchModelRef.current.contains(event.target)
    ) {
      this.setState({
        isSearchModel: false,
        searchResults: [],
        searchInput: "",
        showAllEnabled: false
      });
    }
  };


  getRequests = async () => {
    this.setState({requestLoader:{...this.state.requestLoader,request:true}})
    const  { signupToken, loginToken } = await this.getTokens();
    const header = {
        token: signupToken || loginToken,
        "Content-Type": configJSON.apiContentType,
    };
    const requestListMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestsListApiCallId = requestListMessage.messageId;
    requestListMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getRequestsListApiEndpoint
    );

    requestListMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestListMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getRequestsListApiMethod
    );
    runEngine.sendMessage(requestListMessage.id, requestListMessage);
}



updateRequest = async (isAccept: boolean, id: string) => {
  this.startLoading();
  const  { signupToken, loginToken } = await this.getTokens();
    const httpBody = {
        "data": {
          "status": isAccept ? "Accepted" : "Rejected"  
        },
    };

    const header = {
        token: signupToken || loginToken,
        "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateRequestApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateRequestApiEndpoint + `/${id}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody),
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateRequestApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

  handleShowAllRequest = () => {
    this.props.navigation.navigate("FriendRequests")
  }

  handleCommunityData = async() => {

    let signupToken = await getStorageData('SignupSuccessToken');
  
    let loginToken = await getStorageData('LoginSuccessToken');
  

    const header = {
      token: signupToken || loginToken,
  
      "Content-Type": configJSON.apiContentType,
  
    };
    const requestMessage = new Message(
  
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    this.apiGetCommunityDetails = requestMessage.messageId;
  
    requestMessage.addData(
  
      getName(MessageEnum.RestAPIResponceEndPointMessage),
  
  "get_community"
    );
  
    requestMessage.addData(
  
      getName(MessageEnum.RestAPIRequestHeaderMessage),
  
      JSON.stringify(header)
  
    );
  
    requestMessage.addData(
  
      getName(MessageEnum.RestAPIRequestMethodMessage),
  
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  getFriendsList = async () => {
    this.setState({requestLoader:{...this.state.requestLoader,friends:true}})
    const  { signupToken, loginToken } = await this.getTokens();
    const header = {
        token: signupToken || loginToken,
        "Content-Type": configJSON.apiContentType,
    };
    const requestListMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFriendsListApiCallId = requestListMessage.messageId;
    requestListMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getFriendsListApiEndpoint + `?account_id=${this.userId}`
    );

    requestListMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestListMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
    );
    runEngine.sendMessage(requestListMessage.id, requestListMessage);
  }

  getRecommendedFriendsList = async () => {
    this.setState({requestLoader: { ...this.state.requestLoader, recommandation:true}})
    const  { signupToken, loginToken } = await this.getTokens();
    const header = {
        token: signupToken || loginToken,
        "Content-Type": configJSON.apiContentType,
    };
    const requestListMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRecommendedFriendsListApiCallId = requestListMessage.messageId;
    requestListMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getRecommendedAccountsApiEndpoint
    );

    requestListMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestListMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getRecommendedAcountsApiMethod
    );
    runEngine.sendMessage(requestListMessage.id, requestListMessage);
  }

  addFriendRequest = async (id:string|number) => {
    this.setState({requestLoader:{...this.state.requestLoader,recommandation:true}})
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken');
    
    const header = {
      "Content-Type": 'application/json',
        token: signupToken || loginToken
    }
    const body = {
      data:{
        "account_id": id.toString(),
        "request_text": ""
      }
    }
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendRequestApiEndpoint,
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.addFriendRequestApiCallId = requestMessage1.messageId;
    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  }



   handleFollowerList = async () => {
     const isLA = await isLocalAdmin()
     isLA ? this.props.navigation.navigate("FollowersList",{id:`community-${this.state.information.id}`}) : this.props.navigation.navigate("FollowersList", { id: this.state.information.id })
   }

   showAllFriends = () => {
     const userId = this.props.navigation.getParam("id");
     this.props.navigation.navigate("FriendsList", {id:userId})
   }

   showAllEventFn = () => {
    this.setState({showAllEvents: !this.state.showAllEvents})
   }

   showAllRecommanded = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "FriendRequests");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      "Recommandation"
    );
    this.send(message);
   }

   showAllCommunities = () => {
    this.setState({communityModal:true})
   }

   closeCommunityModal = () => {
    this.getLoginUserInformation()
    this.setState({communityModal:false})
   }

  removeRecommendation = async (id: string) => {
    this.setState({requestLoader:{...this.state.requestLoader,recommandation:true}})
    
    const formData = new FormData();
    formData.append("recommended_account_id", id);
    const  { signupToken, loginToken } = await this.getTokens();
    const header = {
        token: signupToken || loginToken
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeRecommendationApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.removeRecommendationApiEndpoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.removeRecommendationApiMethod
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCloseBroadcast=()=>{
    this.setState({openBroadcast:false})
  }
 // Customizable Area End
}