// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Grid,
  FormControl,
  TextField,
  Link,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import NewLoginController, { Props } from "./NewLoginController.web";
import { SideImage, Logo, Mail, Lock, Eye, Hideeye } from "./assets";
import { styled } from "@material-ui/core/styles";
import "./LoginNew.web.css";
// Customizable Area End


export class LoginNew extends NewLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
  render() {
    const { email, password, emailError, passwordError } = this.state;
    return (
      <Box height={"100%"} className="LoginMainBox">
        <Grid style={webStyle.mainContainerGrid} container>
          <LeftImage item xs={12} sm={5} className="LoginLeftSection" />

          <Grid item xs={12} sm={7} className="LoginRightSection">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: "50px" }}
            >
              <Grid item xs={12}>
                <Box style={webStyle.logoCard}>
                  <img src={Logo} alt="logo-img" className="LoginLogoImg" />
                </Box>
              </Grid>
              <Grid item xs={12} className="LoginDetailsBlock">
                <Grid container>
                  <Grid item xs={12}>
                    <Box>
                      <Typography variant="h4" style={{ marginTop: "40px" }}>
                        Login
                      </Typography>
                    </Box>
                    <Box
                      className="LoginInputFields"
                      style={{ marginTop: "40px" }}
                    >
                      <form onSubmit={this.handleSubmit}>
                        <FormControl style={webStyle.InputField}>
                          <TextField
                            id="email"
                            data-test-id="txtInputEmail"
                            variant="outlined"
                            size="small"
                            placeholder="Email id"
                            name="email"
                            type="text"
                            value={email}
                            onChange={this.handleChange}
                            error={emailError !== ""}
                            helperText={emailError}
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginLeft: "-78px" }}
                                >
                                  <img src={Mail} alt="mail-icon" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                        <FormControl style={webStyle.InputField}>
                          <TextField
                            id="password"
                            variant="outlined"
                            size="small"
                            data-test-id="txtInputPassword"
                            placeholder="Password"
                            type={this.state.showPassTwo ? "text" : "password"}
                            name="password"
                            value={password}
                            onChange={this.handleChange}
                            error={passwordError !== ""}
                            helperText={passwordError}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginLeft: "-78px" }}
                                >
                                  <img src={Lock} alt="lock-icon" />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />
                        </FormControl>
                        {this.state.showPassTwo ? (
                          <img
                            onClick={this.EyeChangeTwo}
                            style={{
                              cursor: "pointer",
                              width: "2rem",
                              height: "27px",
                              position: "relative",
                              left: "25.5rem",
                              bottom: passwordError !== "" ? "6rem" : "4rem",
                            }}
                            src={Eye}
                            alt="eye"
                          />
                        ) : (
                          <img
                            onClick={this.EyeChangeTwo}
                            style={{
                              cursor: "pointer",
                              width: "2rem",
                              height: "27px",
                              position: "relative",
                              left: "25.5rem",
                              bottom: passwordError !== "" ? "6rem" : "4rem",
                            }}
                            src={Hideeye}
                            alt="eye"
                          />
                        )}
                        <Box className="LoginRememberMe">
                          <FormControlLabel
                            control={
                              <Checkbox
                                data-test-id="btnCheck"
                                style={{ fontSize: "15px" }}
                                color="primary"
                                checked={this.state.checkedRememberMe}
                                onChange={this.handleChangeCheck}
                                name="agree"
                              />
                            }
                            label="Remember Me"
                          />
                          <Typography
                            className="LoginForgotPwd"
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            <Link
                              onClick={this.handleForgotPassNavigation}
                              style={{
                                fontWeight: "bold",
                                fontSize: "15px",
                                color: "grey",
                                cursor: "pointer",
                              }}
                            >
                              Forgot Password?
                            </Link>
                          </Typography>
                        </Box>
                        <Typography
                          component="span"
                          className="LoginInputError"
                        >
                          {this.state.FieldError}
                        </Typography>
                        <Button
                          type="submit"
                          variant="contained"
                          className="LoginBtn"
                          data-test-id="btnLogIn"
                        >
                          Login
                        </Button>
                        <Typography variant="body2">
                          Don't have an account? &nbsp;{" "}
                          <a
                            onClick={this.handleSignUp}
                            style={{
                              fontWeight: "bold",
                              color: "#005487",
                              textDecoration: "none",
                              cursor: "pointer",
                              borderBottom: "2px solid",
                            }}
                          >
                            SignUp.
                          </a>
                        </Typography>
                      </form>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
    // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  mainContainerGrid: {
    height: "inherit",
  },
  logoCard: {
    width: "200px",
    height: "200px",
    boxShadow: "-1px 3px 13px -1px rgba(0,0,0,0.75)",
    borderRadius: "50%",
  },
  Login: {
    color: "#01548A",
    fontSize: "25px",
    fontWeight: "bold",
  } as const,
  InputField: {
    marginTop: "10px",
  },
  LoginBtn: {
    paddingLeft: "42%",
    paddingRight: "42%",
    textTransform: "none",
    backgroundColor: "#01548A",
    color: "#fff",
    fontWeight: "bold",
    borderRadius: "10px",
    marginTop: "5%",
    marginBottom: "5%",
  } as const,
  LoginSideImg: {
    width: "100%",
    height: "100%",
  },
};
const LeftImage = styled(Grid)({
  backgroundSize: "100% 100%", // Set the background size
  backgroundImage: `url(${SideImage})`,
});
export default LoginNew
// Customizable Area End