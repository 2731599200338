import React from "react";
// Customizable Area Start
import { Box , styled,Typography} from '@material-ui/core';
import ReportController, { Props, Reason } from "./ReportController.web";
import {tickcirclewhite,messagetext,arrowsquaredown,arrowleft } from "./assets";
export const configJSON = require("./config");
// Customizable Area End


export default class Report extends ReportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDropDownContent = () => {
    return (
      <div style={webStyles.reportDrop}>
        <div style={webStyles.reportMainTop}>
          <img
            onClick={this.goBackreport}
            src={arrowleft}
            alt="left-arrow"
            style={webStyles.reportDropLeftArrow}
          />
          <h2 style={webStyles.reportDropHeading}>Report</h2>
        </div>
        <div style={webStyles.reportMainBottom}>
          <h3 style={webStyles.reportMainBottomHeading}>
            Report something that doesn’t look right.
          </h3>
          <p style={webStyles.reportMainBottomDesc}>
            {configJSON.reportContent}
          </p>
          <h4 style={webStyles.reportMainBottomReasonHeading}>
            {this.state.postId
              ? configJSON.reportQuestion
              : configJSON.reportEventquestion}
          </h4>
          <div style={webStyles.dropDownContainer}>
            <div
              style={webStyles.reportDropNav}
              onClick={this.handleOpenDropDownButton}
              data-test-id="dropdown-id"
            >
              <div style={webStyles.reportDropNavLeft}>
                <img
                  src={messagetext}
                  alt=""
                />
                <p style={webStyles.reportDropNavLeftText}>
                  {this.state.showReason === ""
                    ? "Select"
                    : this.state.showReason}
                </p>
              </div>
              <div style={webStyles.reportDropNavRight}>
                <img src={arrowsquaredown} alt="" />
              </div>
            </div>
            {this.state.postError && (
              <Box component="p" color="red">
                {this.state.postError}
              </Box>
            )}
            <div style={webStyles.reportReasonContainer}>
              {this.state.openDropDown && (
                <div style={webStyles.reportDropDetails}>
                  {this.state.dropDownData.map(
                    (item: Reason, index: number) => {
                      return (
                        <CustomDiv
                          key={index}
                          style={
                            item.active
                              ? webStyles.reportDropDetailsItemActive
                              : webStyles.reportDropDetailsItem
                          }
                          data-test-id={item.title}
                          onClick={() => this.handleDetailButton(item.title, index)}
                        >
                          <CustomP
                            style={
                              item.active === true
                                ? webStyles.reportDropDetailsItemHeadingActive
                                : webStyles.reportDropDetailsItemHeading
                            }
                          >
                            {item.title}
                          </CustomP>
                          {item.active ? (
                            <img src={tickcirclewhite} />
                          ) : ""
                          }
                        </CustomDiv>
                      );
                    }
                  )}
                  <div style={webStyles.reportDropDownBtnContainer}>
                    <button
                    data-test-id="done-btn"
                      style={
                        this.state.selectedReason === ""
                          ? webStyles.mainButton
                          : webStyles.reportDropDownBtn
                      }
                      onClick={this.handleDoneButton}
                      disabled={this.state.selectedReason === "" ? true : false}
                    >
                      Done
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            style={webStyles.reportSubmitBtnContainer}
            data-test-id="submit report"
            onClick={this.reportApi}
          >
            <button style={webStyles.reportSubmitBtn}>Submit</button>
          </div>
        </div>
      </div>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    
    return (
      // Customizable Area Start
    //   <Box width={"100%"} className="main_event_box">
        
    //   </Box>
    <div className="report">
        {this.renderDropDownContent()}
        </div>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const CustomDiv = styled(Box)({
  width: "95%",
  padding: "15px 10px",
  margin: "2px 0px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  color:"#005487",
  "&:hover": {
    backgroundColor: "#005487",
    color:"white"
  },
});
const CustomP = styled(Typography)({
  margin: "0px",
  // color: "#004587",
  "&:hover":{
    color:"white",
  }
});
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
      borderRadius: "12px",
    },
  },
};

const webStyles:Record<string, React.CSSProperties> = {
  reportDrop: {
    width: "100%",
  },

  reportMainTop: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "15px",
    borderRadius: "10px 10px 0px 0px",
    marginBottom: "2px",
    backgroundColor: "white",
    padding: "15px 10px",
  },

  reportDropHeading: {
    margin: "0px",
    fontWeight: 600,
    color: "#005487",
  },

  reportDropLeftArrow: {
    height: " 25px",
    cursor:"pointer",
  },

  reportMainBottom: {
    height: "690px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    borderRadius: "0px 0px 10px 10px",
    backgroundColor: "white",
    padding: "20px",
    position: "relative",
  },

  reportMainBottomHeading: {
    margin: "0px",
  },

  reportMainBottomDesc: {
    margin: "10px 0px",
    color: "#7d7d7d",
  },

  reportMainBottomReasonHeading: {
    fontWeight: 800,
    color: "#005487",
    margin: "0px",
    marginTop: "18px",
    marginBottom: "10px",
  },

  dropDownContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "100%",
  },

  reportDropNav: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 0px 1px 1px rgb(220, 219, 219)",
    borderRadius: "10px",
    cursor: "pointer",
  },

  reportDropNavLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "20px",
    marginLeft: "10px",
  },

  reportDropNavRight: {
    marginRight: "10px",
  },

  reportDropNavLeftText: {
    color: "rgb(130, 123, 123)",
    fontWeight: 400,
  },

  reportReasonContainer: {
    width: "100%",
    marginTop: "10px",
  },

  reportDropDetails: {
    width: "100%",
    height: "100%",
    boxShadow: "0px 0px 4px 5px rgb(220, 219, 219)",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  reportDrop1:{
    width: "95%",
    padding: "15px 10px",
    margin: "2px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#005487",
    cursor:"pointer"
  },
   
  reportDropDetailsItem: {
    width: "95%",
    padding: "15px 10px",
    margin: "2px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  reportDropDetailsItemActive: {
    backgroundColor: "#005487",
  },

  reportDropDetailsItemHeading: {
    margin: "0px",
    // color: "#004587",
  },

  reportDropDetailsItemHeadingActive: {
    color: "white",
    margin: "0px",
  },

  reportDropDownBtnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    margin: "10px 0px",
  },

  reportDropDownBtn: {
    padding: "15px 0px",
    width: "150px",
    backgroundColor: "#005487",
    color: "white",
    border: "none",
    fontSize: "17px",
    marginRight: "10px",
    cursor:"pointer"
  },

  reportDropDownBtnDisable: {
    cursor: "not-allowed",
    backgroundColor: " #00548778",
  },

  mainButton:{
    padding: "15px 0px",
    width: "150px",
    color: "white",
    border: "none",
    fontSize: "17px",
    marginRight: "10px",
    cursor: "not-allowed",
    backgroundColor: "#00548778",
  },

  reportSubmitBtnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    alignSelf: "flex-end",
  },

  reportSubmitBtn: {
    width: " 70%",
    padding: "15px 0px",
    border: "none",
    backgroundColor: " #005487",
    color: "white",
    fontSize: "17px",
    fontWeight: 600,
    position: "absolute",
    bottom: " 14px",
    cursor: "pointer"
  },
};

// Customizable Area End
