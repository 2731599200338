import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { getSignupLoginTokens, handleErrorArray } from "../../../components/src/common";
// Customizable Area Start
import { toast } from "react-toastify"
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    data: {},
    isOtpSent: boolean;
    otpFormFields: string[];
    otpTiming: number;
    errorMessage: string;
    fullPhoneNumber: string;
    isDisabled:boolean;
    fieldError: string;
    otpRelatedError: string;
    timerTiming: any;
    isLoadingShow: boolean;
    otpValidateToken: string;
    isVerficationSkipped: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
export default class PhoneVerificationLoginFlowController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    sendOtpForPhoneVerificationApiCallId: string = "";
    verifyOtpForVerificationApiCallId: string = "";
    skipVerificationApiCallId: string = "";
    signupToken: string = "";
    loginToken: string = "";
    // Customizable Area End
    constructor(props: Props) {
        
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        
        this.state = {
            data: {},
            isOtpSent: false,
            otpFormFields: ['','','',''],
            otpTiming: 60,
            errorMessage: '',
            fullPhoneNumber: '',
            fieldError: '',
            otpRelatedError: '',
            isDisabled:false,
            timerTiming: 1000,
            isLoadingShow: false,
            otpValidateToken: "",
            isVerficationSkipped: false
        }

        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    
    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        console.log("apiRequestCallId", apiRequestCallId)
        this.setState({
            isLoadingShow: false
        });
        if(responseJson && responseJson.errors){
            handleErrorArray(responseJson.errors)
        }
        if(apiRequestCallId === this.sendOtpForPhoneVerificationApiCallId){
            this.handleSendOtpForVerificationApiResponse(responseJson);
        }
        if(apiRequestCallId === this.verifyOtpForVerificationApiCallId){
            this.handleVerifyOtpVerificationApiResponse(responseJson);
        }
        if(apiRequestCallId === this.skipVerificationApiCallId){
            this.handleSkipVerificationApiResponse(responseJson);
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount(): Promise<void> {
        const { signupToken, loginToken } = await getSignupLoginTokens();
        this.signupToken = signupToken;
        this.loginToken = loginToken;
    }
    handlePhoneNumberChange = (e: any) => {
        const phoneRegex = /^[0-9\b]+$/;
        this.setState({errorMessage: "", fieldError: ""});
        if (e.target.value === "" || phoneRegex.test(e.target.value)) {
            this.setState({ fullPhoneNumber: e.target.value })
        }
    };

    handleOtpKeydown=(e:any, id:any)=>{
        const inputs = document.querySelectorAll('input[type="number"]');
        const value = e.target.value;
        if (value.length === 0 && e.key==="Backspace") {
            const nextInput = inputs[id - 1];
            if (nextInput) {
             (nextInput as HTMLElement).focus();
            }}
    }

    handleOTPInputChange = (e: any, index: number) => {
        this.setState({otpRelatedError:"",fieldError:""});
        const value = e.target.value;
        const otpFormFields = [...this.state.otpFormFields];
        otpFormFields[index] = value;
        this.setState({ otpFormFields });
        const inputs = document.querySelectorAll('input[type="number"]');

        if (value.length === 1) {
            const nextInput = inputs[index + 1];
            if (nextInput) {
             (nextInput as HTMLElement).focus();
            }}

    };

    validatePhoneNumber = () => {
        let isValidPhoneNumber = true;
        if(this.state.fullPhoneNumber.trim().length === 0){
            this.setState({
                errorMessage: "Please Enter Phone number"
            });
            isValidPhoneNumber = false;
        }
        return isValidPhoneNumber;
    }

    validateOtp = () => {
        let isValid = true;
        this.state.otpFormFields.forEach((otp: string) => {
            if(otp.trim().length === 0){
                isValid = false;
            }
        });
        if(!isValid){
            this.setState({fieldError:"", otpRelatedError: "Enter 4 digit OTP"});
        }
        return isValid;
    }

    handlePhoneNumberSubmit = (e: any) => {
        e.preventDefault();
        this.showLoading();
        this.setState({
            otpFormFields: ["","","",""]
        })
        if(!this.validatePhoneNumber()){
            return false;
        }

        const bodyData = {
            data: {
                attributes:{
                    full_phone_number: this.state.fullPhoneNumber
                }
            }
        }

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.loginToken || this.signupToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.sendOtpForPhoneVerificationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendOtpForLoginVerificationApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.sendOtpForLoginVerificationApiMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(bodyData),
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleOtpSubmit = (e: any) => {
        e.preventDefault();
        this.showLoading();
        if(!this.validateOtp()){
            return false;
        }

        const httpBody = {
            "otp_token": this.state.otpValidateToken,
            pin: this.state.otpFormFields.join('')
        };

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.loginToken || this.signupToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.verifyOtpForVerificationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.verifyOtpForLoginVerificationApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.verifyOtpForLoginVerificationApiMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody),
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleSkipVerificationClick = () => {

        this.setState({
            isVerficationSkipped: true
        });
       toast.success("veirfication will be skipped for 30 days, Verify your Phone number!")
    }

    skipVerificationApiCall = () => {
        this.showLoading();

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.loginToken || this.signupToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.skipVerificationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.skipVerificationApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.skipVerificationApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    showLoading = () => {
        this.setState({
            isLoadingShow: true
        });
    }

 

    startTimer = () => {
        // Disable the button to prevent multiple clicks
        this.setState({ isDisabled: true });
    
        // Set the timer for 60 seconds
      let timerInterval = setInterval(() => {
          this.setState((prevState) => ({
           otpTiming: prevState.otpTiming - 1,
          }));
    
          // Check if the timer has reached 0
          if (this.state.otpTiming < 0) {
            // Enable the button and reset the timer
            this.setState({
              otpTiming: 60,
              isDisabled: false,
            });
    
            // Clear the interval to stop the timer
            clearInterval(timerInterval);
          }
        }, 1000);
      };
    

    handleSendOtpForVerificationApiResponse = (responseJson: any) => {
        if(responseJson.meta){
            this.setState({
                otpValidateToken: responseJson.meta.token,
                isOtpSent: true,
               
            });
            this.startTimer();
         
        } else if(responseJson.errors){
            handleErrorArray(responseJson.errors);
            this.setState({
                fieldError: responseJson.errors[0]?.phone || responseJson.errors[0]?.full_phone_number
            })
        }
    }

    handleVerifyOtpVerificationApiResponse = (responseJson: any) => {
        if(responseJson && responseJson.messages){
            toast.success(responseJson.messages[0].otp);
            this.props.navigation.navigate("LandingScreen");
            if(this.state.isVerficationSkipped){
                this.skipVerificationApiCall();
            }
        }
    }

    handleSkipVerificationApiResponse = (responseJson: any) => {
        
        if(responseJson && responseJson.message){
            toast.success(responseJson.message);
            this.props.navigation.navigate("LandingScreen");
        }
    }

    // Customizable Area End
    
}
// Customizable Area End