import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities"
import { getNotificationPermission } from "../../../components/src/firebase";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config");
export const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
export const validPasswordRegex= RegExp(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]|\\:;"'<,>.?/~`])(?!.*\s).{8,}$/);
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: any,
  password: any,
  password_confirmation: any,
  agree: boolean,
  showPassTwo:any,
  errors: {
    email: string,
    password: string,
    password_confirmation: string,

  },
  modalVisible:boolean,
  isLoading: boolean,
  FieldError: any,
  agreeError:boolean,
  agreeErrorMsg: string
  showPassTwoConfirm: boolean;
// Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}


export default class SignInNewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId: any = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
     
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
   
    ]

    this.state = {
      email: "",
      password_confirmation: "",
      password: "",
     
      agree: false,
      showPassTwo:false,
      showPassTwoConfirm:false,
      errors: {
        email: '',
        password: '',
        password_confirmation: '',
      },
     
      
      modalVisible: false,
      isLoading: false,
      agreeError:false,
      FieldError: "",
      agreeErrorMsg: 'Please Accept Terms and Conditions'
  
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    let userData = await localStorage.getItem("LoggedInUserData");

    if(userData)
    {
     this.props.navigation.navigate("LandingScreen");
    }
  }
  
  SignUpValidation = (name: string, value: any) => {
    let formErrors = this.state.errors;

    switch (name) {
      case 'email':
        formErrors.email =
          validEmailRegex.test(value)
            ? ''
            : configJSON.errorEmailNotValid;
        break;
      case 'password':
        formErrors.password =
        validPasswordRegex.test(value) ? '':'Password should contain minimum 8 characters, 1 uppercase, 1 number, 1 special character';
           
        
        break;
   
   
   
case 'password_confirmation':
       
        formErrors.password_confirmation =
       
        value !== this.state.password ? 'Passwords do not match' : '';
        
        
        this.SignUpValidation('password', this.state.password)
     
        break;
     
        default:


        break;
    }
  
    this.setState((prev) =>   
    {



      return {
 ...prev,
       [name]: value,
  error: formErrors,
  };
       });
   
  }
handleChange = (event: any) => {



   
 event.preventDefault();
        const { value, name } = event.target;
  this.SignUpValidation(name, value)


  }

  EyeChangeTwo=()=>{
    this.setState({showPassTwo:!this.state.showPassTwo})
  }

  EyeChangeTwoConfirm=()=>{
    this.setState({showPassTwoConfirm:!this.state.showPassTwoConfirm})
  }

  handleLogin=()=>{
    this.props.navigation.navigate("LogIn");
    }
    

  setAgree = (event:any) => {
    this.setState({ agree: event.target.checked});
    if(!this.state.agree)
    {
      this.setState({agreeError: false})
    }
    else{
      this.setState({agreeError: true})
    }
  };
handleClose=()=>{

  this.setState({modalVisible: false});
}

handleOpen=()=>{
  this.setState({modalVisible: true});
}

  handleAccept = () => {

       this.setState({modalVisible:false})

  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
  

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    console.log("apiRequestCallId", apiRequestCallId)

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  

      if (apiRequestCallId && responseJson) {
        
        if (apiRequestCallId === this.createAccountApiCallId) {

          if (!responseJson.errors) {
           
 setStorageData("SignupSuccessToken", responseJson.meta.token)
                this.props.navigation.navigate('PhoneVerification');
                localStorage.setItem('community','false')
                localStorage.setItem("SelectedScreenId" , "1")
                localStorage.setItem("privatePost","")
                localStorage.setItem('communityData',"")
          } else 
               {          
              this.setState({ FieldError: responseJson.errors[0]?.account })           
    }
        }
      }
    
 if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
 const otpAuthTkn = message.getData(
 getName(MessageEnum.AuthTokenDataMessage)
         );
   if (otpAuthTkn && otpAuthTkn.length > 0) {
  runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }



  }

handleSubmit = async (e: any) => {
       e.preventDefault();
         const { email, password, password_confirmation } = this.state;
let formErrors = this.state.errors;

if (!email ) {
     formErrors.email =
     configJSON.EmailRequired
    }

 if(!this.state.agree)
    {
      this.setState({agreeError: true})
    }
     if (!password) {
      
      formErrors.password =
      configJSON.PasswordRequired
    }

    if (!password_confirmation) {
      
      formErrors.password_confirmation =
      configJSON.passwordConfirmRequired
    }

    if(this.state.password !== this.state.password_confirmation){
      this.setState({
        errors:{
          ...this.state.errors,
          password_confirmation: configJSON.passwordConfirmationValidationErrormessage
        }
      })
      return;
    }
    

    this.setState({ isLoading: true });

    const deviceId = await getNotificationPermission();

    const attrs = {
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      device_id: deviceId ?? ""
    };
if(this.state.email && this.state.password && this.state.password_confirmation && this.state.agree && this.state.password===this.state.password_confirmation && formErrors.email==="")
{
  const header = {
    "Content-Type": configJSON.contentTypeApiAddDetail,
   
  };


  const data = {
    type: "email_account",
    attributes: attrs,
  };

  const httpBody = {
    data: data,

  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createAccountApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.accountsAPiEndPointWeb,

  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header),

  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody),

  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeAddDetail,

  );
  runEngine.sendMessage(requestMessage.id, requestMessage);


  return true;
}

  }
 // Customizable Area End

}