import { IBlock } from "../../../framework/src/IBlock";
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import { DateObject } from "react-multi-date-picker";
import ApiCall from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export interface EventData {
  data: EventData[];
  id: string,
  message:string,
  attributes: {
     id: number,
     cover_image: string,
      event_typ: string,
      event_name: string,
      event_url: string,
      description: string,
      start_date: string,
      end_date: string,
      full_name: string,
      start_time:string,
      end_time: string,
      interest_event_count: [
        {
          account_images: [
            { photo: string },
          ],
          count: null
        }
      ],
  };
}

export interface Interasred{
    photo: string | undefined;
}

export interface SelectedDate {
  toDate: () => Date;
}



export interface EventErrorResponse {
  errors: [
  {
      token: string
  }
]

}
// Customizable Area End
export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  arrayHolder: Array<EventData>;
  selectAppBar: string;
  selectedDate:Date | null;
  newArraydaily: Array<EventData>;
  open: boolean;
  loading: boolean;
  date: string;
  colorFlag: boolean;
  // Customizable Area End
}

interface SS {
 // Customizable Area Start
 // Customizable Area End
}
export default class CalenderEventsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getEventApiCallId: string = "";
  apiCreateEventPostCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      arrayHolder: [],
      selectAppBar: "daily",
      selectedDate: new Date(),
      newArraydaily: [],
      open: false,
      loading: false,
      date: "",
      colorFlag: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  NavigateToCalendarDetails = async (e: string) => {
    this.props.navigation.navigate('CalendarEventDetails',{id:e})
  }

  handleNavigate = () => {
    this.props.navigation.navigate("LandingScreen")
    localStorage.setItem("SelectedScreenId", "1");
    localStorage.setItem("openmodal", "true");
  }

  handleheader = (e: string) => {
    this.setState({ selectAppBar: e })
    this.getListRequest(e)
  }

  async componentDidMount() {
    super.componentDidMount();
    localStorage.setItem('eventDetails',"")
    this.getListRequest(this.state.selectAppBar)
  }

  handleheaderDaly = (selectedDate:any) => {

    console.log(selectedDate,"idjhsfohj")
            const day = selectedDate.toDate().getDate();
            const month = selectedDate.toDate().getMonth() + 1;
            const year = selectedDate.toDate().getFullYear();
            const formattedDay = String(day).padStart(2, "0");
            const formattedMonth = String(month).padStart(2, "0");
            console.log(`${formattedDay}/${formattedMonth}/${year}`,"gfgi");
            this.setState({ date: `${formattedDay}/${formattedMonth}/${year}` }, () => this.handleDate());
}

  handleDate = () => {
  
    this.getListRequest("")
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        this.responseSucessCell(apiRequestCallId, responseJson)
      } else if (responseJson && responseJson.errors) {
        this.responseFailureCall(apiRequestCallId, responseJson)
      }
    }
  }

  responseSucessCell = async (apiRequestCallId: string, responseJson: EventData) => {
    if (apiRequestCallId === this.getEventApiCallId) {
      this.getListRequestSucessCallBack(responseJson);
    }
  }
  ChangeMonth=(selectedDate:any)=>{

    console.log(selectedDate,"Selected Date")
  
    const day = selectedDate.toDate().getDate();
    const month = selectedDate.toDate().getMonth() + 1;
    const year = selectedDate.toDate().getFullYear();
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
    console.log(`${formattedDay}/${formattedMonth}/${year}`,"gfgi");
    this.setState({ date: `${formattedDay}/${formattedMonth}/${year}` }, () => this.handleDate());
  }

  responseFailureCall = async (apiRequestCallId: string, responseJson: EventErrorResponse) => {
    if (apiRequestCallId === this.getEventApiCallId) {
      this.getListRequestFailureCallBack(responseJson);
    }
  }

  getListRequest = async (e: string) => {
    this.setState({ loading: true })
    this.getEventApiCallId = await ApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.dailyEvent}${e!=""?e:this.state.selectAppBar}&event_date=${this.state.date}`
    });
  };

  getListRequestSucessCallBack = (response: any) => {
   

    if(response.message)
    {
      this.setState({colorFlag:false, loading: false,arrayHolder: response.data, newArraydaily: response.data})
      toast.error(response?.message)
      this.forceUpdate()
    }
    else
   { this.setState({ loading: false, arrayHolder: response.data, newArraydaily: response.data, colorFlag:true })
   this.forceUpdate()}

    
  };





  getListRequestFailureCallBack = (response: EventErrorResponse) => {
    this.setState({ loading: false })
    toast.error(response.errors[0].token)
  };
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}

