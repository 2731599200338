// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme , styled} from "@material-ui/core/styles";
import NewPostBlockController , { Props , POST_TYPES } from "./NewPostBlockController.web"
import { Typography, Box, Grid, Avatar, CircularProgress, Fade, Backdrop, Modal } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import {  Like, CommentFill,fillLike } from "./assets";
import CommentPostBlock from "./MainCommentPost.web";
import CustomButton from "../../../components/src/CustomButton.web";
import "./NewPostBlock.web.css";
import ReactPlayer from 'react-player'
import PostAction from "./PostAction.web";
// Customizable Area End

// Customizable Area Start

/*istanbul ignore next */
const VideoPostUrl = (props: any) => {
    const { postType,url,classesOne,ClassesTwo ,handleOpenLink ,propsPostType} = props;
    return (
      <>
         {(postType==="YouTube" || postType==="Vimeo") && <Box style={{width:"104%",marginLeft:"-14px"}}>
                              
                              <ReactPlayer
                               url={url}
                                  playing={true}
                                  controls={true}
                                  width='100%'
                                  height='414px'
                                  light={true}

                               />
                              <br></br>
                              <Typography
                                  variant="body2"
                                  className={`${classesOne} Heading4 PrimaryColor2`}
                           
                                  onClick={handleOpenLink}

                              >
          
                                  <a href={url}  className={ClassesTwo}>{propsPostType=="YouTube"?"YOUTUBE.COM":"VIMEO.COM"}</a>
                              </Typography>
                              </Box>}
      </>
    );
  };

const Poststyles = (theme: Theme) =>
    createStyles({
        title: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            display: 'none',
        },
        descriptionEllipsis:{
            overflow:"hidden",
            textOverflow:"ellipsis",
            whiteSpace:"nowrap"
        },
        descriptionFull:{
            display:"block",
            wordWrap:"break-word"
        },
        cursorPointer:{
            cursor:"pointer"
        },
        urlLink:{
            textDecoration:"underline",
            display:"block",
            wordWrap:"break-word"
        },
        anchorLink:{
            textDecoration:"none",
            color:"#808080",
            marginLeft:"1rem"
        }
    })
// Customizable Area End
  /*istanbul ignore next */
export class NewPostBlock extends NewPostBlockController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }

    renderLikeListModal = () => {
        return (
            <LikeModal
                onClose={this.handleCloseLikeCount}
                open={this.state.likeListModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200
                }}
            >
                <Fade in={this.state.likeListModal}>
                    <Box sx={{ width: { xs: "75%", sm: "60%", md: "50%", lg: "35%" } }} className="fadeContainer" display="flex" alignItems="center" justifyContent="center" >
                        <Box bgcolor="#fff" borderRadius="12px" width="100%">
                            <Box sx={{ border: "1px solid black", padding: "20px",margin: "20px", borderRadius: "12px " }}>
                                <Box component="p" color="#005487" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"20px"} mt={0}>
                                    Likes
                                </Box>
                                <Box className="gap-10" sx={{ margin: "auto", height: "340px" }} >
                                    {this.state.loadingpostLike ? 
                                      <Box margin={"auto"}> <CircularProgress /></Box>
                                    : this.state.postLikeUserList.map(item => {
                                        return (
                                            <Box key={item.name} display="flex" >
                                                <Avatar className="avatar" src={item.profile_pic} data-test-id="like-profile-img" onClick={()=>this.userRedirection(item.id,item.type)}/>
                                                <Box component="p" fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"} m={0} ml={"12px"}>{item.name}</Box>
                                            </Box>
                                        )
                                    }
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </LikeModal>
        )
    }

    renderComments=()=>{
        return(
            this.state.openComments ? (
                <CommentPostBlock 
                navigation={this.props.navigation} 
                id={""}
                data={this.state.postData}
                refreshData={this.singlePostDataChange}                  
                />
            ) : null
        )
    }

    renderCountSection=()=>{
        return(
        !this.state.openComments && <PointerBox display={"flex"} fontSize={"12px"} fontFamily={"Verdana"} justifyContent={"space-between"} margin="auto"width="90%" color="#808080">
                        <Box component={"p"} display={"flex"} data-test-id="post-like-count" className="pointer gap-5" width="max-content" onClick={this.handleOpenLikeCount}>{this.showLike()}</Box>
                        <Box component={"p"} className="pointer" onClick={this.openComments}>{this.showComment()}</Box>
                    </PointerBox>
        )
    }

    renderpostAction=()=>{
        return(
            <>
            <Box>
            <img src={(this.state.postData?.attributes && this.state.postData.attributes.is_liked) ? fillLike : Like} alt="like" style={webPage.imgStyle} onClick={
               this.handleLikeAction
            } />
        </Box>
        <Box display="flex" alignItems="center" 
        >

            <img src={CommentFill} data-test-id={"comment-id"} alt="comment" style={webPage.imgStyle}  onClick={this.openComments}/>
        </Box>
        {this.state.postData?.attributes&&<PostAction communityData={this.props.communityData} flag={this.props.flag} communityFollow={this.props.communityFollow} type={"share"} data={this.state.postData} navigation={this.props.navigation} postList={this.props.postList} id={""} refreshPost={this.singlePostDataChange}  editPost={this.props.editPost} followUpdatedCommunityTab={this.props.followUpdatedCommunityTab} followUpdatedList={this.props.followUpdatedList} deletePost={this.props.deletePost}/>}
        </>
        )
    }

    renderOptions=()=>{
        return(
            <>
                {this.state.postData?.attributes&&<PostAction communityData={this.props.communityData}  flag={this.props.flag} communityFollow={this.props.communityFollow} type={"options"} data={this.state.postData} navigation={this.props.navigation} id={""} refreshPost={this.singlePostDataChange} postList={this.props.postList} editPost={this.props.editPost} followUpdatedCommunityTab={this.props.followUpdatedCommunityTab} followUpdatedList={this.props.followUpdatedList} deletePost={this.props.deletePost}/>}
            </>
        )
    }

    renderSharedField=()=>{
        const { post_shared_by, community_shared_by, like_by, comment_by, shared_account_profile, shared_community_profile} = this.props.data.attributes;
        const author= post_shared_by || community_shared_by || like_by || comment_by;
        const image= shared_account_profile || shared_community_profile
        let message = '';
        if (post_shared_by || community_shared_by) {
            message = 'share this post';
          } else if (like_by) {
            message = 'like this post';
          } else if (comment_by) {
            message = 'commented on this post';
          }
          
        return(<>
        {
            author && <ShareBox p={"10px"} pl={"15px"} display={"flex"} alignItems="center" fontFamily= "Verdana, Geneva, Tahoma, sans-serif" borderBottom={"1px solid #bdbdbd"}>
                {shared_account_profile?<Avatar src={image} className="accountImage"/>:
                <Avatar src={image} className="communityImage"/>}
                <Box component={"p"} fontWeight={"bold"} m={0} mx={"8px"} display="flex" data-test-id="label-id">{author}<Box m={0} ml={"8px"} color="#808080" fontWeight={"normal"}>{message}</Box>
                </Box>
            </ShareBox>
        }
            </>
        )
    }

    subRender=()=>{
        const {
       
            PostId,
            classes,
           
            eventDescription,
           
        } = this.props
        return(<>
             <Typography variant="body2" color="textSecondary" component="p" style={{ color: "#000",paddingBottom:'10px',paddingTop:"10px", fontSize: "18px",  marginTop:"-7px" }}>
                                    {eventDescription.substring(0,70)}
                                   {(eventDescription.length > 70 && !this.state.expandDesc) && <Typography
                                        variant="body2"
                                        className={`Heading4 FontColor2 ${classes.cursorPointer}`}
                                        onClick={()=>this.eventDetailNavigation(PostId)}
                                        component="p"
                                    >
                                        {`Read More`}
                                    </Typography>}
                                    </Typography>
        </>)
    }

    renderText = () =>{
        return (
            <>
                {
                        this.props.postType === POST_TYPES.TextPost ? 
                        (
                             <CardContent>
                            
                                <Typography
                                        variant="body2"
                                        className={`${this.props.classes.descriptionFull} Heading4`}
                                        component="p"
                                    >
                                    <div  dangerouslySetInnerHTML={{ __html: this.replaceUrl(this.props.description) }}></div>
                                    </Typography>
            
                             </CardContent>
                        ) :
                        (
                            <>
                                <CardContent>
                                   {this.props.description && this.props.itemType!=='event' &&  POST_TYPES.Link !== this.props.postType && <Box>
                                    <Typography
                                        variant="body2"
                                        className={this.state.expandDesc ? `${this.props.classes.descriptionFull} Heading4 FontColor6` :`${this.props.classes.descriptionEllipsis} Heading4 FontColor6`}
                                        component="p"
                                    >
                                    <div  dangerouslySetInnerHTML={{ __html: this.replaceUrl(this.props.description) }}></div>
                                    </Typography>
                                    {(this.props.description?.length > 70 && !this.state.expandDesc) && <Typography
                                        variant="body2"
                                        className={`Heading4 FontColor2 ${this.props.classes.cursorPointer}`}
                                        onClick={this.handleExpandDescription}
                                        component="p"
                                    >
                                        {`Read More`}
                                    </Typography>}
                                    </Box>
                                    }
                                <VideoPostUrl postType={this.props.postType} url={this.props.url} classesOne={this.props.classes.cursorPointer} ClassesTwo={this.props.classes.anchorLink} handleOpenLink={()=>this.handleOpenLink(this.props.url)} propsPostType={this.props.postType} />
                                </CardContent>
                     {this.props.videoTitle &&  <p className="para" style={{marginLeft:"15px",maxWidth:"54rem",width:"auto"}}>{this.props.videoTitle}</p>}

                     {( POST_TYPES.Image === this.props.postType && this.props.url) &&  <Typography
                                        variant="body2"
                                        className={`${this.props.classes.cursorPointer} Heading4 PrimaryColor2`}
                    

                                    >
                                
                                        <a href={this.props.url} target="_blank"style={{marginLeft:"17px",marginTop:"-13px"}}>123{this.props.url}</a>
                                    </Typography>
                                    
                        }
                                   {( POST_TYPES.Link === this.props.postType && this.props.url) &&  <><Typography
                                        variant="body2"
                                        className={`${this.props.classes.cursorPointer} Heading4 PrimaryColor2`}

                                    >
                                
                                        <a href={this.props.url} target="_blank" style={{marginLeft:"17px",marginTop:"-13px"}}>{this.props.url}</a>
                                    </Typography>
                                    <br></br>
                                    <Typography

                                    style={{marginLeft:"17px"}}
                                        variant="body2"
                                        className={this.state.expandDesc ? `${this.props.classes.descriptionFull} Heading4 FontColor6` :`${this.props.classes.descriptionEllipsis} Heading4 FontColor6`}
                                        component="p"
                                    >
                                    <div  dangerouslySetInnerHTML={{ __html: this.replaceUrl(this.props.description) }}></div>
                                    </Typography>
                                    </>
                                    
                        }
                  

                           {(this.props.src.length!==0 && this.props.postType!=="YouTube" && this.props.postType!=="Vimeo")&&
                             <Card>
                       <CardMedia
                                    className={this.props.mediaPost}
                                    style={{marginTop:"3px",...webPage.mediaPost}}
                                    image={this.props.src}
                                />
    </Card>
                                }   

                            </>
                        )
                    }
            </>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const {
            id,
            PostId,
            classes,
            profile,
            fullName,
            description,
            createdAt,
            url,
            src,
            postCards,
            mediaPost,
            postType,
            itemType,
            eventName,
            startDate,
            endDate,
            eventType,
            interestedBtnHighlight,
        } = this.props
        return (
            <Grid className={`NewPostBlock`} item xs={12} key={id}>
                <Card className={postCards} style={{overflow:"unset"}}>
                {this.renderLikeListModal()}
                {this.renderSharedField()}
    
                   <CardHeader
                   avatar={
                    Boolean(this.state.postData?.attributes?.account_id) ?
                       <img  alt="" src={profile} style={{  borderRadius: "50%",width: "50px", height: "50px" }} />:
                       <img  alt="" src={profile} style={{  borderRadius: "3%",width: "50px", height: "50px" }} />
                   }
                   action={
                      this.state.postData?.attributes?.post_type !== "welcome_post" && this.renderOptions()
                   }
                   title={
                      <Box  flexDirection="column" display={"flex"}>
                        <Typography  className={`Heading4 FontColor6 BoldFont TextCapitalize`} variant="body2"> {fullName}</Typography>
                        <Box alignItems={"center"} flexDirection="row"  display={"flex"}   >
                        <Typography className={`Heading6 FontColor2`}  component="span" >
                        {createdAt}</Typography>
                        <Typography style={webPage.greyDot} component={"span"} ></Typography>
                        <Typography className={`Heading6 FontColor2`} component="span"  >
                        Public 
                        </Typography>
                        </Box>
                      </Box>
                   }
               />
                 
                {this.renderText()}

{
                        itemType==="event" &&  
                        <CardContent>
 <Box display={"flex"} alignItems="start" justifyContent={"start"} style={{marginTop:"8px",marginLeft:"14px"}} >
                            <div> <Typography component={"span"} className={`Heading3 BoldFont FontColor6`}>{`${eventName.slice(0,17)} - ( ${eventType} )`}</Typography>
                              {this.subRender()}
                                   <Typography variant="body2" style={{ color: "#008194", fontWeight: "bold", fontSize: "14px", textAlign: "start" ,marginTop:"-6px" }}>{`${this.state.startTime} - ${this.state.endTime}`}</Typography></div>
                                  
                                   {startDate.substring(0,6)===endDate.substring(0,6)? <Typography variant="body2" style={{ color: "#008194", fontWeight: "bold", fontSize: "14px", textAlign: "start",marginLeft:"29.5rem",marginTop:"8px",width:"2px",position:"relative",left:"-7rem" }}>{`${startDate.substring(0,6)}`}</Typography>:
                                   <Typography variant="body2" style={{ color: "#008194", fontWeight: "bold", fontSize: "14px", textAlign: "start",marginLeft:"29.5rem",marginTop:"8px",width:"2px",position:"relative",left:"-7rem" }}>{`${startDate.substring(0,3)}`} - {`${endDate.substring(0,3)} `} {`${endDate.substring(3,6)} `}</Typography>}
                      </Box>
                        </CardContent>
                       
                    }
                    {
                        itemType==="event" &&  <Box display={"flex"} alignItems="center" justifyContent={"center"} style={{margin:"21px"}}>
                                 <CustomButton  data-test-id={"eventdetails"} btnStyle={webPage.Publishbtn} btnText={"View Event"} buttonId="user-profile-add-social-publish-btn" type="submit" handleButtonClick={()=>this.eventDetailNavigation(PostId)}  />
                                 <CustomButton btnStyle={interestedBtnHighlight ? webPage.Publishbtn : webPage.InterestBtn} btnText={"Interested"} buttonId="user-profile-add-social-publish-btn" type="submit" handleButtonClick={this.handleInterestedEvent}  data-test-id={"interested"} />
                      </Box>
                    }
                    { (this.state.postData?.attributes?.post_type !== "welcome_post" && this.state.postData?.type !== "event") && <>
                   {this.renderCountSection()}
                    <CardActions style={{ display: "flex", justifyContent: "space-around" }}>
                       {this.renderpostAction()}
                    </CardActions>
                    </>}
                </Card>
                {
                  this.renderComments()
                }
            </Grid>

        );
        // Customizable Area End
    }

}


// Customizable Area Start
const webPage = {
    upcomming: {
        display: "flex",
        justifyContent: "space-around",
        bgcolor: "#fff",
        alignItems: "center",
        borderRadius: "12px",
        background: "#fff",
        padding: "10px",
        marginBottom: "3px",
    },
    cardMedia: {
        height: 0,
        paddingTop: '100%', // Maintain a 1:1 aspect ratio (change this value to adjust the aspect ratio)
      },
      image: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      },
    imgStyle: {
        width: "20px",
        cursor: "pointer"
    },
    Publishbtn: {
        background: "#01548a",
        color: "#fff",
        width: "95%",
        borderRadius:"0px",
        padding:"18px",
        margin:"9px"
    },
    InterestBtn: {
        background: "#fff",
        color: "#01548a",
        width: "95%",
        borderRadius:"0px",
        padding:"18px",
        margin:"9px",
        borderColor:"#01548a",
    },
    cardActionIcon:{
        marginTop:"9px",
        marginRight:"9px",
        width: "20px",
        // cursor: "pointer"
    },
    commentStyle:{
        background:"#F4F4F4",
        padding:"10px",
        borderRadius:"10px"
    },
    CommentuserimgStyle:{
        width:"40%"
    },
    commentMsg:{
        borderTop:"1px solid #e4e4e4",
        marginTop:"10px"
    },
    messageField:{
        background:"#e4e4e4",
        borderRadius:"20px",
        width: "100%",
    },
    greyDot:{
        width:"6px",
        height:"6px",
        borderRadius:"50%",
        backgroundColor:"#d9d9d9",
        display:"inline-block",
        margin:"0px 5px"
    },
    mediaPost: {
        maxWidth: "100%",
        height: "300px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }
}
const ShareBox = styled(Box)({
    "& .communityImage":{
        width:"22px",
        height: "22px",
        borderRadius:"3%"
    },
    "& .accountImage":{
        width:"22px",
        height: "22px"
    }
})
const PointerBox = styled(Box)({
    "& .pointer":{
        cursor: "pointer"
    },
    "& .gap-5":{
        gap: "5px"
    }
})

const LikeModal = styled(Modal)({
    "& .fadeContainer": {
        backgroundColor: "transparent",
        display: "flex",
        transform: "translate(-50%, -50%)",
        margin: "auto",
        left: "50%",
        top: "50%",
        position: "absolute",
    },
    "& .gap-10": {
        gap: "10px",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column"
    },
    "& .avatar": {
        cursor: "pointer",
    }
});
export default withStyles(Poststyles, { withTheme: true })(NewPostBlock);
// Customizable Area End