// Customizable Area Start
import React from "react";
import { Grid , Box , Menu, MenuItem, Avatar , Typography} from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ViewTypePostStepOneController, { Props } from "./VideoTypePostStepOneController.web";
import {youtubeIcon, vimeoIcon} from "./assets"
import "./VideoTypePost.web.css";
import { VIDEO_TYPES } from "./VideoTypePostStepTwoController.web";
// Customizable Area End

// Customizable Area Start
const Poststyles = (theme: Theme) =>
    createStyles({
        title: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            display: 'none',
        },
        videoText:{
            color: "#01548a",
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "10px"
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2, 4, 3),
            borderRadius: "10px",
        },
        mainGrid:{
            width:"100%",
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
        },
        menuIcon:{
          width: "24px",
          height: "24px",
          objectFit: "contain",
        },
        videoItemGrid:{
            width:"100%",
            display:"flex",
            alignItems:"center",
            padding:"5px 3px",
            fontFamily:"Verdana"
        }
    })
// Customizable Area End


export class ViewTypePostStepOne extends ViewTypePostStepOneController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { classes , open } = this.props
        return (
            <Menu
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            anchorEl={this.props.uploadVideoPostAnchor}
            open={open}
            onClose={this.props.handleMenuClose}
            getContentAnchorEl={null}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            className={"VideoPostStepOne VideoPostModal"}
          >
            <Box className="VideoStepOneModalBox">
              <MenuItem
                className="MenuItemVideoStepOne"
                onClick={()=>this.handleOpenStepTwoModal(VIDEO_TYPES.Youtube)}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={4}
                    className="MenuItemVideoStepOneImg"
                  >
                    <Avatar
                      src={youtubeIcon}
                      variant="square"
                      className={classes.menuIcon}
                    />
                  </Grid>
                  <Grid item xs={8} className="MenuItemVideoStepOneGrid">
                    <Typography
                     component="span"
                     className={`${classes.videoTypo}`}
                    >
                      Youtube Embed
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
              <MenuItem
                className="MenuItemVideoStepOne"
                onClick={()=>this.handleOpenStepTwoModal(VIDEO_TYPES.Vimeo)}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={4}
                    className="MenuItemVideoStepOneImg"
                  >
                    <Avatar
                      src={vimeoIcon}
                      variant="square"
                      className={classes.menuIcon}
                    />
                  </Grid>
                  <Grid item xs={8} className="MenuItemVideoStepOneGrid">
                    <Typography
                      component="span"
                      className={`${classes.videoTypo}`}
                    >
                     Vimeo Embed
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            </Box>
          </Menu>
        )
    }
    // Customizable Area End
}

// Customizable Area Start

export default withStyles(Poststyles, { withTheme: true })(ViewTypePostStepOne);
// Customizable Area End