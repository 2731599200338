// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ApiCall from "../../../components/src/ApiCall.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tab: boolean;
  openPopOver: boolean;
  interested: boolean;
  ID: any;
  eventDetails:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EventDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  EventsDetailsAPICallId: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      tab: true,
      openPopOver: false,
      interested: false,
      ID: "",
      eventDetails:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const eventID = this.props.navigation.history.location.pathname.replace(
      /\D/g,
      ""
    );
    this.eventDetailsgetApiRequest(eventID)
  }

  eventDetailsSucessCallBack = (res: any) => {
    
    this.setState({ eventDetails: res.data })
  };
  eventDetailsFailureCallBack = (res: any) => {
    console.log("@@@ ====== eventDetails  Failure success call back", res);
  };

  
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        this.responseSucessCell(apiRequestCallId, responseJson)
      } else if (responseJson && responseJson.errors) {
        this.responseFailureCall(apiRequestCallId, responseJson)
      }
    }
  }


  responseSucessCell = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.EventsDetailsAPICallId) {
      this.eventDetailsSucessCallBack(responseJson);
    }

  }

  responseFailureCall = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.EventsDetailsAPICallId) {
      this.eventDetailsFailureCallBack(responseJson);
    }

  }


  eventDetailsgetApiRequest = async (id:any) => {
    this.EventsDetailsAPICallId = await ApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_events/events/${id}`,

    });
  };

 

  // Customizable Area End

}
