import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleAccept:any
  agree:boolean
  setAgree:any
  // Customizable Area End
}

interface S {
// Customizable Area Start
  agree: boolean,
  agreeError: boolean
// Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsAndConditionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      agree: false,
      agreeError: false,
    };
    // Customizable Area End
  }
// Customizable Area Start
  // Customizable Area End
}