import React from "react";
// Customizable Area Start
import { createTheme} from "@material-ui/core/styles";
import "./EventDetails.web.css";

import moment from "moment"
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import EventDetailsController, { Props } from "./EventDetailsController.web";
import {Vectorsvg, calendar, editcopy, editicon, LocationSVG, Linkicon, Time, editwhite } from "./assets"
// Customizable Area End


export default class EventDetails extends EventDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const data = this.state.eventDetails?.attributes
    return (
      // Customizable Area Start
      <div className="eventDetails main_event_box">
        <div className="eventDetails-container">
          <div className="eventDetails-image-container">
            <div>
              <img className="event-img" src={data?.cover_image} alt="" />
            </div>
            <img className="event-img-edit" src={editwhite} alt="" />
          </div>
          <div className="eventDetails-event-edit">
            <p className="event-text">Event Details</p>
            <img src={editicon} alt="" />
          </div>
          <div className="eventDetails-allEvents">
            <div className="eventDetails-singleEvent">
              <img src={Vectorsvg} alt="" />
              <div className="singleEventDetails">
                <p className="event-title">Event Type</p>
                <p className="event-dec">{data?.event_type}</p>
              </div>
            </div>
            {data?.event_type === "virtual" ?
              <div className="eventDetails-singleEvent">
                <img src={Linkicon} alt="" />
                <div className="singleEventDetails">
                  <p className="event-title">Event URL</p>
                  <p className="event-dec">{data?.event_url}</p>
                </div>
              </div> :
              <div className="eventDetails-singleEvent">
                <img src={LocationSVG} alt="" />
                <div className="singleEventDetails">
                  <p className="event-title">Address</p>
                  <p className="event-dec">{data?.street} street</p>
                  <p className="event-dec">{data?.city} city</p>
                  <p className="event-dec">{data?.state} state</p>
                  <p className="event-dec">zipcode {data?.zipcode}</p>
                </div>
              </div>
            }
           <div className="eventDetails-singleEvent">
              <img src={Vectorsvg} alt="" />
              <div className="singleEventDetails">
                <p className="event-title">Event Name</p>
                <p className="event-dec">{data?.event_name}</p>
              </div>
            </div>
            <div className="eventDetails-singleEvent">
              <img src={Time} alt="" />
              <div className="singleEventDetails">
                <p className="event-title">Timezone of Event</p>
                <p className="event-dec">{data?.timezone}</p>
              </div>
            </div>
            <div className="eventDetails-singleEvent">
              <img src={calendar} alt="" />
              <div className="singleEventDetails">
                <p className="event-title">Date</p>
                <p className="event-dec">{data?.start_date}</p>
              </div>
            </div>
            <div className="eventDetails-singleEvent">
              <img src={Time} alt="" />
              <div className="singleEventDetails">
                <p className="event-title">Time</p>
                <p className="event-dec">{moment(data?.start_time, "HH:mm:ss").format('hh:mm A')} - {moment(data?.end_time, "HH:mm:ss").format('hh:mm A')}</p>
              </div>
            </div>
            <div className="eventDetails-singleEvent">
              <img src={editcopy} alt="" />
              <div className="singleEventDetails">
                <p className="event-title">Event Description</p>
                <p className="event-dec">
                  {data?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
      borderRadius: "12px",
    },
  },
};

// Customizable Area End
