Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Please Enter Valid Email";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.loginRegisterAPIEndPoint = "logins";
exports.apiMethodTypeAddDetail = "POST";
exports.contentTypeApiAddDetail = "application/json";
exports.EmailRequired = "Please Enter Email"
exports.PasswordRequired = "Please Enter Password";
exports.sendOtpForLoginVerificationApiEndpoint = "send_login_verfication_otp";
exports.sendOtpForLoginVerificationApiMethod = "POST";
exports.verifyOtpForLoginVerificationApiEndpoint = "login_otp_verification";
exports.verifyOtpForLoginVerificationApiMethod = "POST";
exports.skipVerificationApiEndpoint = "skip_phone_verification";
exports.skipVerificationApiMethod = "POST";
exports.resendTextLabel = "Resend.";
exports.resendCodeLabel = "Didn’t receive code?";
// Customizable Area End