// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment"
// Customizable Area End

// Customizable Area Start
const regexUrl = /^(https?:\/\/(www\.)?|[wW]{3}\.)[a-zA-Z0-9]+(\.[a-zA-Z]{2,})+(\/[^\s]*)?$/;  
export const configJSON = require("./config");
export const POST_TYPES = {
  Link: "link",
  Video: "video",
  TextPost: "textPost",
  Image: "image",
};
// Customizable Area End

export interface Props {
    // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  postListEdited?:any;
  editedPostFun:()=>void;
  updateEditedPost?:any;
  handleUpdatePostList?: any;
  profileImageSrc?: any;
  data?:any;
  dataEdited:any;
  userFullName: string;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  idEdited:null;
  description: any;
  descriptionError: boolean;
  descriptionErrorMsg: string;
  thumbnail: any;
  thumbnailError: boolean;
  thumbnailErrorMsg: string;
  postType: string;
  open: boolean;
  imageOpen: boolean;
  linkOpen: boolean;
  createPost: string;
  createPostError: boolean;
  createPostErrorMsg: string;
  uploadImage: any | ArrayBuffer | null | unknown;
  urlLink: string;
  urlLinkError: boolean;
  urlLinkErrorMsg: string;
  finalPost: boolean;
  videoTypePostModalStepOne: boolean;
  uploadVideoPostAnchor: any;
  videoTypePostModalStepTwo: boolean;
  videoEmbedType: string;
  imageEventOpen: boolean;
  eventError: boolean;
  eventErrorMessage: string;
  event: string;
  timezone: any;
  photoOpen: any;
  timezoneError: any;
  timezoneList: any;
  timezoneErrorMsg: any;
  isEditedEvent:boolean;
  radioSelect: any;
  eventId:any;
  dateStart: any;
  dateStartError: any;
  dateEnd: any;
  dateEndError: any;
  dateEndErrorMsg: any;
  dateStartErrorMsg: any;
  street: any;
  streetError: any;
  streetErrorMsg: any;
  city: any;
  cityError: any;
  cityErrorMsg: any;
  state: any;
  stateError: any;
  stateErrorMsg: any;
  zipcode: any;
  zipcodeError: any;
  zipcodeErrorMs: any;
  zipcodeErrorMsg: any;
  communityFlag:any;
  eventUrlErrorMessage: any;
  eventUrlError: any;
  eventUrl: any;
  image: any;
  imageWidth: any;
  imageErrorMessage: any;
  imageError: any;
  startTime: any;
  timezoneStartTimeError: any;
  timezoneStartTimeList: any;
  endTime: any;
  editedImage:boolean;
  timezoneEndError: any;
  timezoneEndTimeList: any;
  eventDesc: any;
  eventDescError: any;
  eventErrorDescMessage: any;
  imagePass: any;
  radioSelectError: any;
  radioSelectErrorMsg: any;
  imageErrorMsg: any;
  EmbeddedModal: boolean;
  disableBtn:boolean;
  eventDescModal:any;
  disablePublish:any;
  communityData:any;
    // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
  // Customizable Area Start
/*istanbul ignore next */
  // Customizable Area End
 export default class CreatePostNewController extends BlockComponent<Props, S, SS> {
 // Customizable Area Start
  apiCreatePostCallId: any;
   apiCreateEventPostCallId: any;
   apiCreateEmbededCallId: any;
     // Customizable Area End
   constructor(props: Props) {
     // Customizable Area Start
     super(props);
     this.receive = this.receive.bind(this);
     this.state = {
       isLoading: false,
       description: '',
       descriptionError:false,
       descriptionErrorMsg:"",
       thumbnail: "",
       thumbnailError:false,
       disablePublish:false,
       thumbnailErrorMsg:"",
       postType: "",
       open: false,
       imageOpen: false,
       linkOpen: false,
       createPost: "",
       createPostError:false,
       createPostErrorMsg:"",
       uploadImage: "",
       urlLink: "",
       urlLinkError:false,
       urlLinkErrorMsg:"",
       finalPost: false,
       communityFlag:"",
       videoTypePostModalStepOne: false,
       uploadVideoPostAnchor:null,
      videoTypePostModalStepTwo:false,
      videoEmbedType:"",
      EmbeddedModal: false,
      imageEventOpen: false,
      eventDescModal:false,
      event: "",
      eventErrorMessage: "",
      eventError: false,
      timezone: "",
      timezoneError: "",
      radioSelect: "",
      eventId:null,
      dateStart: "",
      dateStartError: "",
      dateEnd: "",
      dateEndError: "",
      dateEndErrorMsg: "",
      dateStartErrorMsg: "",
      timezoneList: [
        {
          id: 1,
          label: "Eastern",
          value: "Eastern",
        },
        {
          id: 2,
          label: "Central",
          value: "Central",
        },
        {
          id: 3,
          label: "Mountain",
          value: "Mountain",
        },
        {
          id: 4,
          label: "AZ",
          value: "AZ",
        },
        {
          id: 5,
          label: "Pacific",
          value: "Pacific",
        },
      ],
      timezoneErrorMsg: "",
      street: "",
      streetError: "",
      streetErrorMsg: "",
      idEdited:null,
      city: "",
      cityError: "",
      cityErrorMsg: "",
      state: "",
      stateError: "",
      stateErrorMsg: "",
      zipcode: "",
      zipcodeError: "",
      zipcodeErrorMs: "",
      zipcodeErrorMsg: "",
      eventUrlErrorMessage: "",
      eventUrlError: "",
      eventUrl: "",
      image: "",
      imageWidth: 500,
      imageErrorMessage: "",
      startTime: "",
      photoOpen:false,
      timezoneStartTimeError: "",
      timezoneStartTimeList: [
        {
          id: 1,
          label: "12:00 AM",
          value: "12:00 AM",
        },
        {
          id: 2,
          label: "01:00 AM",
          value: "01:00 AM",
        },
        {
          id: 3,
          label: "02:00 AM",
          value: "02:00 AM",
        },
        {
          id: 4,
          label: "03:00 AM",
          value: "03:00 AM",
        },
        {
          id: 5,
          label: "04:00 AM",
          value: "04:00 AM",
        },
        {
          id: 6,
          label: "05:00 AM",
          value: "05:00 AM",
        },
        {
          id: 7,
          label: "06:00 AM",
          value: "06:00 AM",
        },
        {
          id: 8,
          label: "07:00 AM",
          value: "07:00 AM",
        },
        {
          id: 9,
          label: "08:00 AM",
          value: "08:00 AM",
        },
        {
          id: 10,
          label: "09:00 AM",
          value: "09:00 AM",
        },
        {
          id: 11,
          label: "10:00 AM",
          value: "10:00 AM",
        },
        {
          id: 12,
          label: "11:00 AM",
          value: "11:00 AM",
        },
        {
          id: 13,
          label: "12:00 PM",
          value: "12:00 PM",
        },
        {
          id: 14,
          label: "01:00 PM",
          value: "01:00 PM",
        },
        {
          id: 15,
          label: "02:00 PM",
          value: "02:00 PM",
        },
        {
          id: 16,
          label: "03:00 PM",
          value: "03:00 PM",
        },
        {
          id: 17,
          label: "04:00 PM",
          value: "04:00 PM",
        },
        {
          id: 18,
          label: "05:00 PM",
          value: "05:00 PM",
        },
        {
          id: 19,
          label: "06:00 PM",
          value: "06:00 PM",
        },
        {
          id: 20,
          label: "07:00 PM",
          value: "07:00 PM",
        },
        {
          id: 21,
          label: "08:00 PM",
          value: "08:00 PM",
        },
        {
          id: 22,
          label: "09:00 PM",
          value: "09:00 PM",
        },
        {
          id: 23,
          label: "10:00 PM",
          value: "10:00 PM",
        },
        {
          id: 24,
          label: "11:00 PM",
          value: "11:00 PM",
        },
      ],
      endTime: "",
      timezoneEndError: "",
      timezoneEndTimeList: [
        {
          id: 1,
          value: "12:00 AM",
          label: "12:00 AM",
        },
        {
          id: 2,
          value: "01:00 AM",
          label: "01:00 AM",
        },
        {
          id: 3,
          value: "02:00 AM",
          label: "02:00 AM",
        },
        {
          id: 4,
          value: "03:00 AM",
          label: "03:00 AM",
        },
        {
          id: 5,
          value: "04:00 AM",
          label: "04:00 AM",
        },
        {
          id: 6,
          value: "05:00 AM",
          label: "05:00 AM",
        },
        {
          id: 7,
          value: "06:00 AM",
          label: "06:00 AM",
        },
        {
          id: 8,
          value: "07:00 AM",
          label: "07:00 AM",
        },
        {
          id: 9,
          value: "08:00 AM",
          label: "08:00 AM",
        },
        {
          id: 10,
          value: "09:00 AM",
          label: "09:00 AM",
        },
        {
          id: 11,
          value: "10:00 AM",
          label: "10:00 AM",
        },
        {
          id: 12,
          value: "11:00 AM",
          label: "11:00 AM",
        },
        {
          id: 13,
          value: "12:00 PM",
          label: "12:00 PM",
        },
        {
          id: 14,
          value: "01:00 PM",
          label: "01:00 PM",
        },
        {
          id: 15,
          value: "02:00 PM",
          label: "02:00 PM",
        },
        {
          id: 16,
          value: "03:00 PM",
          label: "03:00 PM",
        },
        {
          id: 17,
          value: "04:00 PM",
          label: "04:00 PM",
        },
        {
          id: 18,
          value: "05:00 PM",
          label: "05:00 PM",
        },
        {
          id: 19,
          value: "06:00 PM",
          label: "06:00 PM",
        },
        {
          id: 20,
          value: "07:00 PM",
          label: "07:00 PM",
        },
        {
          id: 21,
          value: "08:00 PM",
          label: "08:00 PM",
        },
        {
          id: 22,
          value: "09:00 PM",
          label: "09:00 PM",
        },
        {
          id: 23,
          value: "10:00 PM",
          label: "10:00 PM",
        },
        {
          id: 24,
          value: "11:00 PM",
          label: "11:00 PM",
        },
      ],
      eventDesc: "",
      eventDescError: "",
      eventErrorDescMessage: "",
      imagePass: "",
      radioSelectError: false,
      radioSelectErrorMsg: "",
      isEditedEvent:false,
      imageError: false,
      imageErrorMsg: "",
      disableBtn:false,
      communityData:{},
      editedImage:false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount () {
    super.componentDidMount();
    this.setState({communityFlag:localStorage.getItem("community")})
  
    if( localStorage.getItem("communityData")!="")
    {
     let parsedData=localStorage.getItem("communityData")
     if(parsedData!=null)
     {
       this.setState({communityData:JSON.parse(parsedData)})
     }
    }
 

    if( localStorage.getItem("eventDetails")!="")
    {
     let parsedData=localStorage.getItem("eventDetails")

     if(parsedData!=null)
     {
       this.setState({eventId:JSON.parse(parsedData).id})
       this.conversion(JSON.parse(parsedData).start_date,JSON.parse(parsedData).street,JSON.parse(parsedData).city,JSON.parse(parsedData).state,JSON.parse(parsedData).zipcode,JSON.parse(parsedData).cover_image,JSON.parse(parsedData).event_url)
       this.convertEndDateFormat(JSON.parse(parsedData).event_type,JSON.parse(parsedData).timezone,JSON.parse(parsedData).end_date,JSON.parse(parsedData).start_time,JSON.parse(parsedData).end_time,JSON.parse(parsedData).description,JSON.parse(parsedData).event_name);
     }

    
    
    }
    if (localStorage.getItem("openmodal")==="true") {
      this.setState({
        imageEventOpen : true
      })
    }
 
  }
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    console.log(this.props.dataEdited,"fdiofhosdifsdf", prevProps.dataEdited)
    console.log(this.props.dataEdited?.attributes?.post_type=="image")
if(this.props.dataEdited!==prevProps.dataEdited)
{  console.log("abcdefg")
  
  this.openDataPostModal(this.props.dataEdited)}

  }


openDataPostModal = (dataEdited:any)=>{

  if(dataEdited?.attributes?.post_type=="image")
  {
    console.log(dataEdited?.attributes,"Attr-1212");
    
    this.setState({idEdited:dataEdited?.id,editedImage:true, uploadImage:dataEdited?.attributes?.thumbnail,open:true });
   
  }
  if(dataEdited?.attributes?.post_type=="text")
  {
    console.log(dataEdited?.attributes,"Attr-1212");
    
    this.setState({idEdited:dataEdited?.id,editedImage:true, createPost: dataEdited?.attributes?.description,open:true });
   
   

  }
  if(dataEdited?.attributes?.post_type=="YouTube" )
  {
    console.log(dataEdited?.attributes,"Attr-1212");
    
    this.setState({idEdited:dataEdited?.id,editedImage:true, urlLink: dataEdited?.attributes?.url,videoTypePostModalStepTwo:true, videoEmbedType: "youtube" });
   
   

  }
  if(dataEdited?.attributes?.post_type=="Vimeo" )
  {
    this.setState({idEdited:dataEdited?.id,editedImage:true, urlLink: dataEdited?.attributes?.url,videoTypePostModalStepTwo:true, videoEmbedType: "vimeo" });
  }
  if(dataEdited?.attributes?.post_type=="url")
  {
    console.log(dataEdited?.attributes,"Attr-1212");
    this.setState({disablePublish:false, idEdited:dataEdited?.id,linkOpen:true,description: dataEdited?.attributes?.description,editedImage:true, urlLink:dataEdited?.attributes?.url})
  
   

  }

}

conversion = (startingDate:string,street:string,city:string,state:string,zipcode:string,coverImage:string,eventUrl:string) => {
this.setState({dateStart:startingDate,street:street, city:city,state:state,zipcode:zipcode,image:coverImage, eventUrl:eventUrl? eventUrl:""})

}
convertEndDateFormat = (eventType:string,timeZone:string,endingDate:string,startTime:string, endTime:string,description:string,eventName:string)=>{

this.setState({dateEnd:endingDate,  isEditedEvent:true})

let inputTimeString = startTime;

console.log("starttter",startTime,endTime)

// Parse the input time string
let inputTime = inputTimeString.split(":");
let hours = parseInt(inputTime[0], 10);
let minutes = parseInt(inputTime[1], 10);

// Convert to 12-hour format
let period = hours >= 12 ? "PM" : "AM";
hours = hours % 12 || 12; // Handle 12:00 PM as noon and 12:00 AM as midnight

// Format the time in "hh:mm AM/PM" format
let formattedStartTime = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + " " + period;



let inputTimeStringEnd = endTime;

// Parse the input time string
let inputTimeEnd = inputTimeStringEnd.split(":");
let hoursEnd = parseInt(inputTimeEnd[0], 10);
let minutesEnd = parseInt(inputTimeEnd[1], 10);

// Convert to 12-hour format
let periodEnd = hoursEnd >= 12 ? "PM" : "AM";
hoursEnd = hoursEnd % 12 || 12; // Handle 12:00 PM as noon and 12:00 AM as midnight

// Format the time in "hh:mm AM/PM" format
let formattedEndTime = hoursEnd.toString().padStart(2, '0') + ":" + minutesEnd.toString().padStart(2, '0') + " " + periodEnd;


this.setState({radioSelect:eventType,timezone:timeZone, event:eventName,startTime: formattedStartTime, endTime: formattedEndTime,eventDesc:description})

}
  renderValueStartTimezone = (selected: any) => {
    console.log(selected, "Selected--SS");
    if (!selected || selected.length === 0) {
      return "Start Time";
    }
    return selected;
  };

  renderValueTimezone = (selected: any) => {
    console.log(selected, "Selected--SS");
    if (!selected || selected.length === 0) {
      return "Timezone";
    }
    return selected;
    
    }
    handleEmbeddedClose = () => {
        this.setState({EmbeddedModal: false})
    }
    EmbeddedOpen= ()=>{
       this.setState({EmbeddedModal: true, urlLinkErrorMsg:""})
    }
    handleVideoOpen = (event:any) => {
        this.setState({videoTypePostModalStepOne: true,uploadVideoPostAnchor:event.target,urlLinkErrorMsg:""})
    }

    handleImageOpen = () => {
        this.setState({ imageOpen: true ,  createPostError:false , createPostErrorMsg:""})
        
    };
    handleImageOnlyOpen = () => {
  
   
        this.handleImageOpen();
      
      
  };

    handleImageClose = () => {
        this.setState({ imageOpen: false, uploadImage:"" }) 
        // this.resetPost()
    };

    handleLinkOpen = () => {
        this.setState({ linkOpen: true ,  createPostError:false , createPostErrorMsg:"",disablePublish:false })
    };

    handleLinkClose = () => {
        this.setState({ linkOpen: false , urlLink:"", thumbnail:"", description:"" , urlLinkError:false, urlLinkErrorMsg:"", thumbnailError:false, thumbnailErrorMsg:"" , descriptionError:false, descriptionErrorMsg:"" })
        // this.resetPost()
    };

    handleFinalPostOpen = (uploadedImage:any) => {
      this.setState({editedImage:false});
        this.setState({ uploadImage: uploadedImage }, () => {
            this.setState({imageOpen: false , finalPost:true, open: false})
        })
    };

    handleFinalPostClose = () => {
        this.setState({imageOpen: false , finalPost:false , uploadImage:""})
        // this.resetPost()
    };

    validateUrlPost = () => {
        let isValid = true
        
        const invalidUrl = this.state.urlLink.match(/^(https?:\/\/(www\.)?|[wW]{3}\.)[a-zA-Z0-9]+(\.[a-zA-Z]{2,})+(\/[^\s]*)?$/);
        
        if(this.state.urlLink && invalidUrl == null){
            this.setState({
                urlLinkError:true,
                urlLinkErrorMsg:"Please enter a valid url."
             })
            isValid=false
        }
        else if(!this.state.urlLink?.trim()){
            this.setState({
                urlLinkError:true,
                urlLinkErrorMsg:"Please enter url"
             })
             isValid=false
        }
        else{
            this.setState({
                urlLinkError:false,
                urlLinkErrorMsg:""
             })
        }
        if(!this.state.description?.trim()){
            this.setState({
                descriptionError:true,
                descriptionErrorMsg:"Please enter a description."
            })
            isValid=false;
        }else{
            this.setState({
                descriptionError:false,
                descriptionErrorMsg:""
            })
        }
        return isValid
    }
 

  renderValueEndTimezone = (selected: any) => {
    console.log(selected, "Selected--SS");
    if (!selected || selected.length === 0) {
      return "End Time";
    }
    return selected;
  };

  handleOpenVideoModalOne = () => {
    this.setState({
      videoTypePostModalStepOne: true,
      open: false,
      createPostError: false,
      createPostErrorMsg: "",
    });
  };

   handleKeyPress = (event:any) => {
   const {value}= event.target
    const maxLength = 10; // Adjust the desired length of characters
    if (event.key === 'Enter' && value.length >= maxLength) {
      event.preventDefault();
      this.setState({eventDesc:value+ '\n'})
 
    }
  };

  handleCloseVideoModalOne = () => {
    this.setState({
      uploadVideoPostAnchor: null,
      videoTypePostModalStepOne: false,
    });
    this.props.editedPostFun();
  };

  handleOpenVideoModalTwo = (videoEmbedType: string) => {
    this.setState({ videoEmbedType: videoEmbedType }, () => {
      this.setState({
        videoTypePostModalStepTwo: true,
        videoTypePostModalStepOne: false,
        uploadVideoPostAnchor: null,
        open: false,
      });
    });
    this.props.editedPostFun();
  };

  handleCloseVideoModalTwo = () => {
    this.setState({
      uploadVideoPostAnchor: null,
      videoTypePostModalStepOne: false,
      videoTypePostModalStepTwo: false,
      urlLinkError:false,
      urlLinkErrorMsg:"",
      disableBtn: false,
      urlLink: "",
    });
    this.props.editedPostFun();
  };

  handleClose =() => {
    this.setState({
      open: false,
      createPost: "",
      createPostError: false,
      createPostErrorMsg: "",
      imageOpen:false,
      photoOpen:false,
    });
    this.props.editedPostFun();
    // this.resetPost()
  };

  handleClosePhoto = () => {
    this.setState({
      open: false,
      createPost: "",
      photoOpen:false,
      createPostError: false,
      createPostErrorMsg: "",
      imageOpen:false
    });
    this.props.editedPostFun();
    // this.resetPost()
  };

  handleRadioSelect = (e: any) => {
    this.setState({
      radioSelect: e.target.value,
      radioSelectError: false,
      radioSelectErrorMsg: "",
    });
  };

  handleOpen = () => {
    this.setState({ open: true,createPostErrorMsg:"" });
  };
  handleOpenPhoto = () => {
    this.setState({ open: true, photoOpen:true, createPostErrorMsg:"" });
  };
  handleWidthImage = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const imageUrl = URL.createObjectURL(event.target.files[0]);
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        if (img.naturalWidth < 480) {
          this.setState({
            imageErrorMessage: "Image width should be greater than 480 px",
          });
          this.setState({ image: "" });
        }

        console.log(img.naturalWidth, "Width");
        this.setState({ imageWidth: img.naturalWidth });

        URL.revokeObjectURL(imageUrl); // Clean up the object URL
      };
    }
  };

  handleImageChange = (e: any) => {
    this.setState({
      imagePass: e.target?.files[0],
      imageError: false,
      imageErrorMsg: "",
    });

    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log(e.target?.result, "Resultt");
        this.setState({ image: e.target?.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
    this.handleWidthImage(e);
  };

  handleImageEventOpen = () => {
    this.setState({ imageEventOpen: true });
    
  };

/*istanbul ignore next */
   handleDescModal=()=>{

    

    this.setState({eventDescModal:true})
  
 }

 handleDescClose=()=>{
  this.setState({eventDescModal:false})
 }
  handleChangeDropdownValue = (e: any) => {
    console.log(e.target.value, "DropDown");
    const { value } = e.target;
    let fieldValue: any = value;

    if (fieldValue) {
      this.setState({
        timezone: fieldValue,
        timezoneError: false,
        timezoneErrorMsg: "",
      });
    }
  };

  handleChangeDropdownStartTImeValue = (e: any) => {
    console.log(e.target.value, "DropDown");
    const { value } = e.target;
    let fieldValue: any = value;

    if (fieldValue) {
      this.setState({ startTime: fieldValue, timezoneStartTimeError: false });
    }
  };
/*istanbul ignore next */
   handleChangeDropdownEndTimeValue = (e: any) => {
     console.log(e.target.value, "DropDown");
     const { value } = e.target;
     let fieldValue: any = value;
 
     if (fieldValue) {
         this.setState({ endTime: fieldValue, timezoneEndError: false });
     }
   };

 
  handleImageEventClose = () => {
    localStorage.removeItem("openmodal")
    this.setState({
      imageEventOpen: false,
      image: "",
      event: "",
      eventUrl: "",
      radioSelect:"",
      timezone: "",
      startTime: "",
      endTime: "",
      dateEnd: "",
      dateStart: "",
      eventDesc: "",
      city: "",
      street: "",
      state: "",
      zipcode: "",
      eventUrlErrorMessage:"",
      imageError: false,
      eventError: false,
      radioSelectError:false,
      eventUrlError: false,
      timezoneError: false,
      timezoneStartTimeError: false,
      timezoneEndError:false,
      dateEndError: false,
      dateStartError: false,
      eventDescError: false,
      eventErrorDescMessage:"",
      cityError: false,
      streetError:false,
      stateError:false,
      zipcodeError:false,
      timezoneErrorMsg:"",
      eventErrorMessage:"",
      cityErrorMsg:"",
      streetErrorMsg:"",
      stateErrorMsg:"",
      zipcodeErrorMsg:"",
      dateStartErrorMsg:"",
      dateEndErrorMsg:"",
      imageErrorMessage:"",
      eventDescModal:false
    });
    localStorage.setItem("openmodal","false")
    // this.resetPost()
  };




 /*istanbul ignore next */
  handleDateStartChange = (data: any) => {
     if (data) {
       const setDateFormat = moment(data.toDate());
       console.log(setDateFormat,"fdihfidhfid",data)
       this.setState({
         dateStart: setDateFormat,
         dateStartError: false,
         dateStartErrorMsg: "",
       });
     }
  };
   /*istanbul ignore next */
  handleDateEndChange = (data: any) => {
    if (data) {
      const setDateFormat = moment(data.toDate());
      this.setState({
        dateEnd: setDateFormat,
        dateEndError: false,
        dateEndErrorMsg: "",
      });
    }
  };



  validatePostData = (from: string) => {
    let isValid = true;
    if (from === POST_TYPES.Link) {
      isValid = this.validateUrlPost();
    }
    if (from === POST_TYPES.TextPost) {
      if (!this.state.createPost?.trim()) {
        this.setState({
          createPostError: true,
          createPostErrorMsg: "Please write a post.",
        });
        isValid = false;
      } else {
        this.setState({
          createPostError: false,
          createPostErrorMsg: "",
        });
      }
    }
    return isValid;
  };


  handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "createPost") {
      this.setState({
        createPostError: false,
        createPostErrorMsg: "",
      });
    }
    if (name === "urlLink") {
      this.setState({
        urlLinkError: false,
        urlLinkErrorMsg: "",
      });
    }
    if (name === "description") {

     
      this.setState({
        descriptionError: false,
        descriptionErrorMsg: "",
      });
    }

    if (name === "event") {
      this.setState({
        eventError: false,
        eventErrorMessage: "",
      });
    }

 
    if (name === "city") {
      this.setState({
        cityError: false,
        cityErrorMsg: "",
      });
    }

    if (name === "state") {
      this.setState({
        stateError: false,
        stateErrorMsg: "",
      });
    }

    if (name === "zipcode") {
      this.setState({
        zipcodeError: false,
        zipcodeErrorMsg: "",
      });
      
      if(value.length !== 5)
     {
          this.setState({zipcodeError:true, zipcodeErrorMsg:"Zipcode length should be exact 5"})
     }
    }

    if (name === "eventUrl") {
      this.setState({
        eventUrlError: false,
        eventUrlErrorMessage: "",
      });
    }

    if (name === "eventDesc") {

      const inputValue = event.target.value;
      const maxLength = 10; // Adjust the desired length of characters
      if (inputValue.length <= maxLength) {
        this.setState({eventDesc:inputValue})
    
      }
      this.setState({
        eventDescError: false,
        eventErrorDescMessage: "",
      });
    }
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  handleAddVideoTypePostOneModalClick = (event: React.MouseEvent) => {
    this.setState(
      {
        uploadVideoPostAnchor: event.currentTarget,
      },
      () => {
        this.setState({ videoTypePostModalStepOne: true });
      }
    );
  };

  ThumnailImg = (e: any) => {
    this.setState({ thumbnail: e.target.files[0] });
    this.setState({
      thumbnailError: false,
      thumbnailErrorMsg: "",
    });
  };

  handleUpdatePost = (responseJson: any) => {
    if (!(responseJson?.errors || responseJson?.error)) {

      
      toast.success( (this.state.idEdited || this.state.eventId)?"Post edited successfully !":"Post added successfully !");

      this.setState({photoOpen:false,disablePublish:true,
        editedImage:false,
        imageEventOpen: false,
        image: "",
        event: "",
        idEdited:null,
        radioSelect: "",
        eventUrlError:false,
        eventUrl: "",
        timezone: "",
        startTime: "",
        endTime: "",
        dateEnd: "",
        dateStart: "",
        eventDesc: "",
        city: "",
        street: "",
        state: "",
        zipcode: "",
        imageErrorMessage:"",
        eventDescModal: false
      })
    this.props.handleUpdatePostList(responseJson?.data,this.state.idEdited)

      
    } else {
      if (responseJson?.errors) {
        for (let key in responseJson.errors[0]) {

          toast.error(responseJson.errors[0].message[0]);

        }
      } else if (responseJson?.error?.length) {
        for (let key in responseJson.error[0]) {
          toast.error(responseJson.error[0]?.[key]);
        }
      }
      this.parseApiErrorResponse(responseJson);
    }
  };

   apiRequestFunction=(apiRequestCallId:any,responseJson:any,errorReponse:any)=>{
    if (apiRequestCallId && responseJson) {
      /*istanbul ignore next */
   if(apiRequestCallId === this.apiCreateEventPostCallId)
   {this.resetPost();
    
 
   this.handleUpdatePost(responseJson);
   this.parseApiCatchErrorResponse(errorReponse);}
 }
  }

  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
   

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    console.log("apiRequestCallId", apiRequestCallId);

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.apiCreatePostCallId) {
        this.resetPost();
        this.handleUpdatePost(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }


        if (apiRequestCallId && responseJson) {
             /*istanbul ignore next */
            if (apiRequestCallId === this.apiCreateEmbededCallId) {
                this.resetPost()
                this.setState({open:false, eventUrlError:false, eventUrlErrorMessage:"", videoTypePostModalStepTwo:false,urlLink:"",videoEmbedType:"", urlLinkError:false, urlLinkErrorMsg:"",disableBtn:false})
                this.handleUpdatePost(responseJson)
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }


    }

this.apiRequestFunction(apiRequestCallId,responseJson,errorReponse);

  }

  resetPost() {
    this.setState((prev) => {
      return {
        ...prev,
        uploadVideoPostAnchor: null,
        videoTypePostModalStepOne: false,
        isLoading: false,
        urlLink: "",
        description: "",
        thumbnail: "",
        postType: "",
        open: false,
        imageOpen: false,
        linkOpen: false,
        createPost: "",
        uploadImage: "",
        finalPost: false,
      };
    });
  }

  handlePublishLink = (e: any) => {
    e.preventDefault();
    if (this.validatePostData(POST_TYPES.Link)) {
      this.handleButtonAction();
    }
  };

  handlePublish = (e: any) => {
    e.preventDefault();
    this.handleButtonAction();
  };

  handlePublishOnlyPost = (e: any) => {

    e.preventDefault();
    if (this.validatePostData(POST_TYPES.TextPost)) {
      this.handleButtonAction();
    }
  };

  handlePublishPost = (e: any) => {

    e.preventDefault();
     if(this.state.createPost==="")
     {
      this.setState({createPostError:true, createPostErrorMsg:"Please write a post"})
     }
     else{
        toast.error("Please Select Image")
     }
       
  
  };


  validateFormTwo=()=>{

     
    if(!regexUrl.test(this.state.eventUrl))
    {
          this.setState({eventUrlError:true, eventUrlErrorMessage:"Please enter valid url"})
    }
    if (this.state.radioSelect === "") {
      this.setState({
        radioSelectError: true,
        radioSelectErrorMsg: "Please select event type",
      });
    }
    if (this.state.event === "") {
      this.setState({
        eventError: true,
        eventErrorMessage: "Please enter the event name",
      });
    }

    if (this.state.radioSelect === "virtual") {
      /*istanbul ignore next */
      if (this.state.eventUrl === "") {
        this.setState({
          eventUrlError: true,
          eventUrlErrorMessage: "Please Enter the Event Url",
        });
      }
    }
    if (this.state.timezone === "") {
      this.setState({
        timezoneError: true,
        timezoneErrorMsg: "Please Select Time Zone",
      });
    }
  }
   /*istanbul ignore next */
  validateForm=()=>{
   this.validateFormTwo()

    if (this.state.eventDesc === "") {
      this.setState({
        eventDescError: true,
        eventErrorDescMessage: "Please enter event description",
      });
    }
    if (this.state.dateStart === "") {
      this.setState({
        dateStartError: true,
        dateStartErrorMsg: "Please select start date",
      });
    }
    if (this.state.dateEnd === "") {
      this.setState({
        dateEndError: true,
        dateEndErrorMsg: "Please Select end date",
      });
    }
    if (this.state.startTime === "") {
      this.setState({ timezoneStartTimeError: true });
    }
    if (this.state.endTime === "") {
      this.setState({ timezoneEndError: true });
    }
    if (this.state.radioSelect === "in-person") {
      /*istanbul ignore next */
   
      if (this.state.city === "") {
        this.setState({ cityError: true, cityErrorMsg: "Please enter city" });
      }
      if (this.state.state === "") {
        this.setState({
          stateError: true,
          stateErrorMsg: "Please enter state",
        });
      }
      if (this.state.zipcode === "") {
        this.setState({
          zipcodeError: true,
          zipcodeErrorMsg: "Please enter zipcode",
        });
      }
    }

    if (this.state.image === "") {
      this.setState({ imageError: true, imageErrorMsg: "Please select image" ,imageErrorMessage:""});
    }

  }



  handleSubmitVideoUrl=async()=>{
    let signupToken = await getStorageData('SignupSuccessToken')
    let loginToken = await getStorageData('LoginSuccessToken')
    const header = {
        token: signupToken || loginToken,
    };
    const formData = new FormData()
       /*istanbul ignore next */
   if(this.state.videoTypePostModalStepTwo && this.state.urlLink!=""){
        formData.append("post[url]",this.state.urlLink)
 {  this.state.videoEmbedType==="youtube" ? formData.append("post[post_type]", "YouTube"): formData.append("post[post_type]", "Vimeo")}
        formData.append("post[description]", this.state.createPost)

        {this.state.communityFlag=="true" && formData.append("post[community_id]",this.state.communityData?.id)}

    }
    this.setState({finalPost:false,linkOpen:false})

      let valid = this.state.videoEmbedType==="youtube"?/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?(?:.*&)?v=|embed\/|v\/)|youtu\.be\/)([^\?&"'>]+)/:/(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)(\d+)/
       
      if(!this.state.urlLink.match(valid))

     { this.setState({
          urlLinkError:true,
          urlLinkErrorMsg:"Please Enter Valid Url",
      })}
   /*istanbul ignore next */

  else {  
    
    this.setState({disableBtn:true})
    
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateEmbededCallId = requestMessage.messageId;

   {this.state.communityFlag=="false"? requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postUrl,
    ):
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "create_community"
  );}

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );

  


    runEngine.sendMessage(requestMessage.id, requestMessage);
}
}

isVirtualData = async ()=>{
  let signupToken = await getStorageData("SignupSuccessToken");
  let loginToken = await getStorageData("LoginSuccessToken");
  const header = {
    token: signupToken || loginToken,
  };
  const formData = new FormData();
  
  if (this.state.radioSelect === "virtual") {
    /*istanbul ignore next */


    if (
      !this.state.radioSelectError &&
      !this.state.eventError &&
      !this.state.eventUrlError &&
      !this.state.timezoneError &&
      !this.state.eventDescError &&
      !this.state.dateStartError &&
      !this.state.dateEndError &&
      !this.state.timezoneEndError &&
      !this.state.timezoneStartTimeError &&
      !this.state.imageError
    ) {
      if (this.state.imageEventOpen) {
        formData.append("event[event_type]", this.state.radioSelect);
        formData.append("event[event_name]", this.state.event);
        formData.append("event[event_url]", this.state.eventUrl);
        formData.append("event[timezone]", this.state.timezone);
        formData.append("event[description]", this.state.eventDesc);
        formData.append("event[start_date]", this.state.dateStart);
        formData.append("event[end_date]", this.state.dateEnd);
        formData.append("event[start_time]", this.state.startTime);
        formData.append("event[end_time]", this.state.endTime);
        formData.append("event[street]",  this.state.street);
        formData.append("event[city]", this.state.city);
        formData.append("event[state]",  this.state.state);
        formData.append("event[zipcode]",  this.state.zipcode);
        typeof(this.state.imagePass)=="object" && formData.append("event[cover_image]", this.state.imagePass);
        
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiCreateEventPostCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
       this.state.isEditedEvent?`bx_block_events/events/${this.state.eventId}` :configJSON.eventPath
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        this.state.isEditedEvent?'PUT': configJSON.PostAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  }
}


  handleEventButtonAction = async () => {
    let signupToken = await getStorageData("SignupSuccessToken");
    let loginToken = await getStorageData("LoginSuccessToken");
    const header = {
      token: signupToken || loginToken,
    };
    const formData = new FormData();
    this.validateForm();

    this.isVirtualData()

  
    if (this.state.radioSelect === "in-person") {
      /*istanbul ignore next */
      if (
        !this.state.radioSelectError &&
        !this.state.eventError &&
        !this.state.timezoneError &&
        !this.state.eventDescError &&
        !this.state.dateStartError &&
        !this.state.dateEndError &&
        !this.state.timezoneEndError &&
        !this.state.timezoneStartTimeError &&
        !this.state.cityError &&
        !this.state.stateError &&
        !this.state.zipcodeError &&
        !this.state.imageError
      ) {
        if (this.state.imageEventOpen) {
          formData.append("event[event_type]", this.state.radioSelect);
          formData.append("event[event_name]", this.state.event);
          formData.append("event[event_url]", this.state.eventUrl);
          formData.append("event[timezone]", this.state.timezone);
          formData.append("event[description]", this.state.eventDesc);
          formData.append("event[start_date]", this.state.dateStart);
          formData.append("event[end_date]", this.state.dateEnd);
          formData.append("event[start_time]", this.state.startTime);
          formData.append("event[end_time]", this.state.endTime);
          formData.append("event[street]", this.state.street);
          formData.append("event[city]", this.state.city);
          formData.append("event[state]", this.state.state);
          formData.append("event[zipcode]", this.state.zipcode);
          typeof(this.state.imagePass)=="object" && formData.append("event[cover_image]", this.state.imagePass);
        }

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCreateEventPostCallId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          this.state.isEditedEvent?`bx_block_events/events/${this.state.eventId}` :configJSON.eventPath
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          this.state.isEditedEvent?'PUT': configJSON.PostAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    }
  };

  handleButtonAction = async () => {
console.log(this.state.editedImage,this.props?.data?.id,"dsijfdsofjoiwe")
   
    this.setState({
      isLoading: true
    })
    let signupToken = await getStorageData("SignupSuccessToken");
    let loginToken = await getStorageData("LoginSuccessToken");
    const header = {
      token: signupToken || loginToken,
    };
    const formData = new FormData();
    if (this.state.createPost && !this.state.linkOpen) {
      formData.append("post[description]", this.state.createPost);
      formData.append("post[post_type]", "text");
      {this.state.communityFlag=="true" && formData.append("post[community_id]",this.state.communityData?.id);}
    }

    if (this.state.finalPost) {
      formData.append("post[images][]", this.state.uploadImage);
      formData.append("post[thumbnail]", this.state.uploadImage);
      formData.append("post[post_type]", "image");
      {this.state.communityFlag=="true" && formData.append("post[community_id]",this.state.communityData?.id);}
      console.log("data", this.state.createPost, this.state.uploadImage);
      console.log("formdata", formData);
    }
    
    else if (this.state.linkOpen) {
      console.log("Abcdddd")
    /*istanbul ignore next */
      formData.append("post[url]", this.state.urlLink);
    /*istanbul ignore next */
      formData.append("post[thumbnail]", this.state.thumbnail);
    /*istanbul ignore next */
      formData.append("post[description]", this.state.description);
      formData.append("post[post_type]", "url");
      {this.state.communityFlag=="true" && formData.append("post[community_id]",this.state.communityData?.id);}
      this.setState({disablePublish:true})
     
    }
    this.setState({ finalPost: false, linkOpen: false });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreatePostCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     this.state.idEdited?`posts/${this.state.idEdited}`: configJSON.postImage
    )
  

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
    this.state.idEdited ?"PUT": configJSON.PostAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
// Customizable Area End
}

